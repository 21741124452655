import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PrizeDetails } from './PrizeDetails'
import LeaderboardActions from 'Redux/LeaderboardDetailsRedux'
import { LeaderboardDetails } from './LeaderboardDetails'
import { NamingDetails } from './NamingDetails'
import { LeaderboardRules } from './LeaderboardRules'
import { TermsAndConditions } from './TermsAndConditions'
import { GameDetails } from './GameDetails'
import { TournamentDesign } from './TournamentDesign'
import { Deposit } from './Deposit'

const SpawningStep = () => {
  const {
    internalName,
    externalName,
    description,
    formatType,
    lbCategory,
    lbType,
    systemEvent,
    gameCategory,
    gameName,
    bbType,
    bbMinValue,
    bbMaxValue,
    totalPrize,
    prizes,
    goalBasedPrizes,
    scoringSystem,
    rules,
    terms,
    entryFee,
    discount,
    slots,
    discountSlots,
    percentOfWinningPlayers,
    leaderboardBannerImage,
    rakePercentage,
    seedPlayers,
    benefitingAncestorGenerations,
    depositorPercentage,
    degreeMultiplier,
    isReferral,
    optIn,
    lateRegistrationAllowed,
    lateRegistrationDurationHour,
    lateRegistrationDurationMin,
    hasNonCashPrizes,
    leaderboardBanners
  } = useSelector((state) => state.leaderboard.spawningDetails)

  const dispatch = useDispatch()
  const updateSD = (key) => (value) => {
    dispatch(LeaderboardActions.updateSpawningDetails(key, value))
  }

  return (
    <>
      <NamingDetails
        updateSD={updateSD}
        internalName={internalName}
        externalName={externalName}
        description={description}
      />

      <LeaderboardDetails
        updateSD={updateSD}
        formatType={formatType}
        lbCategory={lbCategory}
        lbType={lbType}
        optIn={optIn}
        systemEvent={systemEvent}
        leaderboardBannerImage={leaderboardBannerImage}
        entryFee={entryFee}
        discount={discount}
        slots={slots}
        discountSlots={discountSlots}
        seedPlayers={seedPlayers}
        benefitingAncestorGenerations={benefitingAncestorGenerations}
        degreeMultiplier={degreeMultiplier}
        isReferral={isReferral}
        lateRegistrationAllowed={lateRegistrationAllowed}
        lateRegistrationDurationHour={lateRegistrationDurationHour}
        lateRegistrationDurationMin={lateRegistrationDurationMin}
        hasNonCashPrizes={hasNonCashPrizes}
        leaderboardBanners={leaderboardBanners}
      />

      <LeaderboardRules updateSD={updateSD} rules={rules} />

      <TermsAndConditions updateSD={updateSD} terms={terms} />

      {systemEvent && (
        <GameDetails
          updateSD={updateSD}
          gameCategory={gameCategory}
          gameName={gameName}
          systemEvent={systemEvent}
        />
      )}

      {systemEvent?.value !== 'Deposit' && (
        <TournamentDesign
          updateSD={updateSD}
          gameName={gameName}
          bbType={bbType}
          bbMinValue={bbMinValue}
          bbMaxValue={bbMaxValue}
          systemEvent={systemEvent}
          scoringSystem={scoringSystem}
        />
      )}

      {systemEvent.value === 'Deposit' && (
        <Deposit
          updateSD={updateSD}
          depositorPercentage={depositorPercentage}
        />
      )}

      {lbCategory && lbType && (
        <PrizeDetails
          updateSD={updateSD}
          totalPrize={totalPrize}
          prizesForRankBasedLBs={prizes}
          prizesForGoalBasedLBs={goalBasedPrizes}
          lbCategory={lbCategory}
          percentOfWinningPlayers={percentOfWinningPlayers}
          rakePercentage={rakePercentage}
          lbType={lbType}
          hasNonCashPrizes={hasNonCashPrizes}
        />
      )}
    </>
  )
}

export default SpawningStep
