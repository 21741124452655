import React from 'react'
import {
  Bold,
  Button,
  Card,
  DatePicker,
  Divider,
  Input,
  Select,
  Text,
  Title,
  Checkbox,
} from 'salt'
import styles from './EntryConditions.module.scss'
import { Form, Formik } from 'formik'
import {
  asPer,
  attributesMap,
  comparators,
  comparatorsString,
  defaultAttrMapVal,
  ATTRIBUTE_GAME_NAMES,
  ALL_GAMES,
  ALL_PERIOD,
  ATTRIBUTE_PERIODS,
} from '../../constants'
import moment from 'moment'
import TrashCan from '../../../../Images/TrashCan.svg'
import { CSVUploader } from 'Components/CSVUploader'

const EntryConditions = ({
  audience,
  updateAudience,
  updateExcludedPlayerIds,
}) => {
  const addAttribute = () => ({
    id: Math.random().toString(),
    attribute: '',
    comparator: '',
    asPer: asPer[0],
    min: null,
    max: null,
    asOf: new Date(),
    during: [new Date(), new Date()],
    stringValue: '',
    isRolling: false,
    gameName: ALL_GAMES,
    'bb/pr': '',
    period: ALL_PERIOD,
  })

  return (
    <>
      <Card>
        <Formik
          initialValues={{
            audience: audience.length > 0 ? audience : [addAttribute()],
          }}
        >
          {(formikProps) => (
            <Form>
              {formikProps.values.audience.map((attr, i) => {
                const formikC = formikProps.values.audience[i].comparator
                const attrMapVal =
                  attributesMap[attr.attribute] || defaultAttrMapVal

                return (
                  <div key={i}>
                    {i !== 0 && <Divider placement={'left'}>And also</Divider>}
                    <div className={styles.conditionHeader}>
                      <Title level={3}>
                        <Bold>Audience</Bold> Condition - {i + 1}
                      </Title>
                      {formikProps.values.audience.length > 1 && (
                        <img
                          src={TrashCan}
                          alt={'Delete'}
                          onClick={() => {
                            const aud = [
                              ...formikProps.values.audience.slice(0, i),
                              ...formikProps.values.audience.slice(i + 1),
                            ]
                            formikProps.setFieldValue('audience', aud)
                            updateAudience(aud)
                          }}
                        />
                      )}
                    </div>
                    <div className={styles.attributes}>
                      <Select
                        label={'Attribute'}
                        options={Object.keys(attributesMap).map((k) => ({
                          value: k,
                          label: k,
                        }))}
                        value={{
                          value: attr.attribute,
                          label: attr.attribute,
                        }}
                        onChange={(option) => {
                          const aud = [...formikProps.values.audience]
                          aud[i].attribute = option.value
                          aud[i].comparator = ''
                          formikProps.setFieldValue('audience', aud)
                          updateAudience(aud)
                        }}
                      />

                      {formikProps.values.audience[i].attribute &&
                      attributesMap[formikProps.values.audience[i].attribute]
                        ?.hasGameWise ? (
                        <Select
                          label={'Game'}
                          options={[
                            {
                              value: ALL_GAMES,
                              label: ALL_GAMES,
                            },
                            ...ATTRIBUTE_GAME_NAMES.map((g) => g.name).map(
                              (g) => ({
                                value: g,
                                label: g,
                              })
                            ),
                          ]}
                          value={{
                            value: attr.gameName,
                            label: attr.gameName,
                          }}
                          onChange={(option) => {
                            const aud = [...formikProps.values.audience]
                            aud[i].gameName = option.value
                            formikProps.setFieldValue('audience', aud)
                            updateAudience(aud)
                          }}
                        />
                      ) : null}

                      {formikProps.values.audience[i].attribute &&
                      attributesMap[formikProps.values.audience[i].attribute]
                        ?.hasGameWise &&
                      formikProps.values.audience[i].gameName !== ALL_GAMES ? (
                        <Input
                          label={'BB/PR'}
                          type="text"
                          value={attr['bb/pr']}
                          onChange={(e) => {
                            const aud = [...formikProps.values.audience]
                            aud[i]['bb/pr'] = e
                            formikProps.setFieldValue('audience', aud)
                            updateAudience(aud)
                          }}
                        />
                      ) : null}

                      {!!formikProps.values.audience[i].attribute && (
                        <div className={styles.withValue}>
                          {attrMapVal.type === 'STRING' ? (
                            <Select
                              label={'With Value'}
                              options={comparatorsString.map((c) => ({
                                value: c,
                                label: c,
                              }))}
                              value={{
                                value: attr.comparator,
                                label: attr.comparator,
                              }}
                              onChange={(option) => {
                                const aud = [...formikProps.values.audience]
                                aud[i].comparator = option.value
                                formikProps.setFieldValue('audience', aud)
                                updateAudience(aud)
                              }}
                            />
                          ) : (
                            <Select
                              label={'With Value'}
                              options={comparators.map((c) => ({
                                value: c,
                                label: c,
                              }))}
                              value={{
                                value: attr.comparator,
                                label: attr.comparator,
                              }}
                              onChange={(option) => {
                                const aud = [...formikProps.values.audience]
                                aud[i].comparator = option.value

                                if (option.value === '<=') {
                                  if (!!aud[i].min) {
                                    aud[i].max = aud[i].min
                                    aud[i].min = null
                                  }
                                }

                                if (option.value === '>=') {
                                  if (!!aud[i].max) {
                                    aud[i].min = aud[i].max
                                    aud[i].max = null
                                  }
                                }

                                formikProps.setFieldValue('audience', aud)
                                updateAudience(aud)
                              }}
                            />
                          )}

                          {!!formikProps.values.audience[i].comparator && (
                            <>
                              {attrMapVal.type === 'NUMBER' && (
                                <>
                                  <Input
                                    label={'  '}
                                    type="number"
                                    value={
                                      formikC === '<=' ? attr.max : attr.min
                                    } // for less than filter we need to set the max value
                                    onChange={(e) => {
                                      const aud = [
                                        ...formikProps.values.audience,
                                      ]

                                      if (formikC === '<=') {
                                        aud[i].max = e
                                      } else {
                                        aud[i].min = e
                                      }

                                      formikProps.setFieldValue('audience', aud)
                                      updateAudience(aud)
                                    }}
                                  />
                                  {formikProps.values.audience[i].comparator ===
                                    comparators[comparators.length - 1] && (
                                    <>
                                      <Text customClasses={[styles.to]}>
                                        <Bold type={'medium'}>to</Bold>
                                      </Text>
                                      <Input
                                        label={'  '}
                                        type="number"
                                        value={attr.max}
                                        onChange={(e) => {
                                          const aud = [
                                            ...formikProps.values.audience,
                                          ]
                                          aud[i].max = e
                                          formikProps.setFieldValue(
                                            'audience',
                                            aud
                                          )
                                          updateAudience(aud)
                                        }}
                                      />
                                    </>
                                  )}
                                </>
                              )}
                              {attrMapVal.type === 'TIME' && (
                                <>
                                  <DatePicker
                                    label={'  '}
                                    selected={
                                      (formikC === '<='
                                        ? attr.max
                                        : attr.min) || new Date()
                                    }
                                    dateFormat="MMMM d, yyyy"
                                    onChange={(date) => {
                                      const aud = [
                                        ...formikProps.values.audience,
                                      ]

                                      if (formikC === '<=') {
                                        aud[i].max = new Date(
                                          moment(date).startOf('day').format()
                                        )
                                      } else {
                                        aud[i].min = new Date(
                                          moment(date).startOf('day').format()
                                        )
                                      }
                                      formikProps.setFieldValue('audience', aud)
                                      updateAudience(aud)
                                    }}
                                  />
                                  {formikProps.values.audience[i].comparator ===
                                    comparators[comparators.length - 1] && (
                                    <>
                                      <Text customClasses={[styles.to]}>
                                        <Bold type={'medium'}>to</Bold>
                                      </Text>
                                      <DatePicker
                                        label={'  '}
                                        selected={attr.max || new Date()}
                                        dateFormat="MMMM d, yyyy"
                                        onChange={(date) => {
                                          const aud = [
                                            ...formikProps.values.audience,
                                          ]
                                          aud[i].max = new Date(
                                            moment(date).endOf('day').format()
                                          )
                                          formikProps.setFieldValue(
                                            'audience',
                                            aud
                                          )
                                          updateAudience(aud)
                                        }}
                                      />
                                    </>
                                  )}
                                </>
                              )}
                              {attrMapVal.type === 'STRING' && (
                                <Input
                                  label={'  '}
                                  type="text"
                                  value={attr.stringValue}
                                  onChange={(e) => {
                                    const aud = [...formikProps.values.audience]
                                    aud[i].stringValue = e

                                    formikProps.setFieldValue('audience', aud)
                                    updateAudience(aud)
                                  }}
                                />
                              )}
                            </>
                          )}
                        </div>
                      )}

                      {attrMapVal.type === 'TIME' && (
                        <Checkbox
                          id="lateRegistrationAllowed"
                          checked={attr.isRolling}
                          onChange={() => {
                            const aud = [...formikProps.values.audience]
                            aud[i].isRolling = !aud[i].isRolling
                            formikProps.setFieldValue('audience', aud)
                            updateAudience(aud)
                          }}
                          label={'Is Rolling Attribute'}
                        />
                      )}

                      {!!formikProps.values.audience[i].comparator &&
                        !!(
                          formikProps.values.audience[i].max ||
                          formikProps.values.audience[i].min ||
                          formikProps.values.audience[i].stringValue
                        ) && (
                          <>
                            {attrMapVal.asPerValues && (
                              <Select
                                label={'As Per'}
                                options={attrMapVal.asPerValues.map((c) => ({
                                  value: c,
                                  label: c,
                                }))}
                                value={{ value: attr.asPer, label: attr.asPer }}
                                onChange={(option) => {
                                  const aud = [...formikProps.values.audience]
                                  aud[i].asPer = option.value
                                  formikProps.setFieldValue('audience', aud)
                                  updateAudience(aud)
                                }}
                              />
                            )}
                            {attrMapVal.between && (
                              <div className={styles.between}>
                                <DatePicker
                                  label={'Between'}
                                  selected={attr.during[0].setHours(0, 0, 0, 0)}
                                  dateFormat="MMMM d, yyyy"
                                  endDate={attr.during[0].setHours(0, 0, 0, 0)}
                                  onChange={(date) => {
                                    const aud = [...formikProps.values.audience]
                                    aud[i].during[0] = new Date(
                                      moment(date).startOf('day').format()
                                    )
                                    formikProps.setFieldValue('audience', aud)
                                    updateAudience(aud)
                                  }}
                                />
                                <Text customClasses={[styles.to]}>
                                  <Bold type={'medium'}>to</Bold>
                                </Text>
                                <DatePicker
                                  label={'  '}
                                  selected={attr.during[1].setHours(0, 0, 0, 0)}
                                  dateFormat="MMMM d, yyyy"
                                  endDate={attr.during[1].setHours(0, 0, 0, 0)}
                                  onChange={(date) => {
                                    const aud = [...formikProps.values.audience]
                                    aud[i].during[1] = new Date(
                                      moment(date).startOf('day').format()
                                    )
                                    formikProps.setFieldValue('audience', aud)
                                    updateAudience(aud)
                                  }}
                                />
                              </div>
                            )}
                          </>
                        )}
                    </div>
                  </div>
                )
              })}
              <Divider />
              <Button
                type="text"
                onClick={() => {
                  const aud = [...formikProps.values.audience, addAttribute()]
                  formikProps.setFieldValue('audience', aud)
                  updateAudience(aud)
                }}
              >
                + Add Attribute
              </Button>
            </Form>
          )}
        </Formik>
      </Card>
      <Card>
        <Title level={3}>
          <Bold>Exclude</Bold> Players
        </Title>
        <Text weight={'small'} customClasses={[styles.excludeDesc]}>
          <Bold>Upload</Bold> CSV of player IDs to exclude from audience
        </Text>
        <CSVUploader setValues={updateExcludedPlayerIds} />
      </Card>
    </>
  )
}

export default EntryConditions
