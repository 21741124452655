import {
  all,
  call,
  put,
  takeLatest,
  cancel,
  cancelled,
  take,
  fork,
} from 'redux-saga/effects'
import AuthActions, { AuthTypes } from 'Redux/AuthRedux'
import firebase from 'firebase/app'
import 'firebase/auth'
import { rsf } from '../firebase'
import { navigate } from '@reach/router'

const authProvider = new firebase.auth.GoogleAuthProvider()

export function* onLogin(api) {
  try {
    const data = yield call(rsf.auth.signInWithPopup, authProvider)
    yield call(fetchCredentials, data.idToken)
  } catch (error) {
    console.log('error with google login', error)
    yield put(AuthActions.loginFailure(error))
  } finally {
    if (yield cancelled()) {
      yield call(onLogout)
    }
  }
}

function* fetchCredentials(id_token) {
  let credential
  try {
    credential = firebase.auth.GoogleAuthProvider.credential(id_token)

    const data = yield call(
      rsf.auth.signInAndRetrieveDataWithCredential,
      credential
    )

    if (data.additionalUserInfo.profile.hd !== 'getmega.com') {
      yield cancel(data)
    }

    yield put(AuthActions.loginSuccess(data))
    localStorage.setItem('accessToken', data.user.xa)
  } catch (error) {
    console.log('error fetching credentials', error)
    yield put(AuthActions.loginFailure(error))
  } finally {
    if (yield cancelled()) {
      yield call(onLogout)
    }
  }
}

function* checkIfLoggedIn() {
  const channel = yield call(rsf.auth.channel)

  while (true) {
    const { user } = yield take(channel)

    if (user) {
      yield put(AuthActions.loginSuccess({ user }))
      localStorage.setItem('accessToken', user.xa)

      if (window.location.pathname === '/') navigate('schedule')
    } else {
      // token not available/expired, need to login again
      yield put(AuthActions.loginFailure(new Error()))
      navigate('/')
    }
  }
}

export function* onLogout(api, action) {
  try {
    yield call(rsf.auth.signOut)
    localStorage.clear()
  } catch (error) {}
}

export default function* authSagas(api) {
  yield fork(checkIfLoggedIn)

  yield all([
    takeLatest(AuthTypes.LOGIN_REQUEST, onLogin, api),
    takeLatest(AuthTypes.LOGOUT, onLogout, api),
  ])
}
