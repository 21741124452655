import React from 'react'
import { Bold, Card, Text, Title } from 'salt'
import styles from './PrizeDetails.module.scss'
import { InputPreview } from '../../../../Components/InputPreview'
import { walletTypes } from 'Containers/CreateLeaderboard/constants'
import { instantRewardType } from '../../constants'

const PrizeDetailsPreview = ({
  totalPrize,
  lbType,
  prizes,
  hasNonCashPrizes,
  isPartOfSummary,
}) => {
  const isRankBasedLB = lbType === 'Rank Based'

  return (
    <Card
      removeLeftMargin={isPartOfSummary}
      removeRightMargin={isPartOfSummary}
    >
      <Title level={3}>
        <Bold>Prize</Bold> Details
      </Title>
      <div className={styles.prizeDetailsPreview}>
        <InputPreview label="Total Prize" value={`Rs. ${totalPrize}/-`} />
        <InputPreview
          label={`Total ${isRankBasedLB ? 'Winners' : 'Goals'}`}
          value={prizes.length}
        />
      </div>
      <div className={styles.prizeValuesPreview}>
        {isRankBasedLB && (
          <>
            {prizes.map((prize, i) => (
              <div
                key={i}
                className={
                  hasNonCashPrizes
                    ? styles.rankRowWithNonCashPrizePreview
                    : styles.rankRowPreview
                }
              >
                <InputPreview label="Start Rank" value={prize.startRank} />
                <InputPreview
                  label="End Rank"
                  value={prize.endRank || prize.startRank}
                />
                <InputPreview label="Minimum Score" value={prize.minScore} />
                <InputPreview
                  label="Invoice Value"
                  value={`₹ ${prize.prizePerPlayer}`}
                />
                {prize.rewardType === instantRewardType ? (
                  <>
                    {!hasNonCashPrizes && (
                      <InputPreview
                        label="Reward Destination"
                        value={
                          walletTypes.find((w) => w.value === prize.walletType)
                            .label
                        }
                      />
                    )}
                    {prize.displayValue && (
                      <InputPreview
                        label="Display Value"
                        value={prize.displayValue}
                      />
                    )}
                  </>
                ) : (
                  <InputPreview
                    label="Staggered Template"
                    value={`${prize.staggeredTemplateId}`}
                  />
                )}
              </div>
            ))}
          </>
        )}
        {!isRankBasedLB && (
          <>
            {prizes.map((prize, i) => (
              <div key={i} className={styles.goalRow}>
                <Text weight={'paragraph'}>
                  <Bold>{prize.goal_name}</Bold>
                </Text>
                <InputPreview
                  label={'Winning Score'}
                  value={prize.winning_score}
                />
                {!hasNonCashPrizes && (
                  <InputPreview
                    label="Reward Destination"
                    value={
                      walletTypes.find((w) => w.value === prize.wallet_type)
                        .label
                    }
                  />
                )}
                <InputPreview
                  label={'Winning Amount'}
                  value={`₹ ${prize.winning_amount}`}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </Card>
  )
}

export default PrizeDetailsPreview
