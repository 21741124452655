import moment from 'moment'
import axios from 'axios'
import firebase from 'firebase/app'
import { getUTCTime } from 'Containers/CreateLeaderboard/Scheduler/rule'
import {
  asPerToAsOf,
  attributesMap,
  generateAttributeKey,
  validateComparatorValues,
} from 'Containers/CreateLeaderboard/constants'
import { formatSchedule } from 'Lib/Helpers'
import { generateEditLeaderboardBody } from '../EditLeaderboard/helper'

const baseUrl = '/twirp/msadmin.v1.LBTemplateService'

//TODO: Refactor this file

// TODO: Find better solution than waiting 2sec on initial render
// Add Context based component which waits for firebase auth to complete,
// then renders correct page or redirects to login
let numRetries = 0
const getAccessToken = async () => {
  let accessToken = firebase.auth().currentUser?.getIdToken()

  // promise chain will keep growing if we don't put max limit
  if (accessToken || numRetries >= 16) {
    numRetries = 0
    return accessToken
  }

  return new Promise((res) =>
    setTimeout(() => {
      numRetries += 1
      res(getAccessToken())
    }, 2000)
  )
}

const generateScheduleLeaderboardBody = ({
  spawningDetails: sd,
  audience,
  schedule,
  playerIds,
  excludedPlayerIds,
  propertyId,
}) => {
  const formattedSchedule = formatSchedule(schedule)

  let prize_distribution = {}

  if (sd.lbType.value === 'Rank') {
    prize_distribution = {
      type: 'RankBased',
      rank_based: sd.prizes.map((p) => ({
        start_rank: p.startRank,
        end_rank: p.endRank || p.startRank,
        prize: {
          winning_amount: p.prizePerPlayer || 0,
          display_value: p.displayValue,
          prize_type: p.rewardType,
          wallet_type: p.walletType,
          task_template_id: p.staggeredTemplateId,
        },
        qualification_score: p.minScore,
      })),
    }
  }

  if (sd.lbType.value === 'Goal') {
    prize_distribution = {
      type: 'GoalBased',
      goal_based: sd.goalBasedPrizes.map((g) => ({
        goal: g.goal,
        goal_name: g.goal_name,
        prize: {
          winning_amount: g.winning_amount,
          wallet_type: g.wallet_type,
        },
        winning_score: g.winning_score,
      })),
    }
  }
  let scoringSystem = {
    auto_multiplier: false,
    auto_multiplier_base: {},
    additional_multipliers: [],
  }

  if (sd.systemEvent.value === 'PokerHand') {
    scoringSystem.auto_multiplier = true
    scoringSystem.auto_multiplier_base = {
      metric_field: sd.gameName.label === 'Poker' ? 'Bigblind' : 'PointRate',
      value:
        (sd.scoringSystem[0] && sd.scoringSystem[0].bb) ||
        parseInt(sd.bbMinValue, 10),
      multiplier: (sd.scoringSystem[0] && sd.scoringSystem[0].multiplier) || 1,
      generation_gap: 0,
    }
  }

  if (sd.scoringSystem.length > 1) {
    scoringSystem.additional_multipliers = sd.scoringSystem
      .filter((ss, i) => i > 0)
      .map((ss) => {
        return {
          metric_field: 'Bigblind',
          value: parseInt(ss.bb, 10),
          multiplier: parseInt(ss.multiplier, 10),
          generation_gap: 0,
          force_apply: false,
        }
      })
  }

  let benefitingAncestorGenerations = 0
  if (sd.isReferral) {
    benefitingAncestorGenerations = sd.benefitingAncestorGenerations
    let benefitingSelf = false
    sd.degreeMultiplier.map((d) => {
      scoringSystem.additional_multipliers.push({
        multiplier: parseInt(d.multiplier, 10),
        generation_gap: d.degree,
        force_apply: false,
      })
    })
  }

  if (sd.systemEvent.value === 'Deposit') {
    scoringSystem.additional_multipliers.push({
      metric_field: 'Depositor',
      multiplier: parseInt(sd.depositorPercentage, 10) / 100,
      force_apply: true,
    })
  }

  return {
    external_name: sd.externalName,
    internal_name: sd.internalName,
    description: sd.description,
    leaderboard_category: sd.lbCategory.label,
    leaderboard_type: sd.lbType.value,
    ...(sd.lbCategory.label === 'Free' && {
      total_prize_amount: parseInt(sd.totalPrize, 10),
    }),
    metric: {
      type: sd.systemEvent.value,
      metric_filter: [
        {
          metric_field: 'GameName',
          field_type: 'STRING',
          value_range: {
            string_value: sd.gameName.label,
          },
        },
        {
          metric_field: 'GameCategory',
          field_type: 'STRING',
          value_range: {
            string_value: sd.gameCategory.label,
          },
        },
        {
          metric_field:
            sd.gameName.label === 'Poker' ? 'Bigblind' : 'PointRate',
          field_type: 'NUMBER',
          value_range: {
            number_min: parseFloat(sd.bbMinValue),
            number_max: parseFloat(sd.bbMaxValue) || parseFloat(sd.bbMinValue),
            has_min: true,
            has_max: true,
          },
        },
        {
          metric_field: 'FormatType',
          field_type: 'STRING',
          value_range: {
            string_value: sd.formatType.value || 'CashFormatDefault',
          },
        },
      ],
    },
    prize_distribution: prize_distribution,
    has_non_cash_prizes: sd.hasNonCashPrizes,
    eligibility: audience.map((attr) => {
      const attrMapVal = attributesMap[attr.attribute]
      const a = validateComparatorValues(attr)
      let a_type = attrMapVal.type,
        value_range = {},
        during = [],
        operator = 'Equals',
        as_of = null,
        is_rolling = false

      if (attrMapVal.type === 'TIME') {
        if (a.min) {
          value_range.time_min = moment(
            getUTCTime(a.min, { value: 0 }, { value: 0 })
          ).format()
        }

        if (a.max) {
          value_range.time_max = moment(
            getUTCTime(a.max, { value: 0 }, { value: 0 })
          ).format()
        }
        is_rolling = a.isRolling
      } else if (attrMapVal.type === 'STRING') {
        value_range.string_value = a.stringValue
        if (attr.comparator === '!=') {
          operator = 'NotEquals'
        }
      } else if (attrMapVal.type === 'NUMBER') {
        value_range = {
          number_min: parseFloat(a.min, 10),
          number_max: parseFloat(a.max, 10),
          has_min: !isNaN(parseFloat(a.min, 10)),
          has_max: !isNaN(parseFloat(a.max, 10)),
        }
      }

      if (attrMapVal.asPer) {
        as_of = asPerToAsOf(
          a.asPer,
          formattedSchedule.start_time,
          formattedSchedule.end_time
        )
      }

      if (attrMapVal.between) {
        during = a.during
      }

      return {
        attribute: generateAttributeKey({
          key: attrMapVal.key,
          gameName: attr.gameName,
          'bb/pr': attr['bb/pr'],
          period: attr.period,
        }),
        attribute_type: a_type,
        value_range: value_range,
        as_of: as_of,
        anchor: a.asPer,
        during: during,
        is_rolling: is_rolling,
        operator: operator,
      }
    }),
    player_ids: playerIds,
    excluded_player_ids: excludedPlayerIds,
    schedule: formattedSchedule,
    scoring_system: scoringSystem,
    benefitingAncestorGenerations: benefitingAncestorGenerations,
    ui_data: {
      tnc: {
        header: 'General Rules',
        points: sd.terms,
      },
      leaderboard_rules: {
        header: 'Leaderboard Rules',
        points: sd.rules,
      },
      banners: sd.banners || [],
      ...(sd.banner_image_url && { banner_image_url: sd.banner_image_url }),
    },
    // for paid lbs
    entry_fee: {
      amount: sd.entryFee,
      slots: sd.slots,
    },
    discount_offer: {
      discount_amount: sd.discount,
      discount_slots: sd.discountSlots,
    },
    prize_distribution_config: {
      rake_percentage: sd.rakePercentage,
      percent_winners: sd.percentOfWinningPlayers,
    },
    seed_player_count: sd.seedPlayers,
    is_opt_in: sd.lbCategory.label !== 'Paid' ? sd.optIn : true,
    late_registration_allowed: sd.lateRegistrationAllowed,
    ...(sd.lateRegistrationAllowed && {
      late_registration_duration:
        Math.ceil(sd.lateRegistrationDurationHour) * 60 +
        Math.ceil(sd.lateRegistrationDurationMin),
    }),
    propertyId,
  }
}

export const scheduleLeaderboard = async ({
  spawningDetails,
  audience,
  schedule,
  playerIds,
  excludedPlayerIds,
  propertyId,
}) => {
  const url = `${process.env.REACT_APP_FETCH_URL}${baseUrl}/CreateTemplate`
  const data = generateScheduleLeaderboardBody({
    spawningDetails,
    audience,
    schedule,
    playerIds,
    excludedPlayerIds,
    propertyId,
  })

  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data,
  })
    .then((response) => {
      const templateId = response.data.template_id
      return {
        templateId,
        msg: '',
      }
    })
    .catch((error) => {
      console.error(error)
      const { msg } = error.response.data
      return {
        templateId: '',
        msg,
      }
    })
}

export const getLeaderboards = async (date) => {
  const url = `${process.env.REACT_APP_FETCH_URL}/twirp/msadmin.v1.LeaderboardsService/ListLeaderboards`
  const data = {
    date,
  }

  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data,
  })
    .then((response) => {
      const leaderboards = response.data.leaderboards_response || []
      return {
        leaderboards,
        error: '',
      }
    })
    .catch((error) => {
      console.error(error)
      const { msg } = error?.response?.data || 'Error!'
      return {
        leaderboards: [],
        error: msg,
      }
    })
}

export const getLeaderboardDetails = async (lbId) => {
  const url = `${process.env.REACT_APP_FETCH_URL}/twirp/msadmin.v1.LeaderboardsService/LeaderboardDetails`
  const data = { leaderboard_id: lbId }

  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data,
  })
    .then((response) => {
      const leaderboards = response.data || {
        leaderboard_detail: {},
        ledaerboard_entries: [],
      }
      return {
        leaderboards,
        error: '',
      }
    })
    .catch((error) => {
      const { msg } = error.response.data
      return {
        leaderboards: [],
        error: msg,
      }
    })
}

export const getLeaderboardEntries = async (
  lbId,
  playerId,
  goal,
  allGoalsCompleted
) => {
  const url = `${process.env.REACT_APP_FETCH_URL}/twirp/msadmin.v1.LeaderboardsService/LeaderboardEntries`
  const data = {
    leaderboard_id: lbId,
    player_id: playerId,
    count: 30,
    goal_filter: { goal: goal, all_goals_completed: allGoalsCompleted },
  }

  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data,
  })
    .then((response) => {
      const leaderboards = response.data.leaderboard_entries || []
      return {
        leaderboards,
        error: '',
      }
    })
    .catch((error) => {
      console.error(error?.response?.data)
      const { msg } = error?.response?.data && error.response.data
      return {
        leaderboards: [],
        error: msg,
      }
    })
}

// hack to check access
export const hasAccess = async () => {
  const url = `${process.env.REACT_APP_FETCH_URL}/twirp/msadmin.v1.LeaderboardsService/ListLeaderboards`
  const date = new Date()
  const data = {
    date: moment(date).startOf('day').format(),
  }

  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data,
  })
    .then((response) => {
      return true
    })
    .catch((error) => {
      // TODO: Need to check if access control is there on backend as well
      // this can be bypassed
      return error.message !== 'Request failed with status code 403'
    })
}

export const deleteLeaderboard = async (lbId) => {
  const url = `${process.env.REACT_APP_FETCH_URL}/twirp/msadmin.v1.LeaderboardsService/CancelLeaderboard`
  const data = { leaderboard_id: lbId }

  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data,
  })
    .then((_) => {
      return {
        error: '',
      }
    })
    .catch((error) => {
      console.error(error.response.data)
      const { msg } = error.response.data
      return {
        leaderboards: [],
        msg,
      }
    })
}

const getGCSSignedUrl = async (fileNames) => {
  const url = `${process.env.REACT_APP_FETCH_URL}/twirp/msadmin.v1.LBTemplateService/GetImageUploadUrl`
  const data = {
    file_names: fileNames,
  }

  const { signed_urls } = await axios({
    url,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    data,
  })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      console.error(err)
      return {}
    })

  return signed_urls
}

export const uploadLeaderboardBannerImages = async (sd) => {
  const getFileName = (file) => `${sd.internalName}-${file.name}`

  let lbFileNames = {}

  if (sd.hasNonCashPrizes) {
    sd.leaderboardBanners.filter(Boolean).forEach((banner) => {
      lbFileNames[banner.name] = getFileName(banner)
    })
  }

  const lbBannerImage = sd.leaderboardBannerImage
  if (sd.lbCategory.label === 'Paid' && !!lbBannerImage) {
    lbFileNames[lbBannerImage.name] = getFileName(lbBannerImage)
  }

  // get signed urls for each file name
  const signed_urls = await getGCSSignedUrl(Object.values(lbFileNames))

  // TODO: do everything in parallel
  // upload lb banner image
  let banner_image_url = ''
  if (sd.lbCategory.label === 'Paid' && !!lbBannerImage) {
    const { signed_url, file_path } =
      signed_urls[lbFileNames[lbBannerImage.name]]
    if (signed_url) {
      await axios(signed_url, {
        method: 'PUT',
        headers: {
          'Content-Type': lbBannerImage.type,
        },
        data: lbBannerImage,
      })
        .then(() => (banner_image_url = file_path))
        .catch((err) => {
          console.error(err)
        })
    }
  }

  // upload lb banners
  const banners = []
  if (sd.hasNonCashPrizes) {
    for (let i = 0; i < sd.leaderboardBanners.length; i++) {
      let file = sd.leaderboardBanners[i]
      const { signed_url, file_path } = signed_urls[lbFileNames[file.name]]

      if (signed_url) {
        await axios(signed_url, {
          method: 'PUT',
          headers: {
            'Content-Type': file.type,
          },
          data: file,
        })
          .then(() => {
            banners.push({ image_url: file_path, sort_index: i + 1 })
          })
          .catch((err) => {
            console.error(err)
          })
      }
    }
  }

  return {
    banner_image_url,
    banners,
  }
}

export const editLeaderboard = async (details, leaderboardId) => {
  const url = `${process.env.REACT_APP_FETCH_URL}/twirp/msadmin.v1.LeaderboardsService/EditLeaderboard`
  const data = generateEditLeaderboardBody(details, leaderboardId)

  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data,
  })
    .then((response) => {
      return { leaderboardId, msg: '' }
    })
    .catch((error) => {
      console.error(error)
      const { msg } = error.response.data
      return {
        leaderboardId: '',
        msg,
      }
    })
}

export const getTemplateDetails = async (templateId) => {
  const url = `${process.env.REACT_APP_FETCH_URL}${baseUrl}/TemplateDetails`
  const data = { template_id: templateId }

  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data,
  })
    .then((response) => {
      const templateDetails = response.data || {}
      return {
        templateDetails,
        error: '',
      }
    })
    .catch((error) => {
      const { msg } = error.response.data
      return {
        templateDetails: {},
        error: msg,
      }
    })
}

export const getStaggeredTemplates = async () => {
  const url = `${process.env.REACT_APP_FETCH_URL}/twirp/karma.v1.Karma/ListStaggeredTemplates`

  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data: {},
  })
    .then((response) => {
      const data = response.data || {}
      return {
        templates: data.staggered_templates || [],
        error: '',
      }
    })
    .catch((error) => {
      const { msg } = error.response.data
      return {
        templates: [],
        error: msg,
      }
    })
}
