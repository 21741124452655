import { toISTFormattedTime } from 'Containers/CreateLeaderboard/Scheduler/rule'
import { navigate } from '@reach/router'
import moment from 'moment'
import {
  attributesMap,
  formatTypeOp,
  gameCategoryOp,
  gameNameOp,
  getSubAttributes,
  lbCategoryOp,
  lbTypeOp,
  numberTypeOp,
  systemEvents,
} from 'Containers/CreateLeaderboard/constants'
import { Tag } from 'salt'
import OrangeCheckIcon from 'Images/OrangeCheck.svg'
import RedCrossIcon from 'Images/RedCross.svg'
import LiveIcon from 'Images/Live.svg'
import HourGlassIcon from 'Images/HourGlass.svg'
import React from 'react'
import { getTemplateDetails } from './api'

const SPLIT_UPPER_CAMEL_CASE_REGEX = /([a-z])([A-Z])/g
export const responseToDetails = (d) => {
  if (!d?.ext_name) return {}

  let gameName,
    gameCategory,
    formatType,
    bbMinValue,
    bbMaxValue = ''

  d.filters.forEach((mf) => {
    if (mf.metric_field === 'GameName') {
      gameName = gameNameOp.find((g) => g.label === mf.value_range.string_value)
    }

    if (mf.metric_field === 'GameCategory') {
      gameCategory = gameCategoryOp.find(
        (g) => g.label === mf.value_range.string_value
      )
    }

    if (mf.metric_field === 'Bigblind' || mf.metric_field === 'PointRate') {
      bbMinValue = mf.value_range.number_min
      if (mf.value_range.number_min !== mf.value_range.number_max) {
        bbMaxValue = mf.value_range.number_max
      }
    }

    if (mf.metric_field === 'FormatType') {
      formatType = formatTypeOp.find(
        (g) => g.value === mf.value_range.string_value
      )
    }
  })

  formatType = formatType || formatTypeOp[0]

  let lbCategory = lbCategoryOp.find((l) => l.label === d.category)
  let lbType = lbTypeOp.find(
    (l) => l.label === d.type.replace(SPLIT_UPPER_CAMEL_CASE_REGEX, '$1 $2')
  )
  let systemEvent = systemEvents.find((event) => event.value === d.metric)

  const sD = {
    externalName: d.ext_name,
    internalName: d.internal_name,
    description: d.description,
    lbCategory: lbCategory,
    lbType: lbType,
    formatType: formatType,
    systemEvent: systemEvent,
    totalPrize: d.total_prize_amount,
    totalPlayersCount: d.total_players_count || 0,
    gameName: gameName,
    gameCategory: gameCategory,
    bbMinValue: bbMinValue,
    bbMaxValue: bbMaxValue,
    bbType: bbMaxValue === '' ? numberTypeOp[0] : numberTypeOp[1],
    prizes:
      d.prize_distribution?.rank_based?.map((p) => {
        const prize = {
          startRank: p.start_rank,
          prizePerPlayer: p.prize.winning_amount,
          minScore: p.qualification_score,
          displayValue: p.prize.display_value,
          walletType: p.prize.wallet_type,
          rewardType: p.prize.prize_type,
          staggeredTemplateId: p.prize.task_template_id,
        }

        if (p.start_rank !== p.end_rank) prize.endRank = p.end_rank

        return prize
      }) || [],
    goalBasedPrizes: d.prize_distribution?.goal_based?.map((g) => {
      return {
        goal: 1 * g.goal,
        goal_name: g.goal_name,
        winning_amount: g.prize.winning_amount,
        winning_score: g.winning_score,
        wallet_type: g.prize.wallet_type,
      }
    }),
    startTime: toISTFormattedTime(d.live_time),
    endTime: toISTFormattedTime(d.close_time),
    optIn: d.is_opt_in,
    lateRegistrationAllowed: d.late_registration_allowed,
    lateRegistrationEndTime: toISTFormattedTime(d.late_registration_end_time),
    terms: d.ui_data && d.ui_data.tnc && d.ui_data.tnc.points,
    rules:
      d.ui_data &&
      d.ui_data.leaderboard_rules &&
      d.ui_data.leaderboard_rules.points,
    benefitingAncestorGenerations: d.benefiting_ancestor_generations,
    isReferral: d.benefiting_ancestor_generations > 0,
    hasNonCashPrizes: !!d.has_non_cash_prizes,
  }

  let audience = eligibilityToAudience(d)

  return {
    spawningDetails: sD,
    audience: audience,
  }
}

export const eligibilityToAudience = (d) => {
  return d.eligibility?.map((el) => {
    let e = getSubAttributes(el.attribute)
    e = { ...el, ...e }

    const attribute = Object.keys(attributesMap).find(
      (key) => attributesMap[key].key === e.attribute
    )

    const audienceMap = {
      attribute: attribute || e.attribute,
      asOf: toISTFormattedTime(e.as_of),
    }

    if (e.attribute_type === 'TIME') {
      audienceMap.min = toISTFormattedTime(e.value_range.time_min)
      audienceMap.max = toISTFormattedTime(e.value_range.time_max)
    } else if (e.attribute_type === 'NUMBER') {
      if (e.value_range.has_min) audienceMap.min = e.value_range.number_min || 0
      if (e.value_range.has_max) audienceMap.max = e.value_range.number_max
    } else if (e.attribute_type === 'STRING') {
      audienceMap.stringValue = e.value_range.string_value
    }

    if (!!e.during && e.during.length > 1) {
      audienceMap.during = [
        toISTFormattedTime(e.during[0]),
        toISTFormattedTime(e.during[1]),
      ]
    }

    if (audienceMap.min !== undefined && !audienceMap.max) {
      audienceMap.comparator = '>='
    } else if (!audienceMap.min && audienceMap.max) {
      audienceMap.comparator = '<='
    } else if (audienceMap.min && audienceMap.max) {
      audienceMap.comparator = 'Between'
    } else {
      audienceMap.comparator = '='
    }

    if (e.attribute_type === 'STRING') {
      if (e.operator === 'NotEquals') {
        audienceMap.comparator = '!='
      }
    }

    return audienceMap
  })
}

export const templateToDetails = (d) => {
  if (!d.external_name) return {}

  let gameName,
    gameCategory,
    formatType,
    bbMinValue,
    bbMaxValue = ''

  d.metric.metric_filter.forEach((mf) => {
    if (mf.metric_field === 'GameName') {
      gameName = gameNameOp.find((g) => g.label === mf.value_range.string_value)
    }

    if (mf.metric_field === 'GameCategory') {
      gameCategory = gameCategoryOp.find(
        (g) => g.label === mf.value_range.string_value
      )
    }

    if (mf.metric_field === 'Bigblind' || mf.metric_field === 'PointRate') {
      bbMinValue = mf.value_range.number_min
      if (mf.value_range.number_min !== mf.value_range.number_max) {
        bbMaxValue = mf.value_range.number_max
      }
    }

    if (mf.metric_field === 'FormatType') {
      formatType = formatTypeOp.find(
        (g) => g.value === mf.value_range.string_value
      )
    }
  })

  formatType = formatType || formatTypeOp[0]

  let lbCategory = lbCategoryOp.find((l) => l.label === d.leaderboard_category)
  let lbType = lbTypeOp.find((l) => l.value === d.leaderboard_type)
  let systemEvent = systemEvents.find((event) => event.value === d.metric.type)

  let lateRegistrationDurationMin = 0,
    lateRegistrationDurationHour = 0
  let ld = Math.ceil(d.late_registration_duration)
  if (ld > 0) {
    lateRegistrationDurationHour = Math.floor(ld / 60)
    lateRegistrationDurationMin = ld % 60
  }

  let prizes = []
  if (lbCategory.label === 'Free') {
    prizes =
      d.prize_distribution?.rank_based?.map((p) => {
        const prize = {
          startRank: Math.ceil(p.start_rank),
          prizePerPlayer: Math.ceil(p.prize.winning_amount),
          minScore: p.qualification_score,
          displayValue: p.prize.display_value,
          walletType: p.prize.wallet_type,
          rewardType: p.prize.prize_type,
          staggeredTemplateId: p.prize.task_template_id,
        }

        if (p.start_rank !== p.end_rank) prize.endRank = Math.ceil(p.end_rank)

        return prize
      }) || []
  }

  let scoringSystem = []

  if (d.scoring_system.auto_multiplier_base) {
    scoringSystem.push({
      bb: d.scoring_system.auto_multiplier_base.value,
      multiplier: d.scoring_system.auto_multiplier_base.multiplier,
    })
  }

  if (d.scoring_system.additional_multipliers) {
    d.scoring_system.additional_multipliers.map((adm) =>
      scoringSystem.push({
        bb: adm.value,
        multiplier: adm.multiplier,
      })
    )
  }

  const sD = {
    externalName: d.external_name,
    internalName: d.internal_name,
    description: d.description,
    lbCategory: lbCategory,
    lbType: lbType,
    formatType: formatType,
    systemEvent: systemEvent,
    totalPrize: Math.ceil(d.total_prize_amount),
    gameName: gameName,
    gameCategory: gameCategory,
    bbMinValue: bbMinValue,
    bbMaxValue: bbMaxValue,
    bbType: bbMaxValue === '' ? numberTypeOp[0] : numberTypeOp[1],
    prizes,
    goalBasedPrizes:
      d.prize_distribution?.goal_based?.map((g) => {
        return {
          goal_name: g.goal_name,
          winning_amount: Math.ceil(g.prize.winning_amount),
          winning_score: Math.ceil(g.winning_score),
          goal: Math.ceil(g.goal),
          wallet_type: g.prize.wallet_type,
        }
      }) || [],
    hasNonCashPrizes: d.has_non_cash_prizes,
    optIn: d.is_opt_in,
    lateRegistrationAllowed: d.late_registration_allowed,
    terms: d.ui_data && d.ui_data.tnc && d.ui_data.tnc.points,
    rules:
      d.ui_data &&
      d.ui_data.leaderboard_rules &&
      d.ui_data.leaderboard_rules.points,
    entryFee: d.entry_fee.amount ? Math.ceil(d.entry_fee.amount) : 0,
    slots: d.entry_fee.slots ? Math.ceil(d.entry_fee.slots) : 0,
    discount: d.discount_offer.discount_amount
      ? Math.ceil(d.discount_offer.discount_amount)
      : 0,
    discountSlots: d.discount_offer.discount_slots
      ? Math.ceil(d.discount_offer.discount_slots)
      : 0,
    percentOfWinningPlayers: d.prize_distribution_config.percent_winners
      ? Math.ceil(d.prize_distribution_config.percent_winners)
      : 0,
    rakePercentage: d.prize_distribution_config.rake_percentage
      ? Math.ceil(d.prize_distribution_config.rake_percentage)
      : 0,
    seedPlayers: d.seed_player_count ? Math.ceil(d.seed_player_count) : 0,
    lateRegistrationDurationMin,
    lateRegistrationDurationHour,
    scoringSystem,
    benefitingAncestorGenerations: d.benefiting_ancestor_generations,
    isReferral: d.benefiting_ancestor_generations > 0,
    ...(d.benefiting_ancestor_generations > 0 && {
      degreeMultiplier: d.scoring_system.additional_multipliers.map((am, i) => {
        return {
          name: `Degree ${i + 1}`,
          multiplier: am.multiplier,
        }
      }),
    }),
  }

  let audience = d.eligibility?.map((e) => {
    const {
      attribute: attr,
      gameName: attrGameName,
      'bb/pr': bb_pr,
      period,
    } = getSubAttributes(e.attribute)
    const attribute = Object.keys(attributesMap).find(
      (key) => attributesMap[key].key === attr
    )

    let defaultMinDate = new Date()
    let defaultMaxDate = new Date()
    defaultMinDate.setHours(0, 0, 0, 0)
    defaultMaxDate.setDate(defaultMaxDate.getDate() + 1)
    defaultMaxDate.setHours(0, 0, 0, 0)

    const audienceMap = {
      attribute: attribute || e.attribute,
      asOf: toISTFormattedTime(e.as_of),
      during: [defaultMinDate, defaultMaxDate],
      gameName: attrGameName,
      'bb/pr': bb_pr,
      period,
    }

    if (e.attribute_type === 'TIME') {
      // this means a long date has been selected
      let fsTime =
        Math.abs(moment(e.value_range.time_min).year() - moment().year()) > 30
      let feTime =
        Math.abs(moment(e.value_range.time_max).year() - moment().year()) > 30

      if (!fsTime) {
        audienceMap.min = new Date(
          moment(e.value_range.time_min).startOf('day').format()
        )
      }

      if (!feTime) {
        audienceMap.max = new Date(
          moment(e.value_range.time_max).startOf('day').format()
        )
      }

      audienceMap.isRolling = e.is_rolling
    } else if (e.attribute_type === 'NUMBER') {
      if (e.value_range.has_min) audienceMap.min = e.value_range.number_min || 0
      if (e.value_range.has_max) audienceMap.max = e.value_range.number_max
    } else if (e.attribute_type === 'STRING') {
      audienceMap.stringValue = e.value_range.string_value
    }

    if (e.attribute === 'Player.JoinedAt') {
      audienceMap.comparator = '<='
    } else {
      if (audienceMap.min !== undefined && !audienceMap.max) {
        audienceMap.comparator = '>='
      } else if (!audienceMap.min && audienceMap.max) {
        audienceMap.comparator = '<='
      } else if (audienceMap.min && audienceMap.max) {
        audienceMap.comparator = 'Between'
      } else {
        audienceMap.comparator = '='
      }
    }

    audienceMap.asPer = e.anchor

    return audienceMap
  })

  return {
    spawningDetails: sD,
    audience: audience,
  }
}

export const getStateTag = (state) => {
  switch (state) {
    case 'ResultDeclared':
      return <Tag type={'warning'} icon={OrangeCheckIcon} />
    case 'Cancelled':
      return <Tag type={'danger'} icon={RedCrossIcon} />
    case 'Live':
      return <Tag type={'success'} icon={LiveIcon} />
    case 'Draft':
    case 'Open':
      return <Tag type={'primary'} icon={HourGlassIcon} />
    default:
      return null
  }
}

export const createLbFromTemplate = async (templateId) => {
  const { templateDetails: td, error: err } = await getTemplateDetails(
    templateId
  )
  if (!!err) {
    console.log(err)
    throw new Error(err)
  } else {
    let details = templateToDetails(td)
    navigate('/create-leaderboard', {
      state: {
        spawningDetails: details.spawningDetails,
        audience: details.audience,
      },
    })
  }
}
