import React from 'react'
import { NamingDetailsPreview } from './NamingDetails'
import { BannerDetailsPreview } from './BannerDetails'

const SpawningPreview = ({ sD, updateSD }) => {
  const { internalName, externalName, description, largeImage, smallImage } = sD
  return (
    <>
      <NamingDetailsPreview
        updateSD={updateSD}
        internalName={internalName}
        externalName={externalName}
        description={description}
      />

      <BannerDetailsPreview
        updateSD={updateSD}
        largeImage={largeImage}
        smallImage={smallImage}
      />
    </>
  )
}

export default SpawningPreview
