import React from 'react'
import { Bold, Card, Title } from 'salt'
import { CSVUploader } from 'Components/CSVUploader'

const CSVInput = ({ updatePlayerIds, setCSVValidation }) => {
  return (
    <Card>
      <Title level={3}>
        <Bold>Upload</Bold> CSV
      </Title>
      <CSVUploader
        setValues={updatePlayerIds}
        setValidationResult={setCSVValidation}
      />
    </Card>
  )
}

export default CSVInput