import React from 'react'
import styles from './EntryConditions/EntryConditions.module.scss'
import { Bold, Card, Divider, Title } from 'salt'
import EntryConditionsPreview from './EntryConditions/EntryConditionsPreview'

const SelectAudiencePreview = ({ audience, schedule, isPartOfSummary }) => {
  return (
    <Card
      removeRightMargin={isPartOfSummary}
      removeLeftMargin={isPartOfSummary}
    >
      <Title level={3}>
        <Bold>Entry</Bold> Conditions
      </Title>
      <Divider />
      <div className={styles.entryConditionsPreview}>
        {audience.map((aud, i) => {
          return (
            <EntryConditionsPreview
              aud={aud}
              i={i}
              schedule={schedule}
              key={i}
            />
          )
        })}

        {audience.length === 0 ? (
          <div className={styles.csvPreview}>
            No preview is available for csv upload!
          </div>
        ) : null}
      </div>
    </Card>
  )
}

export default SelectAudiencePreview
