import React, { useMemo } from 'react'
import { Bold, Card, Table, Text, Title } from 'salt'
import styles from './Scheduler.module.scss'
import { InputPreview } from 'Components/InputPreview'
import moment from 'moment'
import toRRule from './rule'

const SchedulerPreview = ({ schedule }) => {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'serialNo',
      },
      {
        Header: 'Start Date & Time',
        accessor: 'start',
      },
      {
        Header: 'Weekday',
        accessor: 'weekday',
      },
    ],
    []
  )

  const dates = useMemo(() => {
    let rrule
    let dates = []
    if (schedule) {
      rrule = toRRule(schedule)
      dates = rrule.all().map((date) => moment(date))
    }

    const recurrences = dates.map((date, i) => ({
      serialNo: `${i + 1}.`,
      start: date.format('LLL'),
      weekday: date.format('dddd'),
    }))

    return recurrences
  }, [schedule])

  return (
    <>
      <Card>
        <Title level={3}>
          <Bold>Leaderboard</Bold> Duration
        </Title>
        <div className={styles.lbDurationPreview}>
          <InputPreview
            label={'Start Date'}
            value={moment(schedule.startDate).format('ll')}
          />
          <InputPreview
            label={'Start Time'}
            value={`${schedule.startHour.label.split(' ')[0]}:${
              schedule.startMinute.value
            } ${schedule.startHour.label.split(' ')[1].toUpperCase()}`}
          />
          <Text customClasses={[styles.to]}>
            <Bold>to</Bold>
          </Text>
          <InputPreview
            label={'End Date'}
            value={moment(schedule.endDate).format('ll')}
          />
          <InputPreview
            label={'End Time'}
            value={`${schedule.endHour.label.split(' ')[0]}:${
              schedule.endMinute.value
            } ${schedule.endHour.label.split(' ')[1].toUpperCase()}`}
          />
        </div>
      </Card>
      <Card>
        <Title level={3}>
          <Bold>Recurrence</Bold> Dates
        </Title>
        <div className={styles.lbRecurrencePreview}>
          <Table data={dates} columns={columns} />
        </div>
      </Card>
    </>
  )
}

export default SchedulerPreview
