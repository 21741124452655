import toRrule, {
  getUTCTime,
} from 'Containers/CreateLeaderboard/Scheduler/rule'
import moment from 'moment'

const staging_ms_user = 'https://staging-msuser.getmega.com'
const prod_ms_user = 'https://msuser.getmega.com'
const baseAppUrl = 'https://getmega.com/internalWebview/Mega Champ'

export const generateDeeplink = (lbId) => {
  const envUrl =
    process.env.REACT_APP_ENV === 'production' ? prod_ms_user : staging_ms_user
  return `${baseAppUrl}/${envUrl}/leaderboard-details/${lbId}`
}

export const formatSchedule = (schedule) => {
  const {
    startDate,
    endDate,
    startHour,
    endHour,
    startMinute,
    endMinute,
  } = schedule
  const st = moment(getUTCTime(startDate, startHour, startMinute))
  const et = moment(getUTCTime(endDate, endHour, endMinute))
  const duration = et.diff(st, 'minutes', true)

  return {
    start_time: st.format(),
    end_time: et.format(),
    duration,
    r_rule_set: toRrule(schedule).toString(),
  }
}
