import styles from './GameDetails.module.scss'
import React from 'react'
import { Bold, Card, Select, Title } from 'salt'
import { gameCategoryOp, gameNameOp, systemEvents } from '../../constants'

const GameDetails = ({ updateSD, gameCategory, systemEvent, gameName }) => {
  return (
    <Card>
      <Title level={3}>
        <Bold>Game</Bold> Details
      </Title>
      <div className={styles.gameDetails}>
        <Select
          label="Select Category"
          options={gameCategoryOp}
          onChange={updateSD('gameCategory')}
          value={gameCategory}
          disabled={systemEvent.value === systemEvents[1].value}
        />
        <Select
          label="Select Game"
          options={
            !!gameCategory && !!gameCategory.label
              ? gameNameOp.filter((g) => g.type === gameCategory.label)
              : []
          }
          onChange={updateSD('gameName')}
          value={gameName}
          disabled={systemEvent.value === systemEvents[1].value}
        />
      </div>
    </Card>
  )
}

export default GameDetails
