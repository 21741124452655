import React from 'react'
import { Bold, Card, Drawer, Title, Button, Tag } from 'salt'
import CopyIcon from 'Images/Copy.svg'
import styles from './LeaderboardSummary.module.scss'
import { NamingDetailsPreview } from '../CreateLeaderboard/SpawningStep/NamingDetails'
import { LeaderboardDetailsPreview } from '../CreateLeaderboard/SpawningStep/LeaderboardDetails'
import { LeaderboardRulesPreview } from '../CreateLeaderboard/SpawningStep/LeaderboardRules'
import { TermsAndConditionsPreview } from '../CreateLeaderboard/SpawningStep/TermsAndConditions'
import { GameDetailsPreview } from '../CreateLeaderboard/SpawningStep/GameDetails'
import { TournamentDesignPreview } from '../CreateLeaderboard/SpawningStep/TournamentDesign'
import { DepositPreview } from '../CreateLeaderboard/SpawningStep/Deposit'
import { PrizeDetailsPreview } from '../CreateLeaderboard/SpawningStep/PrizeDetails'
import { InputPreview } from '../../Components/InputPreview'
import { Statistic } from '../../Components/Statistic'
import { SelectAudiencePreview } from '../CreateLeaderboard/SelectAudience'

const LeaderboardSummary = ({
  spawningDetails: sD,
  audience,
  show,
  close,
  copyTemplate,
}) => {
  return (
    <Drawer show={show} close={close} topOffset={80}>
      <div className={styles.heading}>
        <Title>
          <Bold>Leaderboard</Bold> Summary
        </Title>
      </div>
      <div className={styles.statistics}>
        <Statistic
          measure={
            <Title level={3}>
              <Bold>Total</Bold> Players
            </Title>
          }
          measurement={sD.totalPlayersCount.toLocaleString()}
        />
        <Statistic
          measure={
            <Title level={3}>
              <Bold>Total</Bold> Prize
            </Title>
          }
          measurement={`₹ ${(sD.totalPrize || 0).toLocaleString()}`}
        />
        {copyTemplate && (
          <Button type={'text'} onClick={copyTemplate}>
            <Tag type={'primary'} icon={CopyIcon} />
          </Button>
        )}
      </div>
      <Card removeRightMargin removeLeftMargin>
        <Title level={3}>
          <Bold>Leaderboard</Bold> Duration
        </Title>
        <div className={styles.dates}>
          <InputPreview label={'Start Date & Time'} value={sD.startTime} />
          <InputPreview label={'End Date & Time'} value={sD.endTime} />
        </div>
      </Card>
      <NamingDetailsPreview
        internalName={sD.internalName}
        externalName={sD.externalName}
        description={sD.description}
        isPartOfSummary
      />
      <LeaderboardDetailsPreview
        formatType={sD.formatType.label}
        lbCategory={sD.lbCategory.label}
        lbType={sD.lbType.label}
        optIn={sD.optIn}
        lateRegistrationAllowed={sD.lateRegistrationAllowed}
        lateRegistrationEndTime={sD.lateRegistrationEndTime}
        metric={sD.systemEvent.label}
        isReferral={sD.isReferral}
        hasNonCashPrizes={sD.hasNonCashPrizes}
        isPartOfSummary
      />
      <LeaderboardRulesPreview rules={sD.rules} isPartOfSummary />
      <TermsAndConditionsPreview terms={sD.terms} isPartOfSummary />
      <GameDetailsPreview
        gameName={sD.gameName.label}
        gameCategory={sD.gameCategory.label}
        isPartOfSummary
      />
      <TournamentDesignPreview
        gameName={sD.gameName.label}
        bbMaxValue={sD.bbMaxValue}
        bbMinValue={sD.bbMinValue}
        isPartOfSummary
      />
      {!!sD?.systemEvent?.label && sD.systemEvent.label === 'Deposit' && (
        <DepositPreview
          depositorPercentage={sD.depositorPercentage}
          isPartOfSummary
        />
      )}
      <PrizeDetailsPreview
        totalPrize={sD.totalPrize}
        lbType={sD.lbType.label}
        prizes={
          sD.lbType.label === 'Rank Based' ? sD.prizes : sD.goalBasedPrizes
        }
        hasNonCashPrizes={sD.hasNonCashPrizes}
        isPartOfSummary
      />
      {!!audience && audience.length > 0 && (
        <SelectAudiencePreview audience={audience} isPartOfSummary />
      )}
    </Drawer>
  )
}

export default LeaderboardSummary
