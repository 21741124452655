import React, { useCallback, useEffect, useState } from 'react'
import styles from './LeaderboardDetails.module.scss'
import { getLeaderboardDetails, getLeaderboardEntries } from '../Schedule/api'
import InfiniteScroll from 'react-infinite-scroll-component'
import { generateDeeplink } from 'Lib/Helpers'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GoalBasedLeaderboardDetails from './GoalBasedLeaderboardDetails'
import RankBasedLeaderboardDetails from './RankBasedLeaderboardDetails'
import { navigate } from '@reach/router'
import {
  createLbFromTemplate,
  getStateTag,
  responseToDetails,
} from '../Schedule/helper'
import {
  Alert,
  Bold,
  Breadcrumbs,
  Button,
  Card,
  Select,
  Tag,
  Title,
} from 'salt'
import Edit from 'Images/Edit.svg'
import Refresh from 'Images/Refresh.svg'
import LeaderboardSummary from './LeaderboardSummary'
import { Loader } from 'Components/Loader'

const breadcrumbs = [
  {
    text: 'Leaderboards',
    onClick: () => {
      navigate('/schedule')
    },
  },
  { text: 'Leaderboard Details' },
]

const initialSelectedOption = { value: '', label: 'All goals' }

const LeaderboardDetails = ({ leaderboardId }) => {
  const [details, setDetails] = useState()
  const [leaderboardDetails, setLeaderboardDetails] = useState({})
  const [leaderboardEntries, setLeaderboardEntries] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const [allGoals, setAllGoals] = useState([])
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption)
  const [showSummary, setShowSummary] = useState(false)

  const fetchLeaderboardDetails = useCallback(async (lbId) => {
    setLoading(true)
    const { leaderboards: lb } = await getLeaderboardDetails(lbId)
    setDetails(responseToDetails(lb.leaderboard_detail))
    setLeaderboardDetails(lb.leaderboard_detail)
    setLeaderboardEntries(lb.leaderboard_entries || [])

    if (lb.leaderboard_detail?.type === 'GoalBased') {
      let allGoals = lb.leaderboard_detail.prize_distribution.goal_based.map(
        (goalPrizeDist) => {
          return {
            value: goalPrizeDist.goal,
            label: `Goal ${goalPrizeDist.goal}`,
          }
        }
      )

      if (lb.leaderboard_detail?.state !== 'ResultDeclared') {
        allGoals.push({ value: 'Completed', label: 'Completed' })
      }

      allGoals.push({ value: '', label: 'All goals' })

      setAllGoals(allGoals)
    }

    if (!!lb.leaderboard_entries) {
      setHasMore(lb.leaderboard_entries.length >= 30)
    }
    setLoading(false)
  }, [])

  const fetchLeaderboardEntries = async (lbId, refresh = false, goal) => {
    setLoading(true)
    let playerId = ''
    if (leaderboardEntries.length > 0) {
      playerId =
        leaderboardEntries[leaderboardEntries.length - 1].player.player_id
    }
    if (refresh) playerId = ''

    let goalFilter = 0
    let allGoalsCompleted = false

    if (!!goal && goal.value) {
      if (goal.value === 'Completed') {
        allGoalsCompleted = true
      } else {
        goalFilter = parseInt(goal.value, 10)
      }
    }

    const { leaderboards: lbE } = await getLeaderboardEntries(
      lbId,
      playerId,
      goalFilter,
      allGoalsCompleted
    )

    setSelectedOption(!!goal ? goal : initialSelectedOption)

    setHasMore(lbE.length >= 30)
    if (refresh) {
      setLeaderboardEntries(lbE)
    } else {
      setLeaderboardEntries([...leaderboardEntries, ...lbE])
    }

    setLoading(false)
  }

  useEffect(() => {
    fetchLeaderboardDetails(leaderboardId)
  }, [fetchLeaderboardDetails, leaderboardId])

  const copyDeeplink = (lbId) => {
    let deeplink = generateDeeplink(lbId)
    navigator.clipboard.writeText(deeplink)
    toast.info('Deeplink copied to clipboard!', {
      position: 'bottom-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }

  const getDetails = (lbType, leaderboardDetails, leaderboardEntries) => {
    switch (lbType) {
      case 'GoalBased':
        return (
          <GoalBasedLeaderboardDetails
            leaderboardDetails={leaderboardDetails}
            leaderboardEntries={leaderboardEntries}
          />
        )
      default:
        return (
          <RankBasedLeaderboardDetails
            leaderboardDetails={leaderboardDetails}
            leaderboardEntries={leaderboardEntries}
          />
        )
    }
  }

  const isEditable = (details) => {
    return (
      !!details &&
      details.lbState !== 'ResultDeclared' &&
      Math.ceil(leaderboardDetails?.total_players_count || 0) === 0
    )
  }

  const copyTemplate = () => {
    setLoading(true)
    createLbFromTemplate(leaderboardDetails.template_id).catch(() => {
      setLoading(false)
    })
  }

  return (
    <>
      {!loading ? (
        <>
          <ToastContainer />
          <div className={styles.container}>
            <Card customClasses={[styles.header]}>
              <div className={styles.heading}>
                {getStateTag(leaderboardDetails?.state)}
                <Title level={1}>
                  <Bold>{leaderboardDetails?.ext_name || ''}</Bold>
                </Title>
              </div>
              <div className={styles.sub}>
                <Breadcrumbs breadcrumbs={breadcrumbs} />
                <div className={styles.actions}>
                  {isEditable(details) && (
                    <Button
                      type="text"
                      onClick={() => {
                        navigate(`/leaderboard-details/${leaderboardId}/edit`, {
                          state: {
                            sD: details.spawningDetails,
                            audience: details.audience,
                            schedule: details.schedule,
                            lbState: leaderboardDetails.state,
                            lbId: leaderboardId,
                          },
                        })
                      }}
                    >
                      <Tag type={'primary'} icon={Edit} />
                    </Button>
                  )}
                  <Button
                    type={'text'}
                    onClick={() => {
                      copyDeeplink(leaderboardId)
                    }}
                  >
                    Copy Deeplink
                  </Button>
                  <Button type={'text'} onClick={() => setShowSummary(true)}>
                    <Bold>Leaderboard Summary</Bold>
                  </Button>
                </div>
              </div>
            </Card>
            <Card customClasses={[styles.activity]}>
              <div className={styles.heading}>
                <Title level={2}>
                  <Bold>Leaderboard</Bold> Activity
                </Title>
                <Button
                  type="text"
                  onClick={() => {
                    fetchLeaderboardEntries(leaderboardId, true)
                  }}
                >
                  <Tag type={'primary'} icon={Refresh} />
                </Button>
              </div>
              {details && allGoals.length !== 0 && (
                <>
                  <div className={styles.filters}>
                    <Select
                      label={'Goal'}
                      options={allGoals}
                      value={selectedOption}
                      onChange={(option) => {
                        fetchLeaderboardEntries(leaderboardId, true, option)
                      }}
                      disabled={!details}
                    />
                  </div>
                </>
              )}
              <InfiniteScroll
                dataLength={leaderboardEntries.length}
                next={() => {
                  fetchLeaderboardEntries(leaderboardId)
                }}
                hasMore={hasMore}
                loader={<div>Loading...</div>}
                endMessage={
                  leaderboardEntries.length !== 0 && (
                    <Alert type="info" customClasses={[styles.endMessage]}>
                      End of players
                    </Alert>
                  )
                }
              >
                {getDetails(
                  leaderboardDetails?.type,
                  leaderboardDetails,
                  leaderboardEntries
                )}
              </InfiniteScroll>
            </Card>
          </div>
        </>
      ) : (
        <Loader />
      )}
      {details && !loading && (
        <LeaderboardSummary
          spawningDetails={details.spawningDetails}
          audience={details.audience}
          copyTemplate={copyTemplate}
          show={showSummary}
          close={() => setShowSummary(false)}
        />
      )}
    </>
  )
}

export default LeaderboardDetails
