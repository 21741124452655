import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { navigate } from '@reach/router'
import moment from 'moment'
import { Bold, Button, Card, Table, Tag, Text, Title, Link } from 'salt'
import styles from './ListPublishedProperties.module.scss'
import HourGlassIcon from 'Images/HourGlass.svg'
import LiveIcon from 'Images/Live.svg'
import OrangeCheckIcon from 'Images/OrangeCheck.svg'
import { getStateTag } from '../helper'
import { fetchProperties } from '../api'
import { Loader } from '../../../Components/Loader'

const ListPublishedProperties = () => {
  const columns = useMemo(
    () => [
      {
        Header: '',
        accessor: 'state',
        maxWidth: 84,
      },
      {
        Header: 'External Name',
        accessor: 'externalName',
        filterable: true,
      },
      {
        Header: 'Leaderboards',
        accessor: 'leaderboardCount',
        maxWidth: 200,
      },
      {
        Header: 'Starting On',
        accessor: 'startTime',
        maxWidth: 225,
      },
      {
        Header: 'Ending On',
        accessor: 'endTime',
        maxWidth: 225,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 150,
      },
    ],
    []
  )
  const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState([])

  const getActions = useCallback((p) => {
    return [
      <Link
        path={`/properties/published/details/${p.id}`}
      >
        View
      </Link>,
    ]
  }, [])

  const getProperties = useCallback(async () => {
    setLoading(true)
    const { properties: prop } = await fetchProperties('Published')
    setLoading(false)

    let properties = prop.map((p) => {
      return {
        state: getStateTag(p.state),
        externalName: p.external_name,
        startTime: moment(p.schedule.start_time).format('ll'),
        endTime: moment(p.schedule.end_time).format('ll'),
        actions: <div className={styles.actions}>{getActions(p)}</div>,
        leaderboardCount: p.number_of_children,
      }
    })

    setProperties(properties)
  }, [getActions])

  useEffect(() => {
    getProperties()
  }, [getProperties])

  return (
    <>
      {!loading ? (
        <>
          <Card customClasses={[styles.header]}>
            <Title>
              Published <Bold>Properties</Bold>
            </Title>
            <Button onClick={() => navigate('/properties/create')}>
              <Bold>Create Property</Bold>
            </Button>
          </Card>
          <Card>
            <Title level={2}>
              <Bold>Properties</Bold> List
            </Title>
            <div className={styles.infoPanel}>
              <div className={styles.legend}>
                <Tag icon={HourGlassIcon} />
                <Text weight={'small'}>Yet to Start</Text>
                <Tag icon={LiveIcon} />
                <Text weight={'small'}>Live</Text>
                <Tag icon={OrangeCheckIcon} />
                <Text weight={'small'}>Expired</Text>
              </div>
            </div>
            <Table columns={columns} data={properties} />
          </Card>
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default ListPublishedProperties
