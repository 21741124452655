import { getStartOfWeek } from 'Containers/CreateLeaderboard/Scheduler/rule'

const formatTypeOp = [
  { label: 'Competitive', value: 'CashFormatDefault' },
  { label: 'Social', value: 'CashFormatUGC' },
]
const lbCategoryOp = [
  { label: 'Free', value: 'free' },
  { label: 'Paid', value: 'paid' },
]
const lbTypeOp = [
  { label: 'Rank Based', value: 'Rank' },
  { label: 'Goal Based', value: 'Goal' },
]
const lbSubTypeOp = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
]
const systemEvents = [
  { label: 'Net Winnings', value: 'GamePlayed' },
  { label: 'Card Scoring', value: 'PokerHand' },
  { label: 'Deposit', value: 'Deposit' },
]

const gameCategoryOp = [
  { label: 'Cards', value: 'cards' },
  { label: 'Casual', value: 'casual' },
]

const gameNameOp = [
  { label: 'Poker', value: 'poker', type: 'Cards' },
  { label: 'Rummy', value: 'rummy', type: 'Cards' },
  { label: 'Carrom', value: 'carrom', type: 'Casual' },
  { label: 'Go Pool', value: 'pool', type: 'Casual' },
]

const numberTypeOp = [
  { label: 'Value', value: 'value' },
  { label: 'Range', value: 'range' },
]

const rewardTypes = [
  { label: 'Instant', value: 'INSTANT' },
  { label: 'Staggered', value: 'STAGGERED' },
]

const instantRewardType = 'INSTANT'

const walletTypes = [
  { label: 'Bonus', value: 'BONUS' },
  { label: 'Deposit', value: 'DEPOSIT' },
]

const defaultWalletType = 'BONUS'

const prizeModel = {
  startRank: '',
  endRank: '',
  prizePerPlayer: '',
  minScore: '',
  displayValue: '',
  staggeredTemplateId: '',
  rewardType: instantRewardType,
  walletType: defaultWalletType,
}

const goalPrizeModel = {
  goal_name: '',
  winning_score: 0,
  winning_amount: 0,
  goal: 0,
  wallet_type: defaultWalletType,
}

const defaultAttrMapVal = {
  name: '',
  key: '',
  type: 'NUMBER',
  colors: ['#FFF0EC', '#FF6D4A'],
  asPer: true,
  between: false,
}

const comparators = ['<=', '>=', 'Between']
const comparatorsString = ['=', '!=']
const asPer = ['LeaderboardStart', 'WeekStart', 'LeaderboardEnd']

const netWinningsNetProfitParams = {
  type: 'NUMBER',
  colors: ['#e7fff2', '#01ce67'],
  asPer: true,
  asPerValues: asPer,
  between: false,
  hasGameWise: true,
}
const attributesMap = {
  'LifeTime Deposit': {
    name: 'LifeTime Deposit',
    key: 'ExtTxnSum',
    type: 'NUMBER',
    colors: ['#FFF0EC', '#FF6D4A'],
    asPer: true,
    asPerValues: asPer,
    between: false,
  },
  Signup: {
    name: 'Signup',
    key: 'Player.JoinedAt',
    type: 'TIME',
    colors: ['#FFFAE7', '#FFD012'],
    asPer: false,
    asPerValues: asPer,
    between: false,
  },
  'Net Winnings': {
    name: 'Net Winnings',
    key: 'NetWinnings',
    ...netWinningsNetProfitParams,
  },
  '1D Net Winnings': {
    name: '1D Winnings',
    key: '1DNetWinnings',
    ...netWinningsNetProfitParams,
    asPerValues: asPer.filter((a) => a !== 'LeaderboardEnd'),
  },
  '7D Net Winnings': {
    name: '7D Net Winnings',
    key: '7DNetWinnings',
    ...netWinningsNetProfitParams,
    asPerValues: asPer.filter((a) => a !== 'LeaderboardEnd'),
  },
  'Net Profit': {
    name: 'Net Profit',
    key: 'NetProfit',
    ...netWinningsNetProfitParams,
  },
  '1D Net Profit': {
    name: '1D Net Profit',
    key: '1DNetProfit',
    ...netWinningsNetProfitParams,
    asPerValues: asPer.filter((a) => a !== 'LeaderboardEnd'),
  },
  '7D Net Profit': {
    name: '7D Net Profit',
    key: '7DNetProfit',
    ...netWinningsNetProfitParams,
    asPerValues: asPer.filter((a) => a !== 'LeaderboardEnd'),
  },
  'Hands Played': {
    name: 'Hands Played',
    key: 'Hands',
    type: 'NUMBER',
    colors: ['#FFFAE7', '#FFD012'],
    asPer: true,
    asPerValues: asPer,
    between: false,
  },
  'Ftue Game': {
    name: 'Ftue Game',
    key: 'FtueGamePlayed',
    type: 'STRING',
    colors: ['#FFFAE7', '#FFD012'],
    asPer: false,
    asPerValues: asPer,
    between: false,
  },
  'Preferred Big Blind': {
    name: 'Preferred Big Blind',
    key: 'PreferredBigBlind',
    type: 'NUMBER',
    colors: ['#e7ffff', '#12e8ff'],
    asPer: false,
    asPerValues: asPer,
    between: true,
  },
  'Max Big Blind': {
    name: 'Max Big Blind',
    key: 'MaxBigBlind',
    type: 'NUMBER',
    colors: ['#e7f5ff', '#1285ff'],
    asPer: false,
    asPerValues: asPer,
    between: true,
  },
  'Max Net Winnings': {
    name: 'Max Net Winnings',
    key: 'MaxNetWinnings',
    type: 'NUMBER',
    colors: ['#e7ecff', '#122eff'],
    asPer: false,
    asPerValues: asPer,
    between: true,
  },
  'Avg Net Winnings': {
    name: 'Avg Net Winnings',
    key: 'AvgNetWinnings',
    type: 'NUMBER',
    colors: ['#FFFAE7', '#FFD012'],
    asPer: false,
    asPerValues: asPer,
    between: true,
  },
  'Preferred Point Rate (Rummy)': {
    name: 'Preferred Point Rate (Rummy)',
    key: 'PreferredPointRateRummy',
    type: 'NUMBER',
    colors: ['#ffe7f1', '#ff1474'],
    asPer: false,
    asPerValues: asPer,
    between: true,
  },
  'Max Point Rate (Rummy)': {
    name: 'Max Point Rate (Rummy)',
    key: 'MaxPointRateRummy',
    type: 'NUMBER',
    colors: ['#FFFAE7', '#FFD012'],
    asPer: false,
    asPerValues: asPer,
    between: true,
  },
  'Preferred Game': {
    name: 'Preferred Game',
    key: 'PreferredGame',
    type: 'STRING',
    colors: ['#FFFAE7', '#FFD012'],
    asPer: false,
    asPerValues: asPer,
    between: true,
  },
  'Preferred Point Rate (Carrom)': {
    name: 'Preferred Point Rate (Carrom)',
    key: 'PreferredPointRateCarrom',
    type: 'NUMBER',
    colors: ['#ffe7f1', '#ff1474'],
    asPer: false,
    asPerValues: asPer,
    between: true,
  },
  'Preferred Point Rate (GoPool)': {
    name: 'Preferred Point Rate (GoPool)',
    key: 'PreferredPointRateGoPool',
    type: 'NUMBER',
    colors: ['#ffe7f1', '#ff1474'],
    asPer: false,
    asPerValues: asPer,
    between: true,
  },
  'Acquisition Source': {
    name: 'Acquisition Source',
    key: 'AcquisitionSource',
    type: 'STRING',
    colors: ['#ffe7f1', '#ff1474'],
    asPer: false,
    asPerValues: asPer,
    between: false,
  },
  'Fraud Score': {
    name: 'Fraud Score',
    key: 'FraudScore',
    type: 'NUMBER',
    colors: ['#FFF0EC', '#FF6D4A'],
    asPer: false,
    between: false,
  },
}

const pokerHands = [
  {
    hand: 'High Card',
    value: 1,
  },
  {
    hand: 'One Pair',
    value: 2,
  },
  {
    hand: 'Two Pair',
    value: 3,
  },
  {
    hand: 'Three of a Kind',
    value: 4,
  },
  {
    hand: 'Straight',
    value: 5,
  },
  {
    hand: 'Flush',
    value: 6,
  },
  {
    hand: 'Full House',
    value: 7,
  },
  {
    hand: 'Four of a Kind',
    value: 8,
  },
  {
    hand: 'Straight Flush',
    value: 9,
  },
  {
    hand: 'Royal Flush',
    value: 10,
  },
]

const asPerToAsOf = (asPer, startTime, endTime) => {
  switch (asPer) {
    case 'LeaderboardStart':
      return startTime
    case 'WeekStart':
      return getStartOfWeek()
    case 'LeaderboardEnd':
      return endTime
    default:
      return new Date()
  }
}

const validateComparatorValues = (attr) => {
  switch (attr.comparator) {
    case '<=':
      attr.min = null
      break
    case '>=':
      attr.max = null
      break
    // case 'Between':
    default:
  }

  return attr
}

let hours = []
let minutes = []
for (let i = 0; i < 24; i++) {
  const ampm = i < 12 ? 'am' : 'pm'
  let h = i % 12
  if (h === 0) h = 12
  h = `${h.toString().padStart(2, '0')} ${ampm}`

  hours.push({ value: i, label: h })
}
for (let i = 0; i < 60; i++) {
  const m = i.toString().padStart(2, '0')
  minutes.push({ value: m, label: m })
}

// sub attributes - game/bb/pr/period specific
const ALL_GAMES = 'All Games'
const ATTRIBUTE_GAME_NAMES = [
  { name: 'Poker' },
  { name: 'Rummy' },
  { name: 'Carrom' },
  { name: 'GoPool' },
  { name: 'FruitKaat' },
  { name: 'DotDash' },
  { name: 'ABCRummy' },
  { name: 'Warship' },
  // { name: '123' },
  // { name: 'GK' },
  // { name: 'PicMe' },
]

const ALL_PERIOD = '-None-'
const ATTRIBUTE_PERIODS = ['D1', 'D7', 'D30']

const BB_PR_REGEX = /^\d+(\.\d+)?$/

// attribute key structure
// attributeName$gameName$bb_pr$period
// eg. NetWinnings$Poker$0.4$D7, NetWinnings$Poker, NetWinnings$D1
// gameName, bb_pr, period are optional

const generateAttributeKey = ({ key, gameName, 'bb/pr': bb_pr, period }) => {
  let finalKey = key
  if (gameName !== ALL_GAMES) finalKey = `${finalKey}$${gameName}`
  if (bb_pr !== '') finalKey = `${finalKey}$${bb_pr}`
  if (period !== ALL_PERIOD) finalKey = `${finalKey}$${period}`
  return finalKey
}

const getSubAttributes = (key) => {
  const subAttrs = key.split('$')

  const defaultSubAttrs = [ALL_GAMES, ALL_PERIOD]
  const [gameName, period] = [
    ATTRIBUTE_GAME_NAMES.map((g) => g.name),
    ATTRIBUTE_PERIODS,
  ].map((subA, i) => {
    let val = defaultSubAttrs[i]
    subA.forEach((s) => {
      if (subAttrs.includes(s)) val = s
    })
    return val
  })

  let bb_pr = ''
  // bb_pr is the only number in attribute key,
  // so we can check if key contains bb_pr
  subAttrs.forEach((sa) => {
    if (sa && BB_PR_REGEX.test(sa)) {
      bb_pr = sa
    }
  })

  return {
    attribute: subAttrs[0],
    gameName,
    'bb/pr': bb_pr,
    period,
  }
}

export {
  formatTypeOp,
  lbCategoryOp,
  lbTypeOp,
  lbSubTypeOp,
  systemEvents,
  gameCategoryOp,
  gameNameOp,
  numberTypeOp,
  defaultWalletType,
  walletTypes,
  rewardTypes,
  instantRewardType,
  prizeModel,
  comparators,
  asPer,
  pokerHands,
  asPerToAsOf,
  validateComparatorValues,
  attributesMap,
  defaultAttrMapVal,
  goalPrizeModel,
  hours,
  minutes,
  comparatorsString,
  ATTRIBUTE_GAME_NAMES,
  ALL_GAMES,
  ALL_PERIOD,
  ATTRIBUTE_PERIODS,
  BB_PR_REGEX,
  generateAttributeKey,
  getSubAttributes,
}
