import React from 'react'
import { Bold, Card, Drawer, Text, Title } from 'salt'
import styles from './PropertySummary.module.scss'
import { NamingDetailsPreview } from 'Containers/Properties/Create/Spawning/NamingDetails'
import { SelectAudiencePreview } from 'Containers/CreateLeaderboard/SelectAudience'
import { eligibilityToAudience } from 'Containers/Schedule/helper'
import { generateImageUrl } from '../helper'

const PropertySummary = ({ details, show, close }) => {
  let audience = eligibilityToAudience(details)
  return (
    <Drawer show={show} close={close} topOffset={80}>
      <div className={styles.heading}>
        <Title>
          <Bold>Property</Bold> Summary
        </Title>
      </div>
      <NamingDetailsPreview
        internalName={details.internal_name}
        externalName={details.external_name}
        description={details.description}
        isPartOfSummary
      />
      <Card removeRightMargin removeLeftMargin>
        <Title level={3}>
          <Bold>Banner</Bold> Details
        </Title>
        <div className={styles.namingDetails}>
          <div>
            <Text weight="small" customClasses={[styles.label]}>
              Large Image
            </Text>
            <img
              src={generateImageUrl(details.banners.large_image_url)}
              className={styles.summaryImage}
            />
          </div>
          <div>
            <Text weight="small" customClasses={[styles.label]}>
              Small Image
            </Text>
            <img
              src={generateImageUrl(details.banners.small_image_url)}
              className={styles.summaryImage}
            />
          </div>
        </div>
      </Card>
      <SelectAudiencePreview audience={audience} isPartOfSummary />
    </Drawer>
  )
}

export default PropertySummary
