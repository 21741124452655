import styles from './Schedule.module.scss'
import React from 'react'
import { Bold, Card, Title, DatePicker, Select } from 'salt'
import { hours, minutes } from 'Containers/CreateLeaderboard/constants'

const Schedule = ({ schedule, updateSchedule }) => {
  const {
    visibleTime,
    visibleTimeHour,
    visibleTimeMinute,
    startDate,
    startHour,
    startMinute,
    endTime,
    endHour,
    endMinute,
  } = schedule
  return (
    <>
      <Card>
        <Title level={3}>
          <Bold>VISIBLE TIME</Bold> - T1
        </Title>
        <div className={styles.lbDuration}>
          <DatePicker
            label={'Enter Date'}
            selected={visibleTime}
            dateFormat="MMMM d, yyyy"
            onChange={(date) => {
              updateSchedule('visibleTime')(date)
            }}
            value={visibleTime}
            minDate={new Date().setHours(0, 0, 0, 0)}
          />
          <Select
            label={'Hour'}
            options={hours}
            onChange={(option) => {
              updateSchedule('visibleTimeHour')(option)
            }}
            value={visibleTimeHour}
          />
          <Select
            label={'Minutes'}
            options={minutes}
            onChange={(option) => {
              updateSchedule('visibleTimeMinute')(option)
            }}
            value={visibleTimeMinute}
          />
        </div>
      </Card>
      <Card>
        <Title level={3}>
          <Bold>START TIME</Bold> - T2
        </Title>
        <div className={styles.lbDuration}>
          <DatePicker
            label={'Enter Date'}
            selected={startDate}
            dateFormat="MMMM d, yyyy"
            onChange={(date) => {
              updateSchedule('startDate')(date)
            }}
            value={startDate}
            minDate={new Date().setHours(0, 0, 0, 0)}
          />
          <Select
            label={'Hour'}
            options={hours}
            onChange={(option) => {
              updateSchedule('startHour')(option)
            }}
            value={startHour}
          />
          <Select
            label={'Minutes'}
            options={minutes}
            onChange={(option) => {
              updateSchedule('startMinute')(option)
            }}
            value={startMinute}
          />
        </div>
      </Card>
      <Card>
        <Title level={3}>
          <Bold>END TIME</Bold> - T3
        </Title>
        <div className={styles.lbDuration}>
          <DatePicker
            label={'Enter Date'}
            selected={endTime}
            dateFormat="MMMM d, yyyy"
            onChange={(date) => {
              updateSchedule('endTime')(date)
            }}
            value={endTime}
            minDate={new Date().setHours(0, 0, 0, 0)}
          />
          <Select
            label={'Hour'}
            options={hours}
            onChange={(option) => {
              updateSchedule('endHour')(option)
            }}
            value={endHour}
          />
          <Select
            label={'Minutes'}
            options={minutes}
            onChange={(option) => {
              updateSchedule('endMinute')(option)
            }}
            value={endMinute}
          />
        </div>
      </Card>
    </>
  )
}

export default Schedule
