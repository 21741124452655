import { InputPreview } from 'Components/InputPreview'
import styles from './EntryConditions.module.scss'
import {
  ALL_GAMES,
  ALL_PERIOD,
  asPerToAsOf,
  attributesMap,
  comparators,
  defaultAttrMapVal,
} from 'Containers/CreateLeaderboard/constants'
import { Bold, Divider, Title } from 'salt'
import React from 'react'
import moment from 'moment'
import { getUTCTime } from 'Containers/CreateLeaderboard/Scheduler/rule'

const getValue = (attrMapVal, aud) => {
  let text = `${aud.comparator} `

  if (attrMapVal.type === 'STRING') {
    text += aud.stringValue
  } else {
    text += aud.min || aud.max || '0'

    if (aud.comparator === comparators[comparators.length - 1]) {
      text += ` and ${aud.max}`
    }
  }

  return text
}

const getAsPer = (aud, schedule) => {
  if (!!schedule) {
    return asPerToAsOf(
      aud.asPer,
      moment(
        getUTCTime(schedule.startDate, schedule.startHour, schedule.startMinute)
      )
        .format('LLLL')
        .toString()
    )
  } else {
    return aud.asOf.toString()
  }
}

const EntryConditionsPreview = ({ aud, i, schedule }) => {
  const attrMapVal = attributesMap[aud.attribute] || defaultAttrMapVal

  return (
    <div>
      {i !== 0 && <Divider placement={'left'}>And also</Divider>}
      <div className={styles.conditionHeader}>
        <Title level={3}>
          <Bold>Audience</Bold> Condition - {i + 1}
        </Title>
      </div>
      <div className={styles.attributesPreview}>
        <div>
          <InputPreview label={'Attribute'} value={aud.attribute} />
          {attributesMap[aud.attribute]?.hasGameWise &&
          aud.gameName !== ALL_GAMES ? (
            <>
              <br />
              <InputPreview label={'Game Name'} value={aud.gameName} />
            </>
          ) : null}
          {attributesMap[aud.attribute]?.hasGameWise &&
          aud.gameName !== ALL_GAMES &&
          aud['bb/pr'] !== '' ? (
            <>
              <br />
              <InputPreview label={'BB/PR'} value={aud['bb/pr']} />
            </>
          ) : null}
        </div>
        <InputPreview label={'With Value'} value={getValue(attrMapVal, aud)} />
        {attrMapVal.between && aud?.during?.length > 1 && (
          <InputPreview
            label={'Between'}
            value={`${aud.during[0]} - ${aud.during[1]}`}
          />
        )}
        {attrMapVal.asPer && !!aud.asOf && (
          <InputPreview label={'As Per'} value={getAsPer(aud, schedule)} />
        )}
      </div>
    </div>
  )
}

export default EntryConditionsPreview
