import React, { useEffect, useState } from 'react'
import styles from './PrizeDetails.module.scss'
import {
  goalPrizeModel,
  instantRewardType,
  prizeModel,
  rewardTypes,
  walletTypes,
} from '../../constants'
import { Bold, Button, Card, Divider, Input, Select, Text, Title } from 'salt'
import TrashCan from '../../../../Images/TrashCan.svg'
import { getStaggeredTemplates } from '../../../Schedule/api'

const PrizeDetails = ({
  updateSD,
  totalPrize,
  prizesForRankBasedLBs,
  prizesForGoalBasedLBs,
  lbCategory,
  percentOfWinningPlayers,
  rakePercentage,
  lbType,
  hasNonCashPrizes,
}) => {
  const calculateBalance = (totalPrize, prizes, goalBasedPrizes, lbType) => {
    let allotted = 0
    if (lbType.label === 'Rank Based') {
      allotted = prizes?.reduce((prevValue, p) => {
        if (p.endRank) {
          return (
            prevValue + (p.endRank - p.startRank + 1) * (p.prizePerPlayer || 0)
          )
        } else {
          return prevValue + 1 * (p.prizePerPlayer || 0)
        }
      }, 0)
    }

    if (lbType.label === 'Goal Based') {
      allotted = goalBasedPrizes.reduce((prevValue, p) => {
        return prevValue + 1 * (p.winning_amount || 0)
      }, 0)
    }

    return totalPrize - allotted
  }
  const balance = calculateBalance(
    totalPrize,
    prizesForRankBasedLBs,
    prizesForGoalBasedLBs,
    lbType
  )

  const [staggeredTemplates, setStaggeredTemplates] = useState([])

  useEffect(() => {
    getStaggeredTemplates().then((res) => {
      setStaggeredTemplates(res.templates)
    })
  }, [])

  const getPrizeSelector = (lbType) => {
    if (lbType.label === 'Rank Based') {
      return (
        <PrizeSelectorForRankBasedLBs
          totalPrize={totalPrize}
          prizes={prizesForRankBasedLBs}
          setPrizes={updateSD('prizes')}
          balance={balance}
          hasNonCashPrizes={hasNonCashPrizes}
          staggeredTemplates={staggeredTemplates}
        />
      )
    }
    if (lbType.label === 'Goal Based') {
      return (
        <PrizeSelectorForGoalBasedLBs
          totalPrize={totalPrize}
          prizes={prizesForGoalBasedLBs}
          setPrizes={updateSD('goalBasedPrizes')}
          balance={balance}
        />
      )
    }
  }

  return (
    <Card>
      <Title level={3}>
        <Bold>Prize</Bold> Details
      </Title>
      <div className={styles.prizeDetails}>
        {lbCategory.label === 'Free' && (
          <>
            <Input
              label="Total Prize"
              placeholder="eg: 1000, 20000"
              value={totalPrize}
              onChange={updateSD('totalPrize')}
              type="number"
              min={0}
            />
            {!!balance && (
              <div className={styles.totalBalance}>
                <Text weight={'small'}>Total Prize Balance</Text>
                <Text>
                  <Bold>₹ {balance}</Bold>
                </Text>
              </div>
            )}
          </>
        )}

        {lbCategory.label === 'Paid' && (
          <>
            <Input
              label="Rake Percentage"
              placeholder="eg: 20"
              value={rakePercentage}
              onChange={updateSD('rakePercentage')}
              type="number"
              min={0}
            />
            <Input
              label="Percentage of Winning Players"
              placeholder="eg: 50"
              value={percentOfWinningPlayers}
              onChange={updateSD('percentOfWinningPlayers')}
              type="number"
              min={0}
            />
          </>
        )}
      </div>
      {lbCategory.label === 'Free' && getPrizeSelector(lbType)}
    </Card>
  )
}

const shouldDisableButton = (lbType, prizes) => {
  const lastEnteredPrize = prizes[prizes.length - 1]

  if (!lastEnteredPrize) return true

  if (lbType === 'Rank Based') {
    return !(
      !!lastEnteredPrize.startRank &&
      !!lastEnteredPrize.endRank &&
      !!lastEnteredPrize.prizePerPlayer &&
      !!lastEnteredPrize.minScore &&
      !!lastEnteredPrize.walletType
    )
  }

  if (lbType === 'Goal Based') {
    return !(
      !!lastEnteredPrize.goal_name &&
      !!lastEnteredPrize.winning_score &&
      !!lastEnteredPrize.winning_amount &&
      !!lastEnteredPrize.goal &&
      !!lastEnteredPrize.wallet_type
    )
  }
}

const PrizeSelectorForRankBasedLBs = ({
  totalPrize,
  prizes,
  setPrizes,
  balance,
  hasNonCashPrizes,
  staggeredTemplates,
}) => {
  if (!totalPrize) return null

  const changePrize = (i, key) => (value) => {
    let tempPrizes = [...prizes]
    tempPrizes[i] = {
      ...tempPrizes[i],
      [key]: value,
    }
    setPrizes(tempPrizes)
  }

  const changeRewardType =
    (i, key) =>
    ({ value }) => {
      changePrize(i, key)(value)
    }

  const changeStaggeredReward =
    (i, key) =>
    ({ value }) => {
      let staggeredTemplate = staggeredTemplates.filter(
        (t) => t.template_id === value
      )
      let prizePerPlayer =
        staggeredTemplate.length && staggeredTemplate[0].total_reward

      let tempPrizes = [...prizes]
      tempPrizes[i] = {
        ...tempPrizes[i],
        [key]: value,
        prizePerPlayer: prizePerPlayer,
      }
      setPrizes(tempPrizes)
    }

  const removePrize = (i) => () => {
    let tempPrizes = [...prizes]
    tempPrizes.splice(i, 1)
    setPrizes(tempPrizes)
  }

  const addPrize = () => {
    let tempPrizes = [...prizes]
    if (prizes.length === 0) {
      tempPrizes.push(prizeModel)
    } else {
      const lP = prizes[prizes.length - 1]
      const sR = lP.endRank ? +lP.endRank + 1 : +lP.startRank + 1
      tempPrizes.push({
        ...prizeModel,
        startRank: sR,
      })
    }

    setPrizes(tempPrizes)
  }

  const renderPrizes = () => {
    if (prizes.length === 0) addPrize()

    return (
      <>
        {prizes.map((p, i) => (
          <div
            className={
              p.rewardType === instantRewardType
                ? styles.rankRow
                : styles.rankRowWithStaggered
            }
            key={i}
          >
            <Input
              label={'Start Rank'}
              placeholder={'Enter start rank'}
              value={p.startRank}
              onChange={changePrize(i, 'startRank')}
              type="number"
              min={1}
            />
            <Input
              label={'End Rank'}
              placeholder={'Enter end rank'}
              value={p.endRank}
              onChange={changePrize(i, 'endRank')}
              type="number"
              min={1}
            />
            <Input
              label={'Minimum Score'}
              placeholder={'Enter minimum score'}
              value={p.minScore}
              onChange={changePrize(i, 'minScore')}
              type="number"
              min={0}
            />
            <Select
              label="Reward Type"
              options={rewardTypes}
              onChange={changeRewardType(i, 'rewardType')}
              value={rewardTypes.find((w) => w.value === p.rewardType)}
            />
            {p.rewardType === instantRewardType ? (
              <>
                {!hasNonCashPrizes && (
                  <Select
                    label="Reward Destination"
                    options={walletTypes}
                    onChange={changeRewardType(i, 'walletType')}
                    value={walletTypes.find((w) => w.value === p.walletType)}
                  />
                )}
                {hasNonCashPrizes && (
                  <Input
                    label={'Display Value'}
                    placeholder={'Enter display value of prize'}
                    value={p.displayValue}
                    onChange={changePrize(i, 'displayValue')}
                    type="text"
                  />
                )}
              </>
            ) : (
              <Select
                label="Staggered Reward Offer"
                options={staggeredTemplates.map((s) => ({
                  value: s.template_id,
                  label: s.template_id,
                }))}
                onChange={changeStaggeredReward(i, 'staggeredTemplateId')}
                value={{
                  value: p.staggeredTemplateId,
                  label: p.staggeredTemplateId,
                }}
              />
            )}
            <Input
              label={'Invoice Value'}
              placeholder={'Enter prize per player'}
              value={p.prizePerPlayer}
              onChange={changePrize(i, 'prizePerPlayer')}
              type="number"
              max={totalPrize}
              disabled={p.rewardType !== instantRewardType}
            />
            {i !== 0 && (
              <img
                className={styles.delete}
                src={TrashCan}
                onClick={removePrize(i)}
                alt="delete"
              />
            )}
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <Divider placement={'left'}>For Rank Based Leaderboard</Divider>
      <div className={styles.prizeValues}>{renderPrizes()}</div>
      <div className={styles.footer}>
        {!!balance && (
          <Text weight={'paragraph'}>
            Use remaining balance of <Bold>₹ {balance}</Bold> for distribution
          </Text>
        )}
        <Button
          onClick={addPrize}
          type={'text'}
          disabled={shouldDisableButton('Rank Based', prizes)}
        >
          + Add Rank
        </Button>
      </div>
    </>
  )
}

const PrizeSelectorForGoalBasedLBs = ({
  totalPrize,
  prizes,
  setPrizes,
  balance,
}) => {
  if (!totalPrize) return null

  const changeGoalPrize = (i, key) => (value) => {
    let tempPrizes = [...prizes]
    tempPrizes[i] = {
      ...tempPrizes[i],
      [key]: value,
    }
    setPrizes(tempPrizes)
  }

  const changeWalletType =
    (i, key) =>
    ({ value }) => {
      changeGoalPrize(i, key)(value)
    }

  const removeGoalPrize = (i) => () => {
    let tempPrizes = [...prizes]
    tempPrizes.splice(i, 1)
    for (let index = 0; index < tempPrizes.length; index++) {
      const prize = tempPrizes[index]
      tempPrizes.splice(index, 1, {
        ...prize,
        goal: index + 1,
        goal_name: `Goal ${index + 1}`,
      })
    }
    setPrizes(tempPrizes)
  }

  const addGoalPrize = () => {
    let tempPrizes = [...prizes]
    if (prizes.length === 0) {
      tempPrizes.push({
        ...goalPrizeModel,
        goal_name: 'Goal 1',
        goal: 1,
      })
    } else {
      const lP = prizes[prizes.length - 1].goal
      tempPrizes.push({
        ...goalPrizeModel,
        goal: lP + 1,
        goal_name: `Goal ${lP + 1}`,
      })
    }

    setPrizes(tempPrizes)
  }

  const renderPrizes = () => {
    if (prizes.length === 0) addGoalPrize()

    return (
      <>
        {prizes.map((p, i) => (
          <div className={styles.goalRow} key={i}>
            <Text weight={'paragraph'} customClasses={[styles.goalName]}>
              <Bold>{p.goal_name}</Bold>
            </Text>
            <Input
              label={'Winning Score'}
              placeholder={'Enter winning score'}
              value={p.winning_score}
              onChange={changeGoalPrize(i, 'winning_score')}
              type="number"
              min={0}
            />
            <Select
              label="Reward Destination"
              options={walletTypes}
              onChange={changeWalletType(i, 'wallet_type')}
              value={walletTypes.find((w) => w.value === p.wallet_type)}
            />
            <Input
              label={'Winning Amount'}
              placeholder={'Enter winning amount'}
              value={p.winning_amount}
              onChange={changeGoalPrize(i, 'winning_amount')}
              type="number"
              max={totalPrize}
              min={0}
            />
            {i !== 0 && (
              <>
                <img
                  className={styles.delete}
                  src={TrashCan}
                  onClick={removeGoalPrize(i)}
                  alt="delete"
                />
              </>
            )}
          </div>
        ))}
      </>
    )
  }

  return (
    <>
      <Divider placement={'left'}>For Goal Based Leaderboard</Divider>
      <div className={styles.prizeValues}>{renderPrizes()}</div>
      <div className={styles.footer}>
        {!!balance && (
          <Text weight={'paragraph'}>
            Use remaining balance of <Bold>₹ {balance}</Bold> for distribution
          </Text>
        )}
        <Button
          onClick={addGoalPrize}
          type={'text'}
          disabled={shouldDisableButton('Goal Based', prizes)}
        >
          + Add Goal
        </Button>
      </div>
    </>
  )
}

export default PrizeDetails
