import React from 'react'
import { Bold, Card, Title } from 'salt'
import styles from './TournamentDesign.module.scss'
import { InputPreview } from '../../../../Components/InputPreview'

const TournamentDesignPreview = ({
  gameName,
  bbMaxValue,
  bbMinValue,
  isPartOfSummary,
}) => {
  return (
    <Card
      removeLeftMargin={isPartOfSummary}
      removeRightMargin={isPartOfSummary}
    >
      <Title level={3}>
        <Bold>Tournament</Bold> Design
      </Title>
      <div className={styles.tournamentDesignPreview}>
        <InputPreview
          label={
            !!gameName && gameName === 'Poker' ? 'Big Blind' : 'Point Rate'
          }
          value={
            !!bbMaxValue ? `${bbMinValue || 0} - ${bbMaxValue}` : bbMinValue
          }
        />
      </div>
    </Card>
  )
}

export default TournamentDesignPreview
