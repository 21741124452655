import React from 'react'
import styles from './PhoneModal.module.scss'
import PhoneIcon from '../../Images/PhoneBlue.svg'
import { Bold, Modal, Tag, Title } from 'salt'

const PhoneModal = ({ close, currentPlayer }) => {
  return (
    <Modal title={currentPlayer.name || ''} close={close}>
      <div className={styles.phoneModal}>
        <Tag icon={PhoneIcon} customClasses={[styles.phoneIcon]} />
        <Title level={3}>
          <Bold type={'medium'}>{currentPlayer.phone_number || ''}</Bold>
        </Title>
      </div>
    </Modal>
  )
}

export default PhoneModal
