import styles from './TermsAndConditions.module.scss'
import TrashCan from '../../../../Images/TrashCan.svg'
import React from 'react'
import { Bold, Button, Card, Input, Title } from 'salt'

const TermsAndConditions = ({ updateSD, terms }) => {
  return (
    <Card>
      <Title level={3}>
        <Bold>Terms &</Bold> Conditions
      </Title>
      <div className={styles.terms}>
        {terms.map((term, i) => {
          return (
            <div className={styles.term} key={i}>
              <Input
                placeholder="eg: No officials / employees / interns of GetMega are eligible for this contest"
                onChange={(value) => {
                  const t = [
                    ...terms.slice(0, i),
                    value.trimStart(),
                    ...terms.slice(i + 1),
                  ]
                  updateSD('terms')(t)
                }}
                value={term}
              />
              {i !== 0 && (
                <img
                  className={styles.delete}
                  src={TrashCan}
                  onClick={() => {
                    let tempTerms = [...terms]
                    tempTerms.splice(i, 1)
                    updateSD('terms')(tempTerms)
                  }}
                  alt="delete"
                />
              )}
            </div>
          )
        })}
      </div>
      <Button
        onClick={() => {
          const t = [...terms, '']
          updateSD('terms')(t)
        }}
        type={'text'}
        disabled={terms[terms.length - 1] === ''}
      >
        + Add Term or Condition
      </Button>
    </Card>
  )
}

export default TermsAndConditions
