import React from 'react'
import { useDispatch } from 'react-redux'
import AuthActions from 'Redux/AuthRedux'
import { Button } from 'salt'

const LoginScreen = () => {
  const dispatch = useDispatch()
  let noAccess = false

  if (window.location.href.includes('/?noaccess=true')) {
    noAccess = true
  }
  const signIn = () => {
    dispatch(AuthActions.loginRequest())
  }

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        width: '100vw',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          position: 'relative',
          bottom: 140,
          fontSize: 44,
          fontWeight: 'bold',
        }}
      >
        Moonshot Admin
      </div>
      {noAccess ? (
        <div
          style={{
            color: 'red',
            position: 'relative',
            bottom: 16,
            width: 450,
            textAlign: 'center',
          }}
        >{`You do not have access to Moonshot Admin. Please contact your manager`}</div>
      ) : null}
      <Button onClick={signIn} type={'secondary'}>
        Sign In with Google
      </Button>
    </div>
  )
}

export default LoginScreen
