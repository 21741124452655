export const validatePrizeDistribution = (
  totalPrize,
  rankBasedPrizes,
  goalBasedPrizes,
  hasNonCashPrizes
) => {
  let validationPassed = true
  let validationErr = ''

  if (!totalPrize) {
    validationPassed = false
    validationErr = 'Please fill Total Prize'
    return returnPayload(validationPassed, validationErr)
  } else {
    let allotted = 0
    let hasMinOneNonCashPrize = false
    if (rankBasedPrizes.length > 0) {
      for (let i = 0; i < rankBasedPrizes.length; i++) {
        const p = rankBasedPrizes[i]
        if (1 * (p.prizePerPlayer || 0) <= 0) {
          validationPassed = false
          validationErr = 'Rank Prize cannot be zero'
          return returnPayload(validationPassed, validationErr)
        }

        if (p.startRank <= 0) {
          validationPassed = false
          validationErr = 'Rank cannot be zero'
          return returnPayload(validationPassed, validationErr)
        }

        if (p.minScore <= 0) {
          validationPassed = false
          validationErr = 'Min score cannot be zero'
          return returnPayload(validationPassed, validationErr)
        }

        if (!!p.displayValue) {
          hasMinOneNonCashPrize = true
        }
      }

      if (hasNonCashPrizes && !hasMinOneNonCashPrize) {
        validationPassed = false
        validationErr = 'At least one non cash prize expected'
        return returnPayload(validationPassed, validationErr)
      }

      allotted = rankBasedPrizes.reduce((prevValue, p) => {
        if (p.endRank) {
          return (
            prevValue + (p.endRank - p.startRank + 1) * (p.prizePerPlayer || 0)
          )
        } else {
          return prevValue + 1 * (p.prizePerPlayer || 0)
        }
      }, 0)
    } else if (goalBasedPrizes.length > 0) {
      for (let i = 0; i < goalBasedPrizes.length; i++) {
        const g = goalBasedPrizes[i]
        if (1 * (g.winning_amount || 0) <= 0) {
          validationPassed = false
          validationErr = 'Goal Prize cannot be zero'
          return returnPayload(validationPassed, validationErr)
        }
      }

      allotted = goalBasedPrizes.reduce((prevValue, p) => {
        return prevValue + 1 * (p.winning_amount || 0)
      }, 0)
    }

    const balance = totalPrize - allotted

    if (balance < 0) {
      validationPassed = false
      validationErr = 'Reduce prize allotment as budget is lower'
      return returnPayload(validationPassed, validationErr)
    }

    if (balance > 0) {
      validationPassed = false
      validationErr =
        'Balance remaining; please reduce total prize or allot more prizes'
      return returnPayload(validationPassed, validationErr)
    }
  }

  return returnPayload(validationPassed, validationErr)
}

const returnPayload = (validationPassed, validationErr) => {
  return {
    passed: validationPassed,
    error: validationErr,
  }
}
