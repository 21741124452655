import { isEmpty } from 'ramda'
import { getUTCTime } from 'Containers/CreateLeaderboard/Scheduler/rule'
import moment from 'moment'

export const validateSchedule = (schedule) => {
  let validationPassed = true
  let validationErr = ''

  const {
    visibleTime,
    visibleTimeHour,
    visibleTimeMinute,
    startDate,
    startHour,
    startMinute,
    endTime,
    endHour,
    endMinute,
  } = schedule

  if (!isEmpty(schedule)) {
    if (!visibleTime || !visibleTimeHour || !visibleTimeMinute) {
      validationPassed = false
      validationErr = 'Please enter valid Visible Time'
      return returnPayload(validationPassed, validationErr)
    }

    if (!startDate || !startHour || !startMinute) {
      validationPassed = false
      validationErr = 'Please enter valid Start Time'
      return returnPayload(validationPassed, validationErr)
    }

    if (!endTime || !endHour || !endMinute) {
      validationPassed = false
      validationErr = 'Please enter valid End Time'
      return returnPayload(validationPassed, validationErr)
    }

    let visible = moment(
      getUTCTime(visibleTime, visibleTimeHour, visibleTimeMinute)
    )
    let start = moment(getUTCTime(startDate, startHour, startMinute))
    let end = moment(getUTCTime(endTime, endHour, endMinute))

    if (visible.isAfter(start) || visible.isAfter(end)) {
      validationPassed = false
      validationErr = 'Visble time should be less than start and end time'
      return returnPayload(validationPassed, validationErr)
    }

    if (start.isAfter(end)) {
      validationPassed = false
      validationErr = 'Start time should be less than end time'
      return returnPayload(validationPassed, validationErr)
    }
  }

  return returnPayload(validationPassed, validationErr)
}

const returnPayload = (validationPassed, validationErr) => {
  return {
    passed: validationPassed,
    error: validationErr,
  }
}
