import moment from 'moment'
import {
  attributesMap,
  BB_PR_REGEX,
} from '../Containers/CreateLeaderboard/constants'

export const validateAudience = (audience, playerIds) => {
  let validationPassed = true
  let validationErr = ''

  if (!(audience && audience.length) && !(playerIds && playerIds.length)) {
    validationPassed = false
    validationErr = `Please enter Entry Conditions or upload CSV of Player IDs`
    return returnPayload(validationPassed, validationErr)
  }

  if (audience && audience.length > 0) {
    for (let i = 0; i < audience.length; i++) {
      const a = audience[i]
      if (!a.attribute) {
        validationPassed = false
        validationErr = `Condition ${i + 1} - attribute is required.`
        return returnPayload(validationPassed, validationErr)
      }
      if (a['bb/pr'] && (!BB_PR_REGEX.test(a['bb/pr']) || a['bb/pr'] <= 0)) {
        validationPassed = false
        validationErr = `Condition ${i + 1} - Invalid BB/PR`
        return returnPayload(validationPassed, validationErr)
      }
      if (!a.comparator) {
        validationPassed = false
        validationErr = `Condition ${i + 1} - comparator is required.`
        return returnPayload(validationPassed, validationErr)
      }

      if (a.comparator === '>=') {
        if (!a.min) {
          validationPassed = false
          validationErr = `Condition ${i + 1} - min value is required`
          return returnPayload(validationPassed, validationErr)
        }
      }

      if (a.comparator === '<=') {
        if (!a.max) {
          validationPassed = false
          validationErr = `Condition ${i + 1} - max value is required`
          return returnPayload(validationPassed, validationErr)
        }
      }

      if (a.comparator === 'Between') {
        if (!a.min) {
          validationPassed = false
          validationErr = `Condition ${i + 1} - min value is required`
          return returnPayload(validationPassed, validationErr)
        }

        if (!a.max) {
          validationPassed = false
          validationErr = `Condition ${i + 1} - max value is required`
          return returnPayload(validationPassed, validationErr)
        }

        if (a.attribute === 'Signup') {
          if (moment(a.min).isAfter(a.max)) {
            validationPassed = false
            validationErr = `Condition ${i + 1} - Attribute ${
              a.attribute
            } has min date after max date`
            return returnPayload(validationPassed, validationErr)
          }
        } else {
          if (parseFloat(a.min) > (parseFloat(a.max) || 0)) {
            validationPassed = false
            validationErr = `Condition ${i + 1} - Attribute ${
              a.attribute
            } has min value greater than max value`
            return returnPayload(validationPassed, validationErr)
          }
        }
      }

      // check on during param of attribute
      if (
        a.attribute &&
        attributesMap[a.attribute] &&
        attributesMap[a.attribute].between
      ) {
        let minDate = a.during[0]
        let maxDate = a.during[1]
        if (moment(minDate).isSameOrAfter(maxDate)) {
          validationPassed = false
          validationErr = `Attribute ${a.attribute} has min date equal or after max date`
          return returnPayload(validationPassed, validationErr)
        }
      }
    }
  }

  return returnPayload(validationPassed, validationErr)
}

const returnPayload = (validationPassed, validationErr) => {
  return {
    passed: validationPassed,
    error: validationErr,
  }
}

const hasDuplicates = (array) => {
  return new Set(array).size !== array.length
}
