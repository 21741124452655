import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
// import { path } from 'ramda'
// import store from 'store'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  updateSpawningDetails: ['key', 'value'],
  setSpawningDetails: ['details'],
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */
const initialData = {
  spawningDetails: {
    internalName: '',
    externalName: '',
    description: '',
    formatType: '',
    lbCategory: '',
    lbType: '',
    // lbSubType: '',
    systemEvent: '',
    gameCategory: '',
    gameName: '',
    bbType: '',
    bbMinValue: '',
    bbMaxValue: '',
    totalPrize: '',
    min_score_prize: 0,
    min_score_qualification: 0,
    prizes: [],
    goalBasedPrizes: [],
    scoringSystem: [],
    rules: [''],
    terms: [''],
    entryFee: 0,
    discount: 0,
    slots: 0,
    discountSlots: 0,
    percentOfWinningPlayers: 50,
    leaderboardBannerImage: undefined,
    rakePercentage: 20,
    seedPlayers: 0,
    benefitingAncestorGenerations: 0,
    degreeMultiplier: [],
    isReferral: false,
    depositorPercentage: 10,
    optIn: false,
    lateRegistrationDurationMin: 0,
    lateRegistrationDurationHour: 0,
    lateRegistrationAllowed: false,
    hasNonCashPrizes: false,
    leaderboardBanners: [],
  },
}

// const resetState = {
//   ...initialData,
// }

export const INITIAL_STATE = Immutable({
  ...initialData,
})

/* ------------- Reducers ------------- */

// request the data for a auth
export const updateSpawningDetails = (state, { key, value }) =>
  state.merge({
    spawningDetails: state.spawningDetails.setIn([key], value),
  })
export const setSpawningDetails = (state, { details }) =>
  state.merge({
    spawningDetails: { ...state.spawningDetails, ...details },
  })

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.UPDATE_SPAWNING_DETAILS]: updateSpawningDetails,
  [Types.SET_SPAWNING_DETAILS]: setSpawningDetails,
})
