import React from 'react'
import { Bold, Card, Title } from 'salt'
import styles from './Schedule.module.scss'
import { InputPreview } from 'Components/InputPreview'
import moment from 'moment'

const SchedulePreview = ({ schedule }) => {
  const {
    visibleTime,
    visibleTimeHour,
    visibleTimeMinute,
    startDate,
    startHour,
    startMinute,
    endTime,
    endHour,
    endMinute,
  } = schedule
  console.log(schedule)

  return (
    <>
      <Card>
        <Title level={3}>
          <Bold>VISIBLE TIME</Bold> - T1
        </Title>
        <div className={styles.lbDurationPreview}>
          <InputPreview
            label={'Date'}
            value={moment(visibleTime).format('ll')}
          />
          <InputPreview
            label={'Time'}
            value={`${visibleTimeHour.label.split(' ')[0]}:${
              visibleTimeMinute.value
            } ${visibleTimeHour.label.split(' ')[1].toUpperCase()}`}
          />
        </div>
      </Card>
      <Card>
        <Title level={3}>
          <Bold>START TIME</Bold> - T2
        </Title>
        <div className={styles.lbDurationPreview}>
          <InputPreview label={'Date'} value={moment(startDate).format('ll')} />
          <InputPreview
            label={'Time'}
            value={`${startHour.label.split(' ')[0]}:${
              startMinute.value
            } ${startHour.label.split(' ')[1].toUpperCase()}`}
          />
        </div>
      </Card>
      <Card>
        <Title level={3}>
          <Bold>END TIME</Bold> - T3
        </Title>
        <div className={styles.lbDurationPreview}>
          <InputPreview label={'Date'} value={moment(endTime).format('ll')} />
          <InputPreview
            label={'Time'}
            value={`${endHour.label.split(' ')[0]}:${
              endMinute.value
            } ${endHour.label.split(' ')[1].toUpperCase()}`}
          />
        </div>
      </Card>
    </>
  )
}

export default SchedulePreview
