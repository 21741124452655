import { all, fork } from 'redux-saga/effects'
import API from 'Services/Api'
import authSagas from './AuthSagas'

/* ------------- API ------------- */

const api = API.create()

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    fork(authSagas, api),
  ])
}
