import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { navigate } from '@reach/router'
import moment from 'moment'
import { Bold, Button, Card, Footer, Table, Text, Title, Link } from 'salt'
import styles from './PropertyDetails.module.scss'
import { InputPreview } from 'Components/InputPreview'
import {
  deleteTemplate,
  fetchPropertyChildren,
  fetchPropertyDetails,
  publishProperty,
} from '../api'
import PropertySummary from './PropertySummary'
import { eligibilityToAudience } from 'Containers/Properties/helper'
import { isEmpty } from 'ramda'
import { Loader } from 'Components/Loader'

const PropertyDetails = (props) => {
  const columns = useMemo(
    () => [
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'External Name',
        accessor: 'externalName',
      },
      {
        Header: 'Game',
        accessor: 'game',
      },
      {
        Header: 'Starting On',
        accessor: 'startTime',
      },
      {
        Header: 'Ending On',
        accessor: 'endTime',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
      },
    ],
    []
  )
  const [loading, setLoading] = useState(true)
  const [leaderboards, setLeaderboards] = useState([])
  const [details, setDetails] = useState({})
  const [showSummary, setShowSummary] = useState(false)
  const [message, setMessage] = useState({ type: 'success', value: '' })
  const [publishing, setPublishing] = useState(false)
  const [published, setPublished] = useState(false)

  const getActions = useCallback((c, details) => {
    const actions = []
    if (!!details && details.state === 'Draft') {
      actions.push(
        <Button
          type={'text'}
          onClick={async () => {
            await deleteTemplate(c.id)
            window.location.reload()
          }}
        >
          Delete
        </Button>
      )
    } else {
      const renderMainAction = (text) => (
        <Link
          path={`/leaderboard-details/${c.id}`}
        >
          {text}
        </Link>
      )

      switch (c.state) {
        case 'ResultDeclared':
          actions.push(renderMainAction('View History'))
          break
        case 'Cancelled':
          break
        case 'Live':
          actions.push(renderMainAction('View Activity'))
          break
        default:
          actions.push(renderMainAction('See Details'))
          break
      }
    }

    return actions
  }, [])

  useEffect(() => {
    let fetchDetails = async () => {
      let promises = [
        fetchPropertyDetails(props.propertyId),
        fetchPropertyChildren(props.propertyId),
      ]
      let resp = await Promise.all(promises)

      let { data } = resp[0]
      let { childrens } = resp[1]
      setDetails(data)
      setLeaderboards(
        childrens.map((c) => {
          return {
            type: c.leaderboard_category,
            game: c.game_name,
            externalName: c.external_name,
            startTime: moment(c.start_time).format('lll'),
            endTime: moment(c.end_time).format('lll'),
            actions: (
              <div className={styles.actions}>{getActions(c, data)}</div>
            ),
          }
        })
      )
    }
    fetchDetails().finally(() => setLoading(false))
  }, [getActions, props])

  const propertyPublish = async () => {
    if (!published) {
      setPublishing(true)
      const { msg } = await publishProperty(props.propertyId)
      setPublishing(false)
      if (msg) {
        setMessage({
          type: 'danger',
          value: msg,
        })
      } else {
        setPublished(true)
        setMessage({
          type: 'success',
          value: 'Successfully published Property!',
        })
      }
    } else {
      navigate('/properties/published')
    }
  }

  return (
    <>
      {!loading ? (
        <>
          <Card>
            <Title>
              <Bold>{details.external_name}</Bold>
            </Title>
          </Card>
          <Card>
            <div className={styles.propertyContainer}>
              <Title level={2}>
                <Bold>Property</Bold> Summary
              </Title>

              <div className={styles.summaryAction}>
                <Button
                  type={'text'}
                  onClick={() => {
                    setShowSummary(true)
                  }}
                >
                  <Bold>View Full Summary</Bold>
                </Button>
              </div>
            </div>

            <div className={styles.propertyDetails}>
              <InputPreview
                label={'Internal Name'}
                value={details.internal_name}
                customClasses={[styles.internalName]}
              />
              <InputPreview
                label={'External name'}
                value={details.external_name}
                customClasses={[styles.externalName]}
              />
              <InputPreview
                label={'Visible Date & Time'}
                value={
                  details.schedule
                    ? moment(details.schedule.visible_time).format('lll')
                    : ''
                }
                customClasses={[styles.visibleTime]}
              />
              <InputPreview
                label={'Start Date & Time'}
                value={
                  details.schedule
                    ? moment(details.schedule.start_time).format('lll')
                    : ''
                }
                customClasses={[styles.startTime]}
              />
              <InputPreview
                label={'End Date & Time'}
                value={
                  details.schedule
                    ? moment(details.schedule.end_time).format('lll')
                    : ''
                }
                customClasses={[styles.endTime]}
              />
            </div>
          </Card>
          <Card customClasses={[styles.calendarContainer]}>
            <div className={styles.leaderboardCalendar}>
              <Title level={2}>
                <Bold>Leaderboards</Bold> Calendar
              </Title>
              <Button
                onClick={() => {
                  let audience = eligibilityToAudience(details)
                  navigate('/create-leaderboard', {
                    state: {
                      audience,
                      propertyId: props.propertyId,
                      propertySchedule: details.schedule,
                    },
                  })
                }}
              >
                <Bold>Create Leaderboard</Bold>
              </Button>
            </div>
            <Table columns={columns} data={leaderboards} />
          </Card>
          {!isEmpty(details) && (
            <PropertySummary
              details={details}
              show={showSummary}
              close={() => setShowSummary(false)}
            />
          )}
          {!!details && details.state === 'Draft' && (
            <Footer customClasses={[styles.footer]}>
              <div className={styles.message}>
                {message?.value && (
                  <Text type={message.type}>
                    <Bold>{message.value}</Bold>
                  </Text>
                )}
              </div>
              <Button
                filled
                onClick={propertyPublish}
                disabled={leaderboards.length == 0 || publishing}
              >
                {!published ? 'Publish Property' : 'See Calendar'}
              </Button>
            </Footer>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default PropertyDetails
