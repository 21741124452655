import React from 'react'
import styles from './Deposit.module.scss'
import { Bold, Card, Input, Title } from 'salt'

const Deposit = ({ updateSD, depositorPercentage }) => {
  return (
    <Card>
      <Title level={3}>
        <Bold>Deposit</Bold> Details
      </Title>
      <div className={styles.depositDetails}>
        <Input
          label={'Depositor Percentage'}
          placeholder="eg: 10, 20"
          onChange={updateSD('depositorPercentage')}
          value={depositorPercentage}
          min={0.1}
          type="number"
          preventZeroValues={true}
        />
      </div>
    </Card>
  )
}

export default Deposit