import React from 'react'
import styles from './LeaderboardRules.module.scss'
import {Bold, Button, Card, Input, Title} from 'salt'
import TrashCan from 'Images/TrashCan.svg'

const LeaderboardRules = ({updateSD, rules}) => {
  return (
    <Card>
      <Title level={3}>
        <Bold>Leaderboard</Bold> Rules
      </Title>
      <div className={styles.rules}>
        {rules.map((rule, i) => {
          return (
            <div className={styles.rule} key={i}>
              <Input
                placeholder="eg: You must score minimum 100 to qualify for any cash prize"
                onChange={(value) => {
                  const r = [
                    ...rules.slice(0, i),
                    value.trimStart(),
                    ...rules.slice(i + 1),
                  ]
                  updateSD('rules')(r)
                }}
                value={rule}
              />
              {i !== 0 && (
                <img
                  className={styles.delete}
                  src={TrashCan}
                  onClick={() => {
                    let tempRules = [...rules]
                    tempRules.splice(i, 1)
                    updateSD('rules')(tempRules)
                  }}
                  alt="delete"
                />
              )}
            </div>
          )
        })}
      </div>
      <Button
        onClick={() => {
          const r = [...rules, '']
          updateSD('rules')(r)
        }}
        type={'text'}
        disabled={rules[rules.length - 1] === ''}
      >
        + Add Rule
      </Button>
    </Card>
  )
}

export default LeaderboardRules
