import React from 'react'
import {Bold, Card, Divider, Dropzone, Text, Title} from 'salt'
import styles from './LeaderboardDetails.module.scss'
import { InputPreview } from '../../../../Components/InputPreview'

const LeaderboardDetailsPreview = ({
  formatType,
  lbCategory,
  lbType,
  optIn,
  lateRegistrationAllowed,
  lateRegistrationEndTime,
  isReferral,
  benefitingAncestorGenerations,
  degreeMultiplier,
  metric,
  hasNonCashPrizes,
  leaderboardBanners,
  isPartOfSummary = false,
}) => {
  const boolToYesNo = (bool) => (bool ? 'Yes' : 'No')

  return (
    <Card
      removeLeftMargin={isPartOfSummary}
      removeRightMargin={isPartOfSummary}
    >
      <Title level={3}>
        <Bold>Leaderboard</Bold> Details
      </Title>
      <div className={styles.leaderboardDetailsPreview}>
        <InputPreview label={'Category'} value={lbCategory} />
        <InputPreview label={'Leaderboard Type'} value={lbType} />
        <InputPreview label={'Format Type'} value={formatType} />
        <InputPreview label={'Metric'} value={metric} />
        <InputPreview
          label={'Has Non Cash Prizes'}
          value={boolToYesNo(hasNonCashPrizes)}
        />
        <InputPreview
          label={'Referral Leaderboard'}
          value={boolToYesNo(isReferral)}
        />
        <InputPreview label={'Opt-In Leaderboard'} value={boolToYesNo(optIn)} />
        <InputPreview
          label={'Late Registration Allowed'}
          value={boolToYesNo(lateRegistrationAllowed)}
        />
      </div>
      {hasNonCashPrizes && leaderboardBanners && (
        <Dropzone
          files={leaderboardBanners}
          allowMultiple={true}
          disabled
          label='Leaderboard Banners Preview'
          acceptedFileTypes={[
            'image/jpeg',
            'image/png',
            'image/jpg',
            'image/webp',
          ]}
          args={{styleItemPanelAspectRatio: 0.428}}
          customClasses={[styles.bannerUploadPreview]}
        />
      )}
      {lateRegistrationAllowed && (
        <>
          <Divider placement={'left'}>For Late Registration Allowed</Divider>
          <div className={styles.lateRegistrationDetailsPreview}>
            <InputPreview
              label={'Late Registration End Time'}
              value={lateRegistrationEndTime}
            />
          </div>
        </>
      )}
      {isReferral && (
        <>
          <Divider placement={'left'}>For Referral Leaderboards</Divider>
          <Title level={3}>
            <Bold>Referral</Bold> Details
          </Title>
          <div className={styles.referralDetails}>
            <div className={styles.degree}>
              <InputPreview
                label="Referral Degree"
                value={benefitingAncestorGenerations}
              />
            </div>

            {degreeMultiplier.map((d, i) => (
              <div key={i} className={styles.degreeRow}>
                <Text weight={'paragraph'}>
                  <Bold>{d.name}</Bold>
                </Text>
                <InputPreview label="Multiplier" value={d.multiplier} />
              </div>
            ))}
          </div>
        </>
      )}
    </Card>
  )
}

export default LeaderboardDetailsPreview
