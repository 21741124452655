import React from 'react'
import { Bold, Text } from 'salt'
import styles from './InputPreview.module.scss'
import classNames from 'classnames'

const InputPreview = ({ label, value, customClasses }) => {
  const classes = classNames(styles.container, customClasses)

  return (
    <div className={classes}>
      <Text weight="small" customClasses={[styles.label]}>
        {label}
      </Text>
      <Text weight={'paragraph'} customClasses={[styles.value]}>
        <Bold type={'medium'}>{value}</Bold>
      </Text>
    </div>
  )
}

export default InputPreview
