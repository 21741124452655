import React, { useEffect, useState } from 'react'
import './App.scss'
import { Match, navigate, Router } from '@reach/router'
import 'salt/dist/index.modern.css'
import LoginScreen from 'Containers/Auth/Login/LoginScreen'
import { CreateLeaderboardScreen } from 'Containers/CreateLeaderboard'
import { Schedule } from 'Containers/Schedule'
import { LeaderboardDetails } from 'Containers/LeaderboardDetails'
import { EditLeaderboard } from 'Containers/EditLeaderboard'
import ListPublishedProperties from 'Containers/Properties/Published/ListPublishedProperties'
import ListUnPublishedProperties from 'Containers/Properties/UnPublished/ListUnPublishedProperties'
import { Alert, Bold, Button, Header, Input, Menu, Modal } from 'salt'
import NavIcon from 'Images/NavIcon.svg'
import AuthActions from './Redux/AuthRedux'
import { useDispatch } from 'react-redux'
import CreateProperty from 'Containers/Properties/Create/CreateProperty'
import PropertyDetails from 'Containers/Properties/Details/PropertyDetails'
import { addAdmin } from "./api";

const navbarItems = [
  {
    icon: NavIcon,
  },
]

function App() {
  const [selectedNavItem, setSelectedNavItem] = useState(0)
  const [selectedSidebarItem, setSelectedSidebarItem] = useState(0)
  const [selectedSidebarSubsection, setSelectedSidebarSubsection] = useState(0)

  const [showModal, setShowModal] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [successMsg, setSuccessMsg] = useState('')

  const [email, setEmail] = useState('')
  const [note, setNote] = useState('')

  const handleSubmit = async () => {
    const err = await addAdmin(email, note)
    if (err?.msg) {
      setErrorMsg(err.msg)
    } else {
      setSuccessMsg(`Successfully added ${email} as an admin`)
    }
  }

  useEffect(() => {
    const { pathname } = window.location

    if (pathname.startsWith('/schedule')) {
      setSelectedSidebarItem(0)
      setSelectedSidebarSubsection(0)
    } else if (pathname.startsWith('/properties')) {
      setSelectedSidebarItem(1)

      if (pathname.startsWith('/properties/published')) {
        setSelectedSidebarSubsection(0)
      } else {
        setSelectedSidebarSubsection(1)
      }
    }
  }, [])

  const sidebars = [
    {
      heading: (
        <>
          <Bold>leaderboards</Bold> menu
        </>
      ),
      sections: [
        {
          title: 'Leaderboards',
          onClick: () => {
            setSelectedSidebarItem(0)
            setSelectedSidebarSubsection(0)
            navigate('/schedule')
          },
        },
        {
          title: 'Properties',
          onClick: () => {
            setSelectedSidebarItem(1)
            setSelectedSidebarSubsection(0)
            navigate('/properties/published')
          },
          sectionItems: [
            {
              text: 'Published Properties',
              onClick: () => {
                setSelectedSidebarItem(1)
                setSelectedSidebarSubsection(0)
                navigate('/properties/published')
              },
            },
            {
              text: 'Unpublished Properties',
              onClick: () => {
                setSelectedSidebarItem(1)
                setSelectedSidebarSubsection(1)
                navigate('/properties/unpublished')
              },
            },
          ],
        },
      ],
    },
  ]

  const dispatch = useDispatch()

  const logout = () => {
    dispatch(AuthActions.logout())
    navigate('/')
  }

  return (
    <div className="App">
      <Router>
        <LoginScreen path="/" />
      </Router>

      <Match path="/">
        {(props) =>
          props.match ? null : (
            <>
              <Header
                brandClickHandler={() => navigate('/schedule')}
                logoutHandler={logout}
              >
                <Button type="text" onClick={() => setShowModal(true)}>
                  Add User
                </Button>
              </Header>
              <div className="mainContent">
                <Menu
                  navbarItems={navbarItems}
                  sidebars={sidebars}
                  selectedNavItem={selectedNavItem}
                  selectedSidebarItem={selectedSidebarItem}
                  selectedSidebarSubsection={selectedSidebarSubsection}
                />
                <div className="container">
                  <Router>
                    <CreateLeaderboardScreen path="/create-leaderboard" />
                    <Schedule confirm={false} path="/schedule" />
                    <LeaderboardDetails path="/leaderboard-details/:leaderboardId" />
                    <EditLeaderboard path="/leaderboard-details/:leaderboardId/edit" />
                    <ListPublishedProperties path="/properties/published" />
                    <ListUnPublishedProperties path="/properties/unpublished" />
                    <CreateProperty path="/properties/create" />
                    <PropertyDetails path="/properties/published/details/:propertyId" />
                    <PropertyDetails path="/properties/unpublished/details/:propertyId" />
                  </Router>
                </div>
              </div>
              {showModal && (
                <Modal title={'Add User'} close={() => setShowModal(false)}>
                  <div className={'modal'}>
                    <Input
                      label={'Email'}
                      placeholder="user@getmega.com"
                      onChange={(val) => setEmail(val)}
                      value={email}
                      type="text"
                    />
                    <Input
                      label={'Additional Note'}
                      onChange={(val) => setNote(val)}
                      value={note}
                      type="text"
                    />
                    {errorMsg && (
                      <Alert type={'danger'}>Error occurred: {errorMsg}</Alert>
                    )}
                    {successMsg && (
                      <Alert type={'success'}>{successMsg}</Alert>
                    )}
                    <Button onClick={handleSubmit}>Submit</Button>
                  </div>
                </Modal>
              )}
            </>
          )
        }
      </Match>
    </div>
  )
}

export default App
