import styles from './NamingDetails.module.scss'
import React from 'react'
import { Bold, Card, Input, Title } from 'salt'

const NamingDetails = ({
  updateSD,
  internalName,
  externalName,
  description,
}) => {
  return (
    <Card>
      <Title level={3}>
        <Bold>Naming</Bold> Details
      </Title>
      <div className={styles.namingDetails}>
        <Input
          label={'Internal Name'}
          placeholder={'PR511'}
          value={internalName}
          onChange={updateSD('internalName')}
          containerCustomClasses={[styles.internalName]}
        />
        <Input
          label={'External Name'}
          placeholder={'Win Rs. 30,000 Poker Daily'}
          value={externalName}
          onChange={updateSD('externalName')}
          containerCustomClasses={[styles.externalName]}
        />
        <Input
          label={'Description'}
          placeholder={'eg: this is a poker leaderboard'}
          value={description}
          onChange={updateSD('description')}
          containerCustomClasses={[styles.description]}
        />
      </div>
    </Card>
  )
}

export default NamingDetails
