import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SpawningStep, SpawningStepPreview } from './SpawningStep'
import styles from './CreateLeaderboardScreen.module.scss'
import { SelectAudience, SelectAudiencePreview } from './SelectAudience'
import { Scheduler, SchedulerPreview } from './Scheduler'
import { navigate } from '@reach/router'
import {
  hasAccess,
  scheduleLeaderboard,
  uploadLeaderboardBannerImages,
} from '../Schedule/api'
import AuthActions from 'Redux/AuthRedux'
import { validateSpawningStep } from 'Validations/SpawningStep'
import { validateSchedule } from 'Validations/Scheduler'
import { validateAudience } from 'Validations/Audience'
import LeaderboardActions from 'Redux/LeaderboardDetailsRedux'
import {
  Bold,
  Breadcrumbs,
  Button,
  Card,
  Footer,
  Stepper,
  Text,
  Title,
} from 'salt'
import CheckGreen from '../../Images/CheckGreen.svg'
import RocketBlue from '../../Images/RocketBlue.svg'
import RocketGrey from '../../Images/RocketGrey.svg'
import CalendarBlue from '../../Images/CalendarBlue.svg'
import CalendarGrey from '../../Images/CalendarGrey.svg'
import AudienceBlue from '../../Images/AudienceBlue.svg'
import AudienceGrey from '../../Images/AudienceGrey.svg'

const steps = [
  {
    title: 'Spawning',
    icons: {
      incomplete: RocketGrey,
      current: RocketBlue,
      complete: CheckGreen,
    },
  },
  {
    title: 'Schedule',
    icons: {
      incomplete: CalendarGrey,
      current: CalendarBlue,
      complete: CheckGreen,
    },
  },
  {
    title: 'Select Audience',
    icons: {
      incomplete: AudienceGrey,
      current: AudienceBlue,
      complete: CheckGreen,
    },
  },
]

const breadcrumbs = [
  {
    text: 'Created Leaderboards',
    onClick: () => {
      navigate('/schedule')
    },
  },
  { text: 'Create Leaderboard' },
]

const CreateLeaderboard = (props) => {
  const { idToken } = useSelector((state) => state.auth)
  const { spawningDetails } = useSelector((state) => state.leaderboard)

  const [audience, setAudience] = useState([])
  const [playerIds, setPlayerIds] = useState([])
  const [excludedPlayerIds, setExcludedPlayerIds] = useState([])
  const [schedule, setSchedule] = useState()

  const [step, setStep] = useState(0)
  const [stepInPreview, setStepInPreview] = useState(false)

  const [scheduled, setScheduled] = useState(false)
  const [scheduling, setScheduling] = useState(false)
  const [message, setMessage] = useState({ type: 'success', value: '' })
  const [propertyId, setPropertyId] = useState('')
  const [hasUserInteracted, setHasUserInteracted] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (props.location && props.location.state) {
      if (props.location.state.spawningDetails) {
        dispatch(
          LeaderboardActions.setSpawningDetails(
            props.location.state.spawningDetails
          )
        )
        window.history.replaceState(null, '')
      }

      if (props.location.state.audience) {
        setAudience(props.location.state.audience)
      }

      if (props.location.state.propertyId) {
        setPropertyId(props.location.state.propertyId)
      }
    }
  }, [props, dispatch])

  const sdValidation = validateSpawningStep(spawningDetails)
  const schedulerValidation = validateSchedule(
    schedule,
    props?.location?.state?.propertySchedule
  )
  const audienceValidation = validateAudience(audience, playerIds)
  const [csvValidation, setCSVValidation] = useState({
    passed: true,
    error: '',
  })

  useEffect(() => {
    localStorage.setItem(
      'leaderboard-creation-form',
      JSON.stringify({
        spawningDetails,
        audience,
        schedule,
      })
    )
  }, [spawningDetails, audience, schedule])

  const updateRule = useCallback((formValues) => {
    setSchedule(formValues)
  }, [])

  useEffect(() => {
    const checkAccess = async () => {
      const hA = await hasAccess()
      if (!hA) {
        dispatch(AuthActions.logout())
        setTimeout(() => navigate('/?noaccess=true'), 1000)
      }
    }
    checkAccess()
  }, [dispatch])

  const shouldDisableShowPreview = (step) => {
    switch (step) {
      case 0:
        return !sdValidation.passed
      case 1:
        return !sdValidation.passed || !schedulerValidation.passed
      default:
        return (
          !sdValidation.passed ||
          !schedulerValidation.passed ||
          !audienceValidation.passed
        )
    }
  }

  const getValidationErr = (step) => {
    const getError = (...validations) => {
      for (const v of validations) {
        if (!!v.error) {
          return v.error
        }
      }

      return ''
    }

    switch (step) {
      case 0:
        return getError(sdValidation)
      case 1:
        return getError(sdValidation, schedulerValidation)
      default:
        return getError(sdValidation, schedulerValidation, audienceValidation)
    }
  }

  const scheduleLB = async (e) => {
    e.stopPropagation()

    if (!scheduled) {
      setScheduling(true)

      const { banner_image_url, banners } = await uploadLeaderboardBannerImages(
        spawningDetails
      )

      const { templateId, msg } = await scheduleLeaderboard({
        spawningDetails: { ...spawningDetails, banner_image_url, banners },
        audience,
        schedule,
        playerIds,
        excludedPlayerIds,
        propertyId,
        accessToken: idToken || localStorage.getItem('accessToken'),
      })
      setScheduling(false)

      if (templateId) {
        if (!!propertyId) {
          navigate(`/properties/unpublished/details/${propertyId}`)
        } else {
          setScheduled(true)
          setMessage({
            type: 'success',
            value: 'Successfully scheduled Leaderboard!',
          })
        }
      }

      if (msg) {
        setMessage({ type: 'danger', value: msg })
      }
    } else {
      navigate('/schedule')
    }
  }

  return (
    <>
      <div
        id="createLeaderboard"
        onMouseDown={() => setHasUserInteracted(true)}
      >
        <Card customClasses={[styles.header]}>
          <Title>
            Create A <Bold>Leaderboard</Bold>
          </Title>
          <Breadcrumbs
            breadcrumbs={breadcrumbs}
            customClassNames={[styles.breadcrumbs]}
          />
          <Stepper
            numberOfSteps={!!propertyId ? 2 : 3}
            currentStep={step + 1}
            stepsData={steps}
            containerCustomClasses={[styles.stepper]}
          />
        </Card>

        {step === 0 &&
          (stepInPreview ? <SpawningStepPreview /> : <SpawningStep />)}

        {step === 1 &&
          (stepInPreview ? (
            <SchedulerPreview schedule={schedule} />
          ) : (
            <Scheduler updateRule={updateRule} />
          ))}

        {step === 2 &&
          (stepInPreview ? (
            <SelectAudiencePreview schedule={schedule} audience={audience} />
          ) : (
            <SelectAudience
              updateAudience={(aud) => {
                setAudience(aud)
              }}
              updatePlayerIds={(playerIds) => {
                setPlayerIds(playerIds)
              }}
              setCSVValidation={setCSVValidation}
              updateExcludedPlayerIds={(playerIds) => {
                setExcludedPlayerIds(playerIds)
              }}
              audience={audience}
            />
          ))}

        {step === 3 && (
          <>
            <SpawningStepPreview />
            <SchedulerPreview schedule={schedule} />
            <SelectAudiencePreview schedule={schedule} audience={audience} />
          </>
        )}
      </div>
      {step !== 4 && (
        <Footer customClasses={[styles.footer]}>
          <div>
            {message?.value && (
              <Text type={message.type}>
                <Bold>{message.value}</Bold>
              </Text>
            )}
            <Text type={'danger'}>
              <Bold>{hasUserInteracted && getValidationErr(step)}</Bold>
            </Text>
          </div>

          {!stepInPreview && (
            <>
              {step !== 0 && !scheduled && (
                <Button
                  type={'secondary'}
                  onClick={() => {
                    setStep(step - 1)
                  }}
                >
                  Back
                </Button>
              )}
              {step !== 3 && (
                <Button
                  onClick={() => setStepInPreview(true)}
                  disabled={shouldDisableShowPreview(step)}
                >
                  Show Preview
                </Button>
              )}
              {step === 3 && (
                <Button onClick={scheduleLB} disabled={scheduling}>
                  {!scheduled ? 'Schedule' : 'See Calendar'}
                </Button>
              )}
            </>
          )}

          {stepInPreview && (
            <>
              <Button
                type={'secondary'}
                onClick={() => setStepInPreview(false)}
              >
                Back
              </Button>
              {step === 1 && !!propertyId ? (
                <Button onClick={scheduleLB} disabled={scheduling}>
                  {!scheduled ? 'Schedule' : 'See Calendar'}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setStep(step + 1)
                    setStepInPreview(false)
                  }}
                >
                  Next
                </Button>
              )}
            </>
          )}
        </Footer>
      )}
    </>
  )
}

export default CreateLeaderboard
