import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
// import { path } from 'ramda'
// import store from 'store'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loginRequest: [],
  loginSuccess: ['data'],
  loginFailure: ['error'],

  logout: [],
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */
// const initialData = { accessToken: null }

// const resetState = {
//   fetching: null,
//   error: null,
//   passwordError: null
// }

export const INITIAL_STATE = Immutable({
  loading: false,
  isAuth: false,
  user: null,
  username: null,
  message: null,
  idToken: null,
})

/* ------------- Reducers ------------- */

// request the data for a auth
export const success = (state, { data }) => ({
  ...state,
  isAuth: true,
  user: data.user,
  username: data.user.displayName,
  idToken: data.user.xa,
})

export const failure = (state, { error }) =>
  state.merge({
    isAuth: false,
  })

/* ------------- Selectors ------------- */

export const AuthSelectors = {}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.LOGIN_SUCCESS]: success,
  [Types.LOGIN_FAILURE]: failure,
})
