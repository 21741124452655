import styles from './NamingDetails.module.scss'
import React from 'react'
import { Bold, Card, Title } from 'salt'
import { InputPreview } from '../../../../Components/InputPreview'

const NamingDetailsPreview = ({
  internalName,
  externalName,
  description,
  isPartOfSummary = false,
}) => {
  return (
    <Card
      removeLeftMargin={isPartOfSummary}
      removeRightMargin={isPartOfSummary}
    >
      <Title level={3}>
        <Bold>Naming</Bold> Details
      </Title>
      <div className={styles.namingDetails}>
        <InputPreview
          label={'Internal Name'}
          value={internalName}
          customClasses={[styles.internalName]}
        />
        <InputPreview
          label={'External Name'}
          value={externalName}
          customClasses={[styles.externalName]}
        />
        <InputPreview
          label={'Description'}
          value={description}
          customClasses={[styles.description]}
        />
      </div>
    </Card>
  )
}

export default NamingDetailsPreview
