import { Tag } from 'salt'
import OrangeCheckIcon from 'Images/OrangeCheck.svg'
import RedCrossIcon from 'Images/RedCross.svg'
import LiveIcon from 'Images/Live.svg'
import HourGlassIcon from 'Images/HourGlass.svg'
import React from 'react'
import { attributesMap, getSubAttributes } from '../CreateLeaderboard/constants'
import { toISTFormattedTime } from '../CreateLeaderboard/Scheduler/rule'
import moment from 'moment'

export const getStateTag = (state) => {
  switch (state) {
    case 'Closed':
      return <Tag type={'warning'} icon={OrangeCheckIcon} />
    case 'Cancelled':
      return <Tag type={'danger'} icon={RedCrossIcon} />
    case 'Live':
    case 'Visible':
      return <Tag type={'success'} icon={LiveIcon} />
    case 'Open':
      return <Tag type={'primary'} icon={HourGlassIcon} />
    default:
      return null
  }
}

export const eligibilityToAudience = (d) => {
  return d.eligibility?.map((el) => {
    let e = getSubAttributes(el.attribute)
    e = { ...el, ...e }

    const attribute = Object.keys(attributesMap).find(
      (key) => attributesMap[key].key === e.attribute
    )

    let defaultMinDate = new Date()
    let defaultMaxDate = new Date()
    defaultMinDate.setHours(0, 0, 0, 0)
    defaultMaxDate.setDate(defaultMaxDate.getDate() + 1)
    defaultMaxDate.setHours(0, 0, 0, 0)

    const audienceMap = {
      attribute: attribute || e.attribute,
      asOf: toISTFormattedTime(e.as_of),
      during: [defaultMinDate, defaultMaxDate],
    }

    if (e.attribute_type === 'TIME') {
      // this means a long date has been selected
      let fsTime =
        Math.abs(moment(e.value_range.time_min).year() - moment().year()) > 30
      let feTime =
        Math.abs(moment(e.value_range.time_max).year() - moment().year()) > 30

      if (!fsTime) {
        audienceMap.min = new Date(
          moment(e.value_range.time_min).startOf('day').format()
        )
      }

      if (!feTime) {
        audienceMap.max = new Date(
          moment(e.value_range.time_max).startOf('day').format()
        )
      }
    } else if (e.attribute_type === 'NUMBER') {
      if (e.value_range.has_min) audienceMap.min = e.value_range.number_min || 0
      if (e.value_range.has_max) audienceMap.max = e.value_range.number_max
    } else if (e.attribute_type === 'STRING') {
      audienceMap.stringValue = e.value_range.string_value
    }

    if (e.attribute === 'Player.JoinedAt') {
      audienceMap.comparator = '<='
    } else {
      if (audienceMap.min !== undefined && !audienceMap.max) {
        audienceMap.comparator = '>='
      } else if (!audienceMap.min && audienceMap.max) {
        audienceMap.comparator = '<='
      } else if (audienceMap.min && audienceMap.max) {
        audienceMap.comparator = 'Between'
      } else {
        audienceMap.comparator = '='
      }
    }

    audienceMap.asPer = e.anchor

    return audienceMap
  })
}

export const generateImageUrl = (image_url) => {
  const dpr = window.devicePixelRatio
  const width = window.innerWidth - 32
  return `${process.env.REACT_APP_IMAGE_KIT_URL}${image_url}?tr=w-${width},dpr-${dpr},pr-true`
}
