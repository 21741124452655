import React from 'react'
import { NamingDetails } from './NamingDetails'
import { BannerDetails } from './BannerDetails'

const SpawningStep = ({ sD, updateSD }) => {
  const { internalName, externalName, description, largeImage, smallImage } = sD
  return (
    <>
      <NamingDetails
        updateSD={updateSD}
        internalName={internalName}
        externalName={externalName}
        description={description}
      />

      <BannerDetails
        updateSD={updateSD}
        lImage={largeImage}
        sImage={smallImage}
      />
    </>
  )
}

export default SpawningStep
