import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { navigate } from '@reach/router'
import get from 'lodash/get'
import moment from 'moment'
import {
  Alert,
  Bold,
  Button,
  Card,
  DatePicker,
  Modal,
  Table,
  Tag,
  Text,
  Title,
  Link,
} from 'salt'
import styles from './Schedule.module.scss'
import { deleteLeaderboard, getLeaderboards } from './api'
import TrashIcon from 'Images/TrashCan.svg'
import HourGlassIcon from 'Images/HourGlass.svg'
import LiveIcon from 'Images/Live.svg'
import OrangeCheckIcon from 'Images/OrangeCheck.svg'
import RedCrossIcon from 'Images/RedCross.svg'
import CopyIcon from 'Images/Copy.svg'
import { getStateTag, createLbFromTemplate } from './helper'
import { Loader } from 'Components/Loader'

const Schedule = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'State',
        accessor: 'stateTag',
        maxWidth: 150,
        filterable: true,
        filterMethod: (filter, row) => {
          if (
            ['Open', 'Live', 'ResultDeclared', 'Cancelled'].includes(
              filter.value
            )
          ) {
            return get(row, '_original.state') === filter.value
          }
          return true
        },
        Filter: ({ filter, onChange }) => (
          <select
            onChange={(event) => onChange(event.target.value)}
            className={styles.filterSelect}
            value={filter ? filter.value : 'all'}
          >
            <option value="all">Show All</option>
            <option value="Open">Yet to Start</option>
            <option value="Live">Live</option>
            <option value="ResultDeclared">Expired</option>
            <option value="Cancelled">Cancelled</option>
          </select>
        ),
      },
      {
        Header: 'Category',
        accessor: 'category',
        maxWidth: 120,
        filterable: true,
      },
      {
        Header: 'Type',
        accessor: 'type',
        maxWidth: 120,
        filterable: true,
      },
      {
        Header: 'Internal Name',
        accessor: 'internalName',
        filterable: true,
      },
      {
        Header: 'External Name',
        accessor: 'externalName',
        filterable: true,
      },
      {
        Header: 'Game',
        accessor: 'gameName',
        maxWidth: 120,
        filterable: true,
      },
      {
        Header: 'Start Time',
        accessor: 'startTime',
        maxWidth: 120,
      },
      {
        Header: 'End Time',
        accessor: 'endTime',
        maxWidth: 120,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        width: 220,
        fixed: 'right',
      },
    ],
    []
  )

  const [date, setDate] = useState(new Date())
  const [loading, setLoading] = useState(false)

  const [lbData, setLbData] = useState([])
  const [currentLb, setCurrentLb] = useState('')

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const getActions = useCallback((lb) => {
    const renderMainAction = (text) => (
      <Link path={`/leaderboard-details/${lb.leaderboard_id}`}>{text}</Link>
    )

    const actions = []
    switch (lb.state) {
      case 'ResultDeclared':
        actions.push(renderMainAction('View History'))
        break
      case 'Cancelled':
        break
      case 'Live':
        actions.push(renderMainAction('View Activity'))
        break
      default:
        actions.push(
          renderMainAction('See Details'),
          <Button
            type={'text'}
            onClick={() => {
              setShowDeleteModal(true)
              setCurrentLb(lb)
            }}
          >
            <Tag type={'primary'} icon={TrashIcon} />
          </Button>
        )
        break
    }

    actions.push(
      <Button type={'text'} onClick={() => copyTemplate(lb.template_id)}>
        <Tag type={'primary'} icon={CopyIcon} />
      </Button>
    )
    return actions
  }, [])

  const fetchLeaderboards = useCallback(
    async (date) => {
      setLoading(true)

      const { leaderboards: lbs } = await getLeaderboards(
        moment(date).startOf('day').format()
      )

      const lbData = lbs.map((lb) => ({
        state: lb.state,
        stateTag: getStateTag(lb.state),
        category: lb.leaderboard_category,
        type: lb.leaderboard_type,
        externalName: lb.external_name,
        internalName: lb.internal_name,
        gameName: lb.game_name,
        startTime: moment(lb.live_time).format('hh:mm A'),
        endTime: moment(lb.close_time).format('hh:mm A'),
        actions: <div className={styles.actions}>{getActions(lb)}</div>,
      }))

      setLbData(lbData)

      setLoading(false)
    },
    [getActions]
  )

  const copyTemplate = (templateId) => {
    setLoading(true)
    createLbFromTemplate(templateId).catch(() => {
      setLoading(false)
    })
  }

  useEffect(() => {
    fetchLeaderboards(date)
  }, [fetchLeaderboards, date])

  const deleteLb = (lbId) => async (e) => {
    e.stopPropagation()

    const { msg } = await deleteLeaderboard(
      lbId,
      localStorage.getItem('accessToken')
    )

    if (!!msg) {
      setErrorMsg(msg)
    } else {
      setShowDeleteModal(false)
      fetchLeaderboards(date)
    }
  }

  return (
    <>
      <Card customClasses={[styles.header]}>
        <Title>
          Created <Bold>Leaderboards</Bold>
        </Title>
        <Button onClick={() => navigate('/create-leaderboard')}>
          <Bold>Create a Leaderboard</Bold>
        </Button>
      </Card>
      <Card>
        <Title level={2}>
          <Bold>Leaderboards</Bold> List
        </Title>
        <div className={styles.infoPanel}>
          <div className={styles.legend}>
            <Tag icon={HourGlassIcon} />
            <Text weight={'small'}>Yet to Start</Text>
            <Tag icon={LiveIcon} />
            <Text weight={'small'}>Live</Text>
            <Tag icon={OrangeCheckIcon} />
            <Text weight={'small'}>Expired</Text>
            <Tag icon={RedCrossIcon} />
            <Text weight={'small'}>Cancelled</Text>
          </div>
          <DatePicker
            selected={date}
            onChange={(d) => {
              setDate(d)
            }}
            placeholderText={'Select date'}
          />
        </div>

        {!loading ? <Table columns={columns} data={lbData} /> : <Loader />}
      </Card>
      {showDeleteModal && (
        <Modal
          title={'Delete Leaderboard'}
          close={() => setShowDeleteModal(false)}
        >
          <div className={styles.deleteModal}>
            <Title level={3} textCase={'none'}>
              <Bold type={'medium'}>
                Are you sure you want to delete this leaderboard?
              </Bold>
            </Title>
            <Alert type={'warning'}>This cannot be undone</Alert>
            {errorMsg && (
              <Alert type={'danger'}>Error occurred: {errorMsg}</Alert>
            )}
            <Button onClick={deleteLb(currentLb.leaderboard_id)}>Delete</Button>
          </div>
        </Modal>
      )}
    </>
  )
}

export default Schedule
