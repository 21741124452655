import React from 'react'
import styles from './TermsAndConditions.module.scss'
import { Alert, Bold, Card, Text, Title } from 'salt'

const TermsAndConditionsPreview = ({ terms, isPartOfSummary }) => {
  return (
    <Card
      removeLeftMargin={isPartOfSummary}
      removeRightMargin={isPartOfSummary}
    >
      <Title level={3}>
        <Bold>Terms &</Bold> Conditions
      </Title>
      <div className={styles.termsPreview}>
        {terms.length !== 0 && !!terms.join('') ? (
          <>
            {terms.map((term, i) => {
              return (
                <Text weight={'paragraph'} key={i}>
                  <Bold type={'medium'}>
                    {i + 1}. {term}
                  </Bold>
                </Text>
              )
            })}
          </>
        ) : (
          <Alert type={'info'}>
            No terms or conditions for leaderboard
          </Alert>
        )}
      </div>
    </Card>
  )
}

export default TermsAndConditionsPreview;