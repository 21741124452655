import { validatePrizeDistribution } from './PrizeDistribution'

export const validateSpawningStep = (details) => {
  const {
    internalName,
    externalName,
    description,
    lbCategory,
    lbType,
    systemEvent,
    gameCategory,
    gameName,
    bbMinValue,
    bbMaxValue,
    totalPrize,
    prizes,
    goalBasedPrizes,
    terms,
    entryFee,
    slots,
    seedPlayers,
    percentOfWinningPlayers,
    leaderboardBannerImage,
    discount,
    discountSlots,
    benefitingAncestorGenerations,
    degreeMultiplier,
    isReferral,
    depositorPercentage,
    lateRegistrationAllowed,
    lateRegistrationDurationHour,
    lateRegistrationDurationMin,
    hasNonCashPrizes,
    leaderboardBanners,
  } = details

  let validationPassed = true
  let validationErr = ''

  if (!internalName) {
    validationPassed = false
    validationErr = 'Please fill Internal Name'
    return returnPayload(validationPassed, validationErr)
  }

  if (!externalName) {
    validationPassed = false
    validationErr = 'Please fill External Name'
    return returnPayload(validationPassed, validationErr)
  }

  if (!description) {
    validationPassed = false
    validationErr = 'Please fill Description'
    return returnPayload(validationPassed, validationErr)
  }

  if (!lbCategory) {
    validationPassed = false
    validationErr = 'Please fill Leaderboard Category'
    return returnPayload(validationPassed, validationErr)
  }

  if (!lbType) {
    validationPassed = false
    validationErr = 'Please fill Leaderboard Type'
    return returnPayload(validationPassed, validationErr)
  }

  if (isReferral) {
    if (benefitingAncestorGenerations === 0) {
      validationPassed = false
      validationErr = 'Degree should be more than zero'
      return returnPayload(validationPassed, validationErr)
    }

    for (let i = 0; i < degreeMultiplier.length; i++) {
      const e = degreeMultiplier[i]
      if (!e.multiplier) {
        validationPassed = false
        validationErr = 'Multiplier should be greater than equal to 1'
        return returnPayload(validationPassed, validationErr)
      }
    }
  }

  if (lateRegistrationAllowed) {
    if (!lateRegistrationDurationHour && lateRegistrationDurationHour !== 0) {
      validationPassed = false
      validationErr = 'Please fill Late Registration Window Hour'
      return returnPayload(validationPassed, validationErr)
    }

    if (!lateRegistrationDurationMin && lateRegistrationDurationMin !== 0) {
      validationPassed = false
      validationErr = 'Please fill Late Registration Window Minutes'
      return returnPayload(validationPassed, validationErr)
    }
  }

  if (!systemEvent) {
    validationPassed = false
    validationErr = 'Please fill Metric Field'
    return returnPayload(validationPassed, validationErr)
  }

  if (hasNonCashPrizes) {
    if (!leaderboardBanners || leaderboardBanners.length === 0) {
      validationPassed = false
      validationErr = 'Please upload at least one Banner for Non Cash Leaderboard'
      return returnPayload(validationPassed, validationErr)
    }
  }

  if (terms.length === 0 || !terms[0]) {
    validationPassed = false
    validationErr = 'Enter at least one T&C'
    return returnPayload(validationPassed, validationErr)
  } else {
    for (let i = 0; i < terms.length; i++) {
      const t = terms[i]
      if (t === '') {
        validationPassed = false
        validationErr = 'T&C cannot be empty'
        return returnPayload(validationPassed, validationErr)
      }
    }
  }

  if (systemEvent.value === 'Deposit') {
    if (depositorPercentage === 0) {
      validationPassed = false
      validationErr = 'Depositor Percentage should be greater than 0'
      return returnPayload(validationPassed, validationErr)
    }
  } else {
    if (!gameCategory) {
      validationPassed = false
      validationErr = 'Please fill Game Category'
      return returnPayload(validationPassed, validationErr)
    }

    if (!gameName) {
      validationPassed = false
      validationErr = 'Please fill Game Name'
      return returnPayload(validationPassed, validationErr)
    }

    if (!bbMinValue) {
      validationPassed = false
      validationErr = 'Please fill Tournament Design'
      return returnPayload(validationPassed, validationErr)
    }
  }

  if (!!bbMaxValue && bbMaxValue < bbMinValue) {
    validationPassed = false
    validationErr = 'Tournament Design max value should be greater than min'
    return returnPayload(validationPassed, validationErr)
  }

  if (lbCategory && lbCategory.value === 'paid') {
    if (!percentOfWinningPlayers) {
      validationPassed = false
      validationErr = 'Please fill Percent of Winnings Players'
      return returnPayload(validationPassed, validationErr)
    }

    if (!entryFee) {
      validationPassed = false
      validationErr = 'Please fill Entry Fee'
      return returnPayload(validationPassed, validationErr)
    }

    if (!slots) {
      validationPassed = false
      validationErr = 'Please fill Slots'
      return returnPayload(validationPassed, validationErr)
    }

    if (!seedPlayers) {
      validationPassed = false
      validationErr = 'Please fill Seed Players'
      return returnPayload(validationPassed, validationErr)
    }

    if (!leaderboardBannerImage) {
      validationPassed = false
      validationErr = 'Please upload an image for paid leaderboard'
      return returnPayload(validationPassed, validationErr)
    }

    if (discount > entryFee) {
      validationPassed = false
      validationErr = 'Discount should be less than entry fee'
      return returnPayload(validationPassed, validationErr)
    }

    if (discountSlots > slots) {
      validationPassed = false
      validationErr = 'Discount slots should be less than total slots'
      return returnPayload(validationPassed, validationErr)
    }
  } else {
    const prizeDistValidation = validatePrizeDistribution(
      totalPrize,
      prizes,
      goalBasedPrizes,
      hasNonCashPrizes
    )
    if (!prizeDistValidation.passed) {
      return prizeDistValidation
    }
  }

  return returnPayload(validationPassed, validationErr)
}

const returnPayload = (validationPassed, validationErr) => {
  return {
    passed: validationPassed,
    error: validationErr,
  }
}
