import moment from 'moment'
import axios from 'axios'
import firebase from 'firebase/app'
import { getUTCTime } from 'Containers/CreateLeaderboard/Scheduler/rule'
import {
  asPerToAsOf,
  attributesMap,
  validateComparatorValues,
} from 'Containers/CreateLeaderboard/constants'

const baseUrl = '/twirp/msadmin.v1.LBPropertiesService'
const templateBaseUrl = '/twirp/msadmin.v1.LBTemplateService'

//TODO: Refactor this file

// TODO: Find better solution than waiting 2sec on initial render
// Add Context based component which waits for firebase auth to complete,
// then renders correct page or redirects to login
let numRetries = 0
const getAccessToken = async () => {
  let accessToken = firebase.auth().currentUser?.getIdToken()

  // promise chain will keep growing if we don't put max limit
  if (accessToken || numRetries >= 16) {
    numRetries = 0
    return accessToken
  }

  return new Promise((res) =>
    setTimeout(() => {
      numRetries += 1
      res(getAccessToken())
    }, 2000)
  )
}

const generateBody = ({
  spawningDetails: sd,
  audience,
  schedule,
  playerIds,
}) => {
  const startTime = getUTCTime(
    schedule.startDate,
    schedule.startHour,
    schedule.startMinute
  )
  const endTime = getUTCTime(
    schedule.endTime,
    schedule.endHour,
    schedule.endMinute
  )

  const body = {
    external_name: sd.externalName,
    internal_name: sd.internalName,
    description: sd.description,
    banners: {
      large_image_url: sd.largeImageUrl,
      small_image_url: sd.smallImageUrl,
    },
    eligibility: audience.map((attr) => {
      const attrMapVal = attributesMap[attr.attribute]
      const a = validateComparatorValues(attr)
      let a_type = attrMapVal.type,
        value_range = {},
        during = [],
        as_of = null
      if (attrMapVal.type === 'TIME') {
        if (a.min) {
          value_range.time_min = moment(
            getUTCTime(a.min, { value: 0 }, { value: 0 })
          ).format()
        }

        if (a.max) {
          value_range.time_max = moment(
            getUTCTime(a.max, { value: 0 }, { value: 0 })
          ).format()
        }
      } else if (attrMapVal.type === 'STRING') {
        value_range.string_value = a.stringValue
      } else if (attrMapVal.type === 'NUMBER') {
        value_range = {
          number_min: parseFloat(a.min, 10),
          number_max: parseFloat(a.max, 10),
          has_min: !isNaN(parseFloat(a.min, 10)),
          has_max: !isNaN(parseFloat(a.max, 10)),
        }
      }

      if (attrMapVal.asPer) {
        as_of = asPerToAsOf(a.asPer, startTime, endTime)
      }

      if (attrMapVal.between) {
        during = a.during
      }

      return {
        attribute: attrMapVal.key,
        attribute_type: a_type,
        value_range: value_range,
        as_of: as_of,
        anchor: a.asPer,
        during: during,
      }
    }),
    player_ids: playerIds,
    schedule: {
      visible_time: moment(
        getUTCTime(
          schedule.visibleTime,
          schedule.visibleTimeHour,
          schedule.visibleTimeMinute
        )
      ).format(),
      start_time: moment(startTime).format(),
      end_time: moment(endTime).format(),
    },
  }

  return body
}

const getGCSSignedUrl = async (fileNames) => {
  const url = `${process.env.REACT_APP_FETCH_URL}/twirp/msadmin.v1.LBTemplateService/GetImageUploadUrl`
  const data = {
    file_names: fileNames,
  }

  const { signed_urls } = await axios({
    url,
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    data,
  })
    .then((response) => {
      return response.data
    })
    .catch((err) => {
      console.error(err)
      return {}
    })

  return signed_urls
}

export const uploadImage = async (sd, image) => {
  const fileName = `${sd.internalName}-${image.name}`
  const signed_urls = await getGCSSignedUrl([fileName])

  const { signed_url, file_path } = signed_urls[fileName]
  if (signed_url) {
    try {
      await axios(signed_url, {
        method: 'PUT',
        headers: {
          'Content-Type': image.type,
        },
        data: image,
      })

      return file_path
    } catch (err) {
      console.error(err)
    }
  }
}

export const createProperty = async ({
  spawningDetails,
  audience,
  schedule,
  playerIds,
}) => {
  const url = `${process.env.REACT_APP_FETCH_URL}${baseUrl}/CreateProperty`
  const data = generateBody({
    spawningDetails,
    audience,
    schedule,
    playerIds,
  })

  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data,
  })
    .then((response) => {
      const propertyId = response.data.property_id
      return {
        propertyId,
        msg: '',
      }
    })
    .catch((error) => {
      console.error(error)
      const { msg } = error?.response?.data
      return {
        propertyId: '',
        msg,
      }
    })
}

export const fetchProperties = async (propertyStatus) => {
  const url = `${process.env.REACT_APP_FETCH_URL}${baseUrl}/ListProperties`
  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data: { property_status: propertyStatus },
  })
    .then((response) => {
      const properties =
        response.data && response.data.properties
          ? response.data.properties
          : []
      return {
        properties,
        msg: '',
      }
    })
    .catch((error) => {
      console.log(error)
      const { msg } = error?.response?.data
      return {
        properties: [],
        msg,
      }
    })
}

export const fetchPropertyDetails = async (propertyId) => {
  const url = `${process.env.REACT_APP_FETCH_URL}${baseUrl}/GetPropertyDetails`
  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data: { property_id: propertyId },
  })
    .then((response) => {
      const data = response.data ? response.data : {}
      return {
        data,
        msg: '',
      }
    })
    .catch((error) => {
      console.log(error)
      const { msg } = error?.response?.data
      return {
        data: {},
        msg,
      }
    })
}

export const fetchPropertyChildren = async (propertyId) => {
  const url = `${process.env.REACT_APP_FETCH_URL}${baseUrl}/GetPropertyChildren`
  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data: { property_id: propertyId },
  })
    .then((response) => {
      const childrens = response.data.children || []
      return {
        childrens,
        msg: '',
      }
    })
    .catch((error) => {
      console.log(error)
      const { msg } = error?.response?.data
      return {
        data: {},
        msg,
      }
    })
}

export const publishProperty = async (propertyId) => {
  const url = `${process.env.REACT_APP_FETCH_URL}${baseUrl}/PublishProperty`
  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data: { property_id: propertyId },
  })
    .then((response) => {
      return {
        msg: '',
      }
    })
    .catch((error) => {
      const { msg } = error?.response?.data
      return {
        msg,
      }
    })
}

export const deleteTemplate = async (templateId) => {
  const url = `${process.env.REACT_APP_FETCH_URL}${templateBaseUrl}/DeleteTemplate`
  return axios({
    method: 'POST',
    headers: {
      Authorization: `Bearer ${await getAccessToken()}`,
    },
    url,
    data: { template_id: templateId },
  })
    .then((response) => {
      return {
        msg: null,
      }
    })
    .catch((error) => {
      console.log(error)
      const { msg } = error?.response?.data
      return {
        msg,
      }
    })
}
