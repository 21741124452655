import React, { useState, useEffect } from 'react'


const ImageUploader = ({ imageState }) => {
    const [selectedFile, setSelectedFile] = useState()
    const [preview, setPreview] = useState()

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!selectedFile) {
            setPreview(undefined)
            return
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)
        imageState(selectedFile)
        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)

      
    }, [imageState, selectedFile])

    const onSelectFile = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])

    }

    return (
        <div>
            <input type='file' onChange={onSelectFile} accept="image/*" />
            {selectedFile &&  <img src={preview} alt="preview" style={{marginTop: '15px', width: 'auto', maxWidth:'100%', height: 'auto'}} /> }
        </div>
    )
}

export default ImageUploader
