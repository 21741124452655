import React from 'react'
import styles from './TournamentDesign.module.scss'
import {
  Bold,
  Button,
  Card,
  Divider,
  Input,
  Select,
  Text,
  Title,
} from 'salt'
import { gameNameOp, numberTypeOp, pokerHands } from '../../constants'

export const getInputsOfType = (bbType, bbMinValue, bbMaxValue, updateSD) => {
  return (
    <>
      {bbType.value === numberTypeOp[0].value ? (
        <Input
          label="Value"
          placeholder="eg: 10, 20"
          onChange={updateSD('bbMinValue')}
          value={bbMinValue}
          type="number"
          min={0}
        />
      ) : (
        <>
          <Input
            label="Minimum Value"
            placeholder="eg: 10, 20"
            onChange={updateSD('bbMinValue')}
            value={bbMinValue}
            type="number"
            min={0}
          />
          <Input
            label="Maximum Value"
            placeholder="eg: 10, 20"
            onChange={updateSD('bbMaxValue')}
            value={bbMaxValue}
            type="number"
            min={0}
          />
        </>
      )}
    </>
  )
}

const TournamentDesign = ({
  updateSD,
  gameName,
  bbType,
  bbMinValue,
  bbMaxValue,
  systemEvent,
  scoringSystem,
}) => {
  const getBBRange = () => {
    let range = ''
    if (bbMinValue) {
      range += `(BB ${bbMinValue}`

      if (bbMaxValue) {
        range += ` - ${bbMaxValue}`
      }

      range += ')'
    }
    return range
  }

  const shouldShowBBTable = () => {
    if (systemEvent.value !== 'PokerHand') return false

    if (bbType.value === numberTypeOp[0].value) {
      return !!bbMinValue
    } else {
      return !!bbMinValue && !!bbMaxValue
    }
  }

  const getBBTable = () => {
    return (
      <div className={styles.bbTable}>
        <table>
          <tr>
            <th></th>
            {scoringSystem.map((b, i) => {
              return (
                <th key={i}>
                  <div className={styles.bbInputs}>
                    <Input
                      label="Enter BB"
                      placeholder="eg: 10"
                      type="number"
                      min={0.1}
                      onChange={(value) => {
                        const newSS = [
                          ...scoringSystem.slice(0, i),
                          { ...scoringSystem[i], bb: value },
                          ...scoringSystem.slice(i + 1),
                        ]
                        updateSD('scoringSystem')(newSS)
                      }}
                      value={b.bb}
                      inputCustomClasses={[styles.bbInput]}
                      labelCustomClasses={[styles.bbLabel]}
                      preventZeroValues={true}
                    />
                    <Input
                      label="Multiplier"
                      placeholder="Enter multiplier"
                      type="number"
                      min={1}
                      onChange={(value) => {
                        const newSS = [
                          ...scoringSystem.slice(0, i),
                          { ...scoringSystem[i], multiplier: value },
                          ...scoringSystem.slice(i + 1),
                        ]
                        updateSD('scoringSystem')(newSS)
                      }}
                      value={b.multiplier}
                      inputCustomClasses={[styles.bbInput]}
                      labelCustomClasses={[styles.bbLabel]}
                      preventZeroValues={true}
                    />
                  </div>
                </th>
              )
            })}
          </tr>
          <tr>
            <td>
              <Text weight={'paragraph'}>
                <Bold>Poker Hand</Bold>
              </Text>
            </td>
            {scoringSystem.map((ss, i) => (
              <td key={i}>
                <Bold>
                  <Text weight={'paragraph'}>Score For BB {ss.bb || null}</Text>
                </Bold>
              </td>
            ))}
          </tr>
          {pokerHands.map((h, i) => {
            return (
              <tr key={i}>
                <td>
                  <Text weight={'paragraph'}>{h.hand}</Text>
                </td>
                {scoringSystem.map((ss, i) => (
                  <td key={i}>
                    <Text>{h.value * ss.multiplier || 1}</Text>
                  </td>
                ))}
              </tr>
            )
          })}
        </table>
      </div>
    )
  }

  return (
    <Card>
      <Title level={3}>
        <Bold>Tournament</Bold> Design (
        {gameName.value === gameNameOp[0].value ? 'Big Blind' : 'Point Rate'})
      </Title>
      <div
        className={`${styles.tournamentDesign} ${
          bbType && bbType.value === numberTypeOp[1].value && styles.range
        }`}
      >
        <Select
          label="Input Type"
          options={numberTypeOp}
          onChange={(value) => {
            updateSD('bbType')(value)
            // reset bbMax if value is selected
            if (value.label === numberTypeOp[0].label) {
              updateSD('bbMaxValue')('')
            }
          }}
          value={bbType}
        />
        {bbType && getInputsOfType(bbType, bbMinValue, bbMaxValue, updateSD)}
      </div>
      {shouldShowBBTable() && (
        <>
          <Divider placement={'left'}>For Card Scoring</Divider>
          <div className={styles.bbHeading}>
            <Title level={3}>
              <Bold>Big Blind</Bold> Range {getBBRange()}
            </Title>
            <Button
              type={'text'}
              onClick={() => {
                const newSS = [
                  ...scoringSystem,
                  { bb: bbMinValue, multiplier: 1 },
                ]
                updateSD('scoringSystem')(newSS)
              }}
            >
              + Add BB
            </Button>
          </div>
          {!!scoringSystem.length && getBBTable()}
        </>
      )}
    </Card>
  )
}

export default TournamentDesign
