import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styles from './CreateProperty.module.scss'
import {
  SelectAudience,
  SelectAudiencePreview,
} from 'Containers/CreateLeaderboard/SelectAudience'
import { Schedule, SchedulePreview } from './Schedule'
import { navigate } from '@reach/router'
import { Bold, Button, Card, Footer, Stepper, Text, Title } from 'salt'
import { Spawning, SpawningPreview } from './Spawning'
import CheckGreen from 'Images/CheckGreen.svg'
import RocketBlue from 'Images/RocketBlue.svg'
import RocketGrey from 'Images/RocketGrey.svg'
import CalendarBlue from 'Images/CalendarBlue.svg'
import CalendarGrey from 'Images/CalendarGrey.svg'
import AudienceBlue from 'Images/AudienceBlue.svg'
import AudienceGrey from 'Images/AudienceGrey.svg'
import { validateSpawningStep } from 'Validations/Property/Spawning'
import { validateSchedule } from 'Validations/Property/Schedule'
import { validateAudience } from 'Validations/Audience'
import { createProperty, uploadImage } from '../api'
import { hours, minutes } from 'Containers/CreateLeaderboard/constants'

const steps = [
  {
    title: 'Spawning',
    icons: {
      incomplete: RocketGrey,
      current: RocketBlue,
      complete: CheckGreen,
    },
  },
  {
    title: 'Schedule',
    icons: {
      incomplete: CalendarGrey,
      current: CalendarBlue,
      complete: CheckGreen,
    },
  },
  {
    title: 'Select Audience',
    icons: {
      incomplete: AudienceGrey,
      current: AudienceBlue,
      complete: CheckGreen,
    },
  },
]

const CreateProperty = () => {
  const { idToken } = useSelector((state) => state.auth)
  const [audience, setAudience] = useState([])
  const [playerIds, setPlayerIds] = useState([])
  const [schedule, setSchedule] = useState({
    visibleTime: new Date(),
    visibleTimeHour: hours[0],
    visibleTimeMinute: minutes[0],
    startDate: new Date(),
    startHour: hours[1],
    startMinute: minutes[0],
    endTime: new Date(),
    endHour: hours[2],
    endMinute: minutes[2],
  })

  const [step, setStep] = useState(0)
  const [stepInPreview, setStepInPreview] = useState(false)

  const [scheduled, setScheduled] = useState(false)
  const [scheduling, setScheduling] = useState(false)
  const [message, setMessage] = useState({ type: 'success', value: '' })
  const [sD, setSD] = useState({})
  const [csvValidation, setCSVValidation] = useState({
    passed: true,
    error: '',
  })

  const audienceValidation = validateAudience(audience, playerIds)
  const sdValidation = validateSpawningStep(sD)
  const schedulerValidation = validateSchedule(schedule)

  const updateSD = (key) => (value) => {
    setSD({
      ...sD,
      [key]: value,
    })
  }

  const updateSchedule = (key) => (value) => {
    setSchedule({
      ...schedule,
      [key]: value,
    })
  }

  const shouldDisableShowPreview = (step) => {
    switch (step) {
      case 0:
        return !sdValidation.passed
      case 1:
        return !sdValidation.passed || !schedulerValidation.passed
      default:
        return (
          !sdValidation.passed ||
          !schedulerValidation.passed ||
          !audienceValidation.passed
        )
    }
  }

  const getValidationErr = (step) => {
    const getError = (...validations) => {
      for (const v of validations) {
        if (!!v.error) {
          return v.error
        }
      }

      return ''
    }

    switch (step) {
      case 0:
        return getError(sdValidation)
      case 1:
        return getError(sdValidation, schedulerValidation)
      default:
        return getError(sdValidation, schedulerValidation, audienceValidation)
    }
  }

  const scheduleProperty = async () => {
    if (!scheduled) {
      setScheduling(true)
      if (!sD.largeImage && sD.largeImage.length === 0) {
        setMessage({ type: 'danger', value: 'Banner Large Image is required' })
        return
      }

      if (!sD.smallImage && sD.smallImage.length === 0) {
        setMessage({ type: 'danger', value: 'Banner Small Image is required' })
        return
      }

      const p1 = uploadImage(sD, sD.largeImage)
      const p2 = uploadImage(sD, sD.smallImage)
      let resp
      try {
        resp = await Promise.all([p1, p2])
      } catch (error) {
        setMessage({ type: 'danger', value: 'Error uploading image' })
        setScheduling(false)
        return
      }

      const { propertyId, msg } = await createProperty({
        spawningDetails: {
          ...sD,
          largeImageUrl: resp[0],
          smallImageUrl: resp[1],
        },
        audience,
        schedule,
        playerIds,
        accessToken: idToken || localStorage.getItem('accessToken'),
      })
      setScheduling(false)

      if (propertyId) {
        setScheduled(true)
        setMessage({
          type: 'success',
          value: `Successfully created Property: ${propertyId}`,
        })
      }

      if (msg) {
        setMessage({ type: 'danger', value: msg })
      }
    } else {
      navigate('/properties/unpublished')
    }
  }

  return (
    <>
      <div id="CreateProperty">
        <Card>
          <Title>
            Create A <Bold>Property</Bold>
          </Title>
          <Stepper
            numberOfSteps={3}
            currentStep={step + 1}
            stepsData={steps}
            containerCustomClasses={[styles.stepper]}
          />
        </Card>

        {step === 0 &&
          (stepInPreview ? (
            <SpawningPreview sD={sD} />
          ) : (
            <Spawning updateSD={updateSD} sD={sD} />
          ))}

        {step === 1 &&
          (stepInPreview ? (
            <SchedulePreview schedule={schedule} />
          ) : (
            <Schedule schedule={schedule} updateSchedule={updateSchedule} />
          ))}

        {step === 2 &&
          (stepInPreview ? (
            <SelectAudiencePreview schedule={schedule} audience={audience} />
          ) : (
            <SelectAudience
              updateAudience={(aud) => {
                setAudience(aud)
              }}
              updatePlayerIds={(playerIds) => {
                setPlayerIds(playerIds)
              }}
              setCSVValidation={setCSVValidation}
              audience={audience}
            />
          ))}

        {step === 3 && (
          <>
            <SpawningPreview sD={sD} />
            <SchedulePreview schedule={schedule} />
            <SelectAudiencePreview schedule={schedule} audience={audience} />
          </>
        )}
      </div>
      {step !== 4 && (
        <Footer customClasses={[styles.footer]}>
          <div className={styles.message}>
            {message?.value && (
              <Text type={message.type}>
                <Bold>{message.value}</Bold>
              </Text>
            )}
            <Text type={'danger'}>
              <Bold>{getValidationErr(step)}</Bold>
            </Text>
          </div>

          {!stepInPreview && (
            <>
              {step !== 0 && !scheduled && (
                <Button
                  type={'secondary'}
                  onClick={() => {
                    setStep(step - 1)
                  }}
                >
                  Back
                </Button>
              )}
              {step !== 3 && (
                <Button
                  onClick={() => setStepInPreview(true)}
                  disabled={shouldDisableShowPreview(step)}
                >
                  Show Preview
                </Button>
              )}
              {step === 3 && (
                <Button onClick={scheduleProperty} disabled={scheduling}>
                  {!scheduled ? 'Create Property' : 'See Calendar'}
                </Button>
              )}
            </>
          )}

          {stepInPreview && (
            <>
              <Button
                type={'secondary'}
                onClick={() => setStepInPreview(false)}
              >
                Back
              </Button>
              <Button
                onClick={() => {
                  setStep(step + 1)
                  setStepInPreview(false)
                }}
              >
                Next
              </Button>
            </>
          )}
        </Footer>
      )}
    </>
  )
}

export default CreateProperty
