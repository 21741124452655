import React from 'react'
import styles from './GameDetails.module.scss'
import { Bold, Card, Title } from 'salt'
import { InputPreview } from '../../../../Components/InputPreview'

const GameDetailsPreview = ({ gameName, gameCategory, isPartOfSummary }) => {
  return (
    <Card
      removeLeftMargin={isPartOfSummary}
      removeRightMargin={isPartOfSummary}
    >
      <Title level={3}>
        <Bold>Game</Bold> Details
      </Title>
      <div className={styles.gameDetailsPreview}>
        <InputPreview label={'Category'} value={gameCategory} />
        <InputPreview label={'Name'} value={gameName} />
      </div>
    </Card>
  )
}

export default GameDetailsPreview
