import React, { useState } from 'react'
import PrizeDetails from '../CreateLeaderboard/SpawningStep/PrizeDetails/PrizeDetails'
import createStyles from '../CreateLeaderboard/CreateLeaderboardScreen.module.scss'
import lbDetailsStyles from '../LeaderboardDetails/LeaderboardDetails.module.scss'
import namingDetailsStyles from '../CreateLeaderboard/SpawningStep/NamingDetails/NamingDetails.module.scss'
import tournamentDesignStyles from '../CreateLeaderboard/SpawningStep/TournamentDesign/TournamentDesign.module.scss'
import { gameNameOp, numberTypeOp } from '../CreateLeaderboard/constants'
import { validateEditStep } from 'Validations/EditLeaderboard'
import { editLeaderboard } from '../Schedule/api'
import { getStateTag } from '../Schedule/helper'
import {
  Bold,
  Breadcrumbs,
  Button,
  Card,
  Footer,
  Input,
  Select,
  Text,
  Title,
} from 'salt'
import { navigate } from '@reach/router'
import LeaderboardSummary from '../LeaderboardDetails/LeaderboardSummary'
import { getInputsOfType } from '../CreateLeaderboard/SpawningStep/TournamentDesign/TournamentDesign'

const styles = {
  ...createStyles,
  ...lbDetailsStyles,
  ...namingDetailsStyles,
  ...tournamentDesignStyles,
}

const EditLeaderboard = (props) => {
  const SPLIT_UPPER_CAMEL_CASE_REGEX = /([a-z])([A-Z])/g
  const sD =
    props.location.state && props.location.state.sD
      ? props.location.state.sD
      : null
  if (!sD) {
    window.history.back()
  }
  const lbState = props.location.state.lbState
  const audience = props.location.state.audience
  const lbId = props.location.state.lbId
  const [editDetails, setEditDetails] = useState(sD)
  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [showSummary, setShowSummary] = useState(false)

  let {
    internalName,
    externalName,
    description,
    lbCategory,
    lbType,
    gameName,
    bbType,
    bbMinValue,
    bbMaxValue,
    totalPrize,
    prizes,
    goalBasedPrizes,
    percentOfWinningPlayers,
    rakePercentage,
    hasNonCashPrizes
  } = editDetails

  const breadcrumbs = [
    {
      text: 'Created Leaderboards',
      onClick: () => {
        navigate('/schedule')
      },
    },
    {
      text: 'Leaderboard Details',
      onClick: () => {
        window.history.back()
      },
    },
    {
      text: 'Edit Leaderboard',
    },
  ]

  lbType = {
    label: lbType.label.replace(SPLIT_UPPER_CAMEL_CASE_REGEX, '$1 $2'),
  }
  const updateDetails = (key) => (value) => {
    setEditDetails((details) => {
      return { ...details, [key]: value }
    })
    setDirty(true)
  }

  const editLb = async () => {
    setLoading(true)
    let resp = await editLeaderboard(editDetails, lbId)
    setLoading(false)
    if (!resp.leaderboardId) {
      window.alert(resp.msg)
    } else {
      window.history.back()
    }
  }

  const sDvalidation = validateEditStep(editDetails)

  return (
    <>
      {!loading && (
        <>
          <Card customClasses={[styles.header]}>
            <div className={styles.heading}>
              {getStateTag(lbState)}
              <Title level={1}>
                <Bold>{externalName || ''}</Bold>
              </Title>
            </div>
            <div className={styles.sub}>
              <Breadcrumbs breadcrumbs={breadcrumbs} />
              <div className={styles.actions}>
                <Button type={'text'} onClick={() => setShowSummary(true)}>
                  <Bold>Leaderboard Summary</Bold>
                </Button>
              </div>
            </div>
          </Card>

          <Card>
            <Title level={3}>
              <Bold>Naming</Bold> Details
            </Title>
            <div className={styles.namingDetails}>
              <Input
                label={'Internal Name'}
                placeholder={'PR511'}
                value={internalName}
                disabled
                containerCustomClasses={[styles.internalName]}
              />
              <Input
                label={'External Name'}
                placeholder={'Win Rs. 30,000 Poker Daily'}
                value={externalName}
                onChange={updateDetails('externalName')}
                disabled={lbState === 'Live'}
                containerCustomClasses={[styles.externalName]}
              />
              <Input
                label={'Description'}
                placeholder={'eg: this is a poker leaderboard'}
                value={description}
                onChange={updateDetails('description')}
                disabled={lbState === 'Live'}
                containerCustomClasses={[styles.description]}
              />
            </div>
          </Card>

          <Card>
            <Title level={3}>
              <Bold>Tournament</Bold> Design (
              {gameName.value === gameNameOp[0].value
                ? 'Big Blind'
                : 'Point Rate'}
              )
            </Title>
            <div
              className={`${styles.tournamentDesign} ${
                bbType && bbType.value === numberTypeOp[1].value && styles.range
              }`}
            >
              <Select
                label="Input Type"
                options={numberTypeOp}
                onChange={(value) => {
                  updateDetails('bbType')(value)
                  // reset bbMax if value is selected
                  if (value.label === numberTypeOp[0].label) {
                    updateDetails('bbMaxValue')('')
                  }
                }}
                value={bbType}
              />
              {bbType &&
                getInputsOfType(bbType, bbMinValue, bbMaxValue, updateDetails)}
            </div>
          </Card>

          <PrizeDetails
            updateSD={updateDetails}
            totalPrize={totalPrize}
            prizesForRankBasedLBs={prizes}
            prizesForGoalBasedLBs={goalBasedPrizes}
            lbCategory={lbCategory}
            percentOfWinningPlayers={percentOfWinningPlayers}
            rakePercentage={rakePercentage}
            lbType={lbType}
            hasNonCashPrizes={hasNonCashPrizes}
          />

          {editDetails && (
            <LeaderboardSummary
              spawningDetails={sD}
              audience={audience}
              show={showSummary}
              close={() => setShowSummary(false)}
            />
          )}

          <Footer customClasses={[styles.footer]}>
            <Text type={'danger'}>
              <Bold>{sDvalidation.error}</Bold>
            </Text>
            <Button
              filled
              onClick={editLb}
              disabled={!sDvalidation.passed || !dirty}
              error={sDvalidation.error}
            >
              Save Changes
            </Button>
          </Footer>
        </>
      )}
    </>
  )
}

export default EditLeaderboard
