import styles from './BannerDetails.module.scss'
import React from 'react'
import { Bold, Card, Title, Text } from 'salt'

const BannerDetailsPreview = ({ largeImage, smallImage }) => {
  return (
    <Card>
      <Title level={3}>
        <Bold>Banner</Bold> Details
      </Title>
      <div className={styles.namingDetails}>
        <div className={styles.bannerUpload}>
          <>
            <Text weight="small" customClasses={[styles.label]}>
              Large Image
            </Text>
            <div>
              <img src={URL.createObjectURL(largeImage)} alt="preview" style={{ width: 'auto', maxWidth: '100%', height: 'auto' }} />
            </div>
          </>
        </div>
        <div className={styles.bannerUpload}>
          <>
            <Text weight="small" customClasses={[styles.label]}>
              Small Image
            </Text>
            <div>
              <img src={URL.createObjectURL(smallImage)} alt="preview" style={{ width: 'auto', maxWidth: '100%', height: 'auto' }} />
            </div>
          </>
        </div>
      </div>
    </Card>
  )
}

export default BannerDetailsPreview
