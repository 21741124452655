import apisauce from 'apisauce'
// import { UrlEncode } from 'Lib/RamdaExtensions'
// import { merge } from 'ramda'

const PROD_URL = ''
// const LOCAL_URL = 'http://localhost:1339'

const CURRENT_URL = PROD_URL
const ContentType = 'application/json'

export default {
  create: (baseURL = process.env.API_URL) => {
    const accessToken = ''
    const api = apisauce.create({
      baseURL: CURRENT_URL,
      headers: {
        Origin: CURRENT_URL,
        'Content-Type': ContentType,
        Authorization: `Bearer ${accessToken}`,
      },
      timeout: 100000,
    })

    return {
      setAuthToken: (token) =>
        api.setHeader('Authorization', `Bearer ${token}`),
    }
  },
}
