import React from 'react'
import styles from './Statistic.module.scss'
import { Bold } from 'salt'

const Statistic = ({measure, measurement}) => {
  return (
    <div className={styles.statisticCard}>
      <div className={styles.measurement}>
        <Bold>{measurement}</Bold>
      </div>
      <div className={styles.measure}>
        {measure}
      </div>
    </div>
  )
}

export default Statistic