import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { navigate } from '@reach/router'
import moment from 'moment'
import { Bold, Button, Card, Table, Title, Link } from 'salt'
import styles from './ListUnPublishedProperties.module.scss'
import { fetchProperties } from '../api'
import { Loader } from '../../../Components/Loader'

const ListUnPublishedProperties = () => {
  const columns = useMemo(
    () => [
      {
        Header: 'External Name',
        accessor: 'externalName',
        filterable: true,
      },
      {
        Header: 'Templates',
        accessor: 'templateCount',
        maxWidth: 175,
      },
      {
        Header: 'Starting On',
        accessor: 'startTime',
        maxWidth: 225,
      },
      {
        Header: 'Ending On',
        accessor: 'endTime',
        maxWidth: 225,
      },
      {
        Header: 'Actions',
        accessor: 'actions',
        maxWidth: 175,
      },
    ],
    []
  )
  const [loading, setLoading] = useState(false)
  const [properties, setProperties] = useState([])

  const getActions = useCallback((p) => {
    return [
      <Link
        path={`/properties/unpublished/details/${p.id}`}
      >
        View
      </Link>,
    ]
  }, [])

  const getProperties = useCallback(async () => {
    setLoading(true)
    const { properties: prop } = await fetchProperties('Unpublished')
    setLoading(false)

    let properties = prop.map((p) => {
      return {
        externalName: p.external_name,
        startTime: moment(p.schedule.start_time).format('ll'),
        endTime: moment(p.schedule.end_time).format('ll'),
        actions: <div className={styles.actions}>{getActions(p)}</div>,
        templateCount: p.number_of_children || 0,
      }
    })

    setProperties(properties)
  }, [getActions])

  useEffect(() => {
    getProperties()
  }, [getProperties])

  return (
    <>
      {!loading ? (
        <>
          <Card customClasses={[styles.header]}>
            <Title>
              UnPublished <Bold>Properties</Bold>
            </Title>
            <Button onClick={() => navigate('/properties/create')}>
              <Bold>Create Property</Bold>
            </Button>
          </Card>
          <Card>
            <Title level={2} customClasses={[styles.title]}>
              <Bold>Properties</Bold> List
            </Title>
            <Table columns={columns} data={properties} />
          </Card>
        </>
      ) : (
        <Loader />
      )}
    </>
  )
}

export default ListUnPublishedProperties
