import React, { useCallback, useMemo, useState } from 'react'
import styles from './LeaderboardDetails.module.scss'
import { PhoneModal } from '../../Components/PhoneModal'
import { Button, Table, Tag, Link } from 'salt'
import PhoneIcon from '../../Images/PhoneBlue.svg'
import AudienceBlue from '../../Images/ProfileLink.svg'
import GreenCheck from 'Images/CheckGreen.svg'
import HourGlassIcon from 'Images/HourGlass.svg'

const GoalBasedLeaderboardDetails = ({
  leaderboardDetails,
  leaderboardEntries,
}) => {
  const { winning_score: maxScore, goal: finalGoal } = useMemo(
    () =>
      leaderboardDetails.prize_distribution.goal_based.reduce((a, b) =>
        a.winning_score > b.winning_score ? a : b
      ),
    [leaderboardDetails]
  )

  const calculateCurrentGoal = useCallback(
    (prizeDist, scoreString, state) => {
      let goal = 0
      const goal_based = [...prizeDist.goal_based]
      goal_based.sort(function (a, b) {
        return a.winning_score - b.winning_score
      })
      const score = Math.ceil(scoreString)
      for (let i = 0; i < goal_based.length; i++) {
        const g = goal_based[i]
        if (score < g.winning_score) {
          goal = Math.ceil(g.goal)
          break
        }
        if (i === goal_based.length - 1 && score >= g.winning_score) {
          goal = Math.ceil(g.goal) + 1
        }
      }
      if (goal === 0 && score > 0) {
        goal = 1
      }

      if (state === 'ResultDeclared') return `Goal ${goal - 1}`

      return goal - 1 === parseInt(finalGoal, 10)
        ? 'All completed'
        : `Goal ${goal}`
    },
    [finalGoal]
  )

  const columns = useMemo(() => {
    const areResultDeclared = leaderboardDetails.state === 'ResultDeclared'

    return [
      {
        Header: 'Name',
        accessor: 'name',
        filterable: true,
      },
      {
        Header: `${areResultDeclared ? 'Final ' : ''}Goal`,
        accessor: 'goal',
      },
      {
        Header: `${areResultDeclared ? 'Final ' : ''}Score`,
        accessor: 'score',
      },
      {
        Header: areResultDeclared ? 'Final Prize' : 'Status',
        accessor: areResultDeclared ? 'prize' : 'status',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
      },
    ]
  }, [leaderboardDetails])

  const data = useMemo(() => {
    return leaderboardEntries.map((le) => ({
      name: le.player.name,
      goal: calculateCurrentGoal(
        leaderboardDetails.prize_distribution,
        le.score,
        leaderboardDetails.state
      ),
      score: le.score,
      prize: `₹ ${(le.prize || 0).toLocaleString()}`,
      status:
        le.score >= maxScore ? (
          <Tag text={'COMPLETED'} type={'success'} icon={GreenCheck} />
        ) : (
          <Tag text={'ONGOING'} type={'primary'} icon={HourGlassIcon} />
        ),
      actions: (
        <div className={styles.actions}>
          <Button
            type={'text'}
            onClick={() => {
              setCurrentPlayer(le.player)
              setShowPhoneModal(true)
            }}
          >
            <Tag type={'primary'} icon={PhoneIcon} />
          </Button>
          <Link
            external
            newTab
            path={`https://console.getmega.com/watson/player-details/${le.player.player_id}`}
          >
            <Tag type={'primary'} icon={AudienceBlue} />
          </Link>
        </div>
      ),
    }))
  }, [leaderboardDetails, leaderboardEntries, calculateCurrentGoal, maxScore])

  const [currentPlayer, setCurrentPlayer] = useState({})
  const [showPhoneModal, setShowPhoneModal] = useState(false)

  return (
    <>
      <Table
        columns={columns}
        data={data}
        noDataText={'No players in the leaderboard'}
      />
      {showPhoneModal && (
        <PhoneModal
          close={() => setShowPhoneModal(false)}
          currentPlayer={currentPlayer}
        />
      )}
    </>
  )
}

export default GoalBasedLeaderboardDetails
