import React, { useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import moment from 'moment'
import styles from './Scheduler.module.scss'
import { hours, minutes } from '../constants'
import {
  Alert,
  Bold,
  Card,
  Checkbox,
  DatePicker,
  Input,
  Select,
  Text,
  Title,
} from 'salt'

const frequencyOptions = [
  { value: 'WEEKLY', label: 'Week' },
  { value: 'MONTHLY', label: 'Month' },
]
const recurrenceOptions = [
  {
    label: 'Custom',
    value: 'Custom',
  },
  {
    label: 'Every Day',
    value: 'Daily',
  },
  {
    label: 'Every Week',
    value: 'Weekly',
  },
]

const tomorrow = moment().add(1, 'days')

const initialValues = {
  startDate: new Date(tomorrow.format()),
  startHour: hours[0],
  startMinute: minutes[0],
  allDay: false,
  endDate: new Date(tomorrow.format()),
  endHour: hours[hours.length - 1],
  endMinute: minutes[minutes.length - 1],
  recurrence: recurrenceOptions[0],
  interval: 1,
  freq: frequencyOptions[1],
  count: 10,
  ends: new Date(tomorrow.format()),
  byweekday: [],
}

const Scheduler = ({ updateRule }) => {
  const [selectedDays, setSelectedDays] = useState(Array(7).fill(false))

  useEffect(() => {
    updateRule(initialValues)
  }, [updateRule])

  const getFormattedTime = (hour, min) => {
    return `${hour.substr(0, 2)}:${min}${hour.slice(-3)}`
  }

  return (
    <>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <Card>
              <Title level={3}>
                <Bold>Leaderboard</Bold> Duration
              </Title>
              <div className={styles.lbDuration}>
                <div className={styles.inputs}>
                  <DatePicker
                    label={'Start Date'}
                    selected={formikProps.values.startDate}
                    dateFormat="MMMM d, yyyy"
                    onChange={(date) => {
                      formikProps.setFieldValue('startDate', date)
                      updateRule({ ...formikProps.values, startDate: date })
                    }}
                    value={formikProps.values.startDate}
                    minDate={new Date().setHours(0, 0, 0, 0)}
                  />
                  <Select
                    label={'Hour'}
                    options={hours}
                    onChange={(option) => {
                      formikProps.setFieldValue('startHour', option)
                      updateRule({ ...formikProps.values, startHour: option })
                    }}
                    value={formikProps.values.startHour}
                  />
                  <Select
                    label={'Minutes'}
                    options={minutes}
                    onChange={(option) => {
                      formikProps.setFieldValue('startMinute', option)
                      updateRule({ ...formikProps.values, startMinute: option })
                    }}
                    value={formikProps.values.startMinute}
                  />
                  {!formikProps.values.allDay && (
                    <Text customClasses={[styles.to]}>
                      <Bold>to</Bold>
                    </Text>
                  )}
                </div>

                {!formikProps.values.allDay && (
                  <div className={styles.inputs}>
                    <DatePicker
                      label={'End Date'}
                      selected={formikProps.values.endDate}
                      dateFormat="MMMM d, yyyy"
                      onChange={(date) => {
                        formikProps.setFieldValue('endDate', date)
                        updateRule({ ...formikProps.values, endDate: date })
                      }}
                      disabled={formikProps.values.allDay}
                      minDate={new Date().setHours(0, 0, 0, 0)}
                    />
                    <Select
                      label={'Hour'}
                      options={hours}
                      onChange={(option) => {
                        formikProps.setFieldValue('endHour', option)
                        updateRule({ ...formikProps.values, endHour: option })
                      }}
                      value={formikProps.values.endHour}
                      disabled={formikProps.values.allDay}
                    />
                    <Select
                      label={'Minutes'}
                      options={minutes}
                      onChange={(option) => {
                        formikProps.setFieldValue('endMinute', option)
                        updateRule({
                          ...formikProps.values,
                          endMinute: option,
                        })
                      }}
                      value={formikProps.values.endMinute}
                      disabled={formikProps.values.allDay}
                    />
                  </div>
                )}
              </div>
              <Checkbox
                label={'All Day'}
                checked={formikProps.values.allDay}
                onChange={(e) => {
                  formikProps.setFieldValue('allDay', e.currentTarget.checked)

                  if (e.currentTarget.checked) {
                    formikProps.setFieldValue(
                      'startHour',
                      initialValues.startHour
                    )
                    formikProps.setFieldValue(
                      'startMinute',
                      initialValues.startMinute
                    )
                    formikProps.setFieldValue(
                      'endDate',
                      formikProps.values.startDate
                    )
                    formikProps.setFieldValue('endHour', initialValues.endHour)
                    formikProps.setFieldValue(
                      'endMinute',
                      initialValues.endMinute
                    )

                    const {
                      startHour,
                      startMinute,
                      endHour,
                      endMinute,
                    } = initialValues
                    updateRule({
                      ...formikProps.values,
                      startHour,
                      startMinute,
                      endDate: formikProps.values.startDate,
                      endHour,
                      endMinute,
                    })
                  }
                }}
              />
              <Alert type={'primary'} customClasses={[styles.message]}>
                This leaderboard will take place from{' '}
                {moment(formikProps.values.startDate).format('DD-MM-YYYY')}{' '}
                {getFormattedTime(
                  formikProps.values.startHour.label,
                  formikProps.values.startMinute.label
                )}{' '}
                until {moment(formikProps.values.endDate).format('DD-MM-YYYY')}{' '}
                {getFormattedTime(
                  formikProps.values.endHour.label,
                  formikProps.values.endMinute.label
                )}
              </Alert>
            </Card>
            <Card>
              <Title level={3}>
                <Bold>Recurrence</Bold>
              </Title>
              <div className={styles.lbRecurrence}>
                <Select
                  options={recurrenceOptions}
                  label="When"
                  value={formikProps.values.recurrence}
                  containerCustomClasses={[styles.when]}
                  onChange={(option) => {
                    formikProps.setFieldValue('recurrence', option)

                    let d

                    if (option.value === 'Daily') {
                      d = Array(7).fill(true)
                      setSelectedDays(d)
                    }

                    if (option.value === 'Weekly') {
                      d = Array(7).fill(false)
                      d[formikProps.values.startDate.getDay()] = true
                      setSelectedDays(d)
                    }

                    if (option.value !== 'Custom') {
                      const days = moment.weekdays()
                      let byWeekDay = []
                      d.forEach((sd, i) => {
                        if (sd)
                          byWeekDay.push(days[i].substr(0, 2).toUpperCase())
                      })
                      formikProps.setFieldValue('byweekday', byWeekDay)

                      updateRule({
                        ...formikProps.values,
                        byweekday: byWeekDay,
                        recurrence: option,
                      })
                    }
                  }}
                />
                <div className={styles.repeatOn}>
                  <Text weight="small">Repeat On</Text>
                  <div className={styles.days}>
                    {moment.weekdays().map((d, i) => {
                      return (
                        <div
                          key={d}
                          className={`${styles.day} ${
                            selectedDays[i] && styles.selected
                          }`}
                          onClick={() => {
                            if (
                              formikProps.values.recurrence.value !==
                              recurrenceOptions[0].value
                            )
                              return

                            setSelectedDays([
                              ...selectedDays.slice(0, i),
                              !selectedDays[i],
                              ...selectedDays.slice(i + 1),
                            ])
                            const days = moment.weekdays()
                            let byWeekDay = []
                            if (!selectedDays[i])
                              byWeekDay.push(days[i].substr(0, 2).toUpperCase())
                            selectedDays.forEach((sd, j) => {
                              if (sd && j !== i) {
                                byWeekDay.push(
                                  days[j].substr(0, 2).toUpperCase()
                                )
                              }
                            })
                            formikProps.setFieldValue('byweekday', byWeekDay)

                            updateRule({
                              ...formikProps.values,
                              byweekday: byWeekDay,
                            })
                          }}
                        >
                          <Text>
                            <Bold type={'medium'}>{d[0]}</Bold>
                          </Text>
                        </div>
                      )
                    })}
                  </div>
                </div>
                {formikProps.values.recurrence.value ===
                  recurrenceOptions[0].value && (
                  <div className={styles.repeatEvery}>
                    <Input
                      type="number"
                      value={formikProps.values.interval}
                      label="Repeat Every"
                      onChange={(value) => {
                        formikProps.setFieldValue('interval', value)
                        updateRule({
                          ...formikProps.values,
                          interval: value || 1,
                        })
                      }}
                      min={1}
                    />
                    <Select
                      options={frequencyOptions}
                      label="  "
                      value={formikProps.values.freq}
                      onChange={(option) => {
                        formikProps.setFieldValue('freq', option)
                        updateRule({ ...formikProps.values, freq: option })
                      }}
                    />
                  </div>
                )}
                <div className={styles.endsInputs}>
                  <DatePicker
                    label={'Ending On'}
                    selected={formikProps.values.ends}
                    dateFormat="MMMM d, yyyy"
                    onChange={(date) => {
                      formikProps.setFieldValue('ends', date)
                      updateRule({ ...formikProps.values, ends: date })
                    }}
                  />
                  <Input
                    type="number"
                    label="Times"
                    value={formikProps.values.count}
                    onChange={(value) => {
                      formikProps.setFieldValue('count', value)
                      updateRule({ ...formikProps.values, count: value })
                    }}
                  />
                </div>
              </div>
            </Card>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default Scheduler
