import RRule from 'rrule'
import moment from 'moment'

const TZ_DIFF = 19800000 // 5.5hrs in milliseconds

const getWeekday = (bw, startHour, startMinute) => {
  if (
    startHour.value >= 6 ||
    (startHour.value === 5 && startMinute.value >= 30)
  )
    return bw

  const weekdays = moment.weekdays()
  let index
  weekdays.forEach((wd, i) => {
    if (wd.substr(0, 2).toUpperCase() === bw) {
      index = i
    }
  })

  return weekdays[(index - 1 + 7) % 7].substr(0, 2).toUpperCase()
}

export const getUTCTime = (startDate, startHour, startMinute) =>
  new Date(
    Date.UTC(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate(),
      startHour.value,
      startMinute.value,
      0
    ) - TZ_DIFF
  )

export const getUntil = (ends) =>
  new Date(
    Date.UTC(ends.getFullYear(), ends.getMonth(), ends.getDate(), 23, 59, 59) -
      TZ_DIFF
  )

export const getStartOfWeek = () => {
  const sow = moment().startOf('week') // this is sunday
  return new Date(
    Date.UTC(sow.year(), sow.month(), sow.date() + 1, 0, 0, 0) - TZ_DIFF
  )
}

export const toISTFormattedTime = (time) => {
  return moment(time).format('Do MMM YYYY | hh:mm a')
}

export default function convertToRRule(schedule) {
  const {
    startDate,
    startHour,
    startMinute,
    freq,
    count,
    ends,
    interval,
    byweekday,
  } = schedule

  const r = new RRule({
    dtstart: getUTCTime(startDate, startHour, startMinute),
    until: getUntil(ends),
    count,
    interval,
    freq: RRule[freq.value],
    byweekday: byweekday.map(
      (bw) => RRule[getWeekday(bw, startHour, startMinute)]
    ),
  })

  return r
}
