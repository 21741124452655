import { validatePrizeDistribution } from './PrizeDistribution'

export const validateEditStep = (details) => {
  const {
    internalName,
    externalName,
    description,
    lbCategory,
    bbMinValue,
    bbMaxValue,
    totalPrize,
    prizes,
    goalBasedPrizes,
    hasNonCashPrizes
  } = details

  let validationPassed = true
  let validationErr = ''

  if (!externalName) {
    validationPassed = false
    validationErr = 'Please fill External Name'
    return returnPayload(validationPassed, validationErr)
  }

  if (!internalName) {
    validationPassed = false
    validationErr = 'Please fill Internal Name'
    return returnPayload(validationPassed, validationErr)
  }

  if (!description) {
    validationPassed = false
    validationErr = 'Please fill Description'
    return returnPayload(validationPassed, validationErr)
  }

  if (!bbMinValue) {
    validationPassed = false
    validationErr = 'Please fill Tournament Design'
    return returnPayload(validationPassed, validationErr)
  }

  if (!!bbMaxValue && bbMaxValue < bbMinValue) {
    validationPassed = false
    validationErr = 'Tournament Design max value should be greater than min'
    return returnPayload(validationPassed, validationErr)
  }

  if (lbCategory && lbCategory.value !== 'paid') {
    const prizeDistValidation = validatePrizeDistribution(
      totalPrize,
      prizes,
      goalBasedPrizes,
      hasNonCashPrizes
    )
    if (!prizeDistValidation.passed) {
      return prizeDistValidation
    }
  }

  return returnPayload(validationPassed, validationErr)
}

const returnPayload = (validationPassed, validationErr) => {
  return {
    passed: validationPassed,
    error: validationErr,
  }
}
