export const generateEditLeaderboardBody = (sd, leaderboardId) => {
  let prize_distribution = {}

  if (sd.lbType.label === 'Rank Based') {
    prize_distribution = {
      type: 'RankBased',
      rank_based: sd.prizes.map((p) => ({
        start_rank: p.startRank,
        end_rank: p.endRank || p.startRank,
        prize: {
          winning_amount: p.prizePerPlayer,
          display_value: p.displayValue,
          wallet_type: p.wallet_type,
        },
        qualification_score: p.minScore,
      })),
    }
  }

  if (sd.lbType.label === 'Goal Based') {
    prize_distribution = {
      type: 'GoalBased',
      goal_based: sd.goalBasedPrizes.map((g) => ({
        goal: g.goal,
        goal_name: g.goal_name,
        prize: {
          winning_amount: g.winning_amount,
          wallet_type: g.wallet_type,
        },
        winning_score: g.winning_score,
      })),
    }
  }

  const body = {
    external_name: sd.externalName,
    internal_name: sd.internalName,
    description: sd.description,
    ...(sd.lbCategory.label === 'Free' && {
      total_prize_amount: parseInt(sd.totalPrize, 10),
    }),
    metric_filter: {
      metric_field: sd.gameName.label === 'Poker' ? 'Bigblind' : 'PointRate',
      field_type: 'NUMBER',
      value_range: {
        number_min: parseFloat(sd.bbMinValue),
        number_max: parseFloat(sd.bbMaxValue) || parseFloat(sd.bbMinValue),
        has_min: true,
        has_max: true,
      },
    },
    prize_distribution: prize_distribution,
    leaderboard_id: leaderboardId,
  }

  return body
}
