import React, { useMemo, useState } from 'react'
import styles from './LeaderboardDetails.module.scss'
import { PhoneModal } from '../../Components/PhoneModal'
import { Button, Table, Tag, Link } from 'salt'
import PhoneIcon from '../../Images/PhoneBlue.svg'
import AudienceBlue from '../../Images/ProfileLink.svg'

const RankBasedLeaderboardDetails = ({
  leaderboardDetails,
  leaderboardEntries,
}) => {
  const columns = useMemo(() => {
    const cols = [
      {
        Header: 'Rank',
        accessor: 'rank',
      },
      {
        Header: 'Name',
        accessor: 'name',
        filterable: true,
      },
      {
        Header: 'Score',
        accessor: 'score',
      },
      {
        Header: 'Prize',
        accessor: 'prize',
      },
      {
        Header: 'Actions',
        accessor: 'actions',
      },
    ]

    if (leaderboardDetails.state !== 'ResultDeclared') cols.splice(3, 1)

    return cols
  }, [leaderboardDetails])

  const data = useMemo(() => {
    return leaderboardEntries.map((le) => ({
      rank: le.rank,
      name: le.player.name,
      score: le.score,
      prize: le.prize_display_value || `₹ ${(le.prize || 0).toLocaleString()}`,
      actions: (
        <div className={styles.actions}>
          <Button
            type={'text'}
            onClick={() => {
              setCurrentPlayer(le.player)
              setShowPhoneModal(true)
            }}
          >
            <Tag type={'primary'} icon={PhoneIcon} />
          </Button>
          <Link
            external
            newTab
            path={`https://console.getmega.com/watson/player-details/${le.player.player_id}`}
          >
            <Tag type={'primary'} icon={AudienceBlue} />
          </Link>
        </div>
      ),
    }))
  }, [leaderboardEntries])

  const [currentPlayer, setCurrentPlayer] = useState({})
  const [showPhoneModal, setShowPhoneModal] = useState(false)

  return (
    <>
      <Table
        columns={columns}
        data={data}
        noDataText={'No players in the leaderboard'}
      />
      {showPhoneModal && (
        <PhoneModal
          close={() => setShowPhoneModal(false)}
          currentPlayer={currentPlayer}
        />
      )}
    </>
  )
}

export default RankBasedLeaderboardDetails
