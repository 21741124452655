import styles from './LeaderboardDetails.module.scss'
import {
  formatTypeOp,
  gameCategoryOp,
  gameNameOp,
  lbCategoryOp,
  lbTypeOp,
  systemEvents,
} from '../../constants'
import React, { useEffect, useState } from 'react'
import {
  Alert,
  Bold,
  Card,
  Checkbox,
  Divider,
  Dropzone,
  Input,
  Select,
  Text,
  Title,
} from 'salt'

const LeaderboardDetails = ({
  updateSD,
  formatType,
  lbCategory,
  lbType,
  optIn,
  systemEvent,
  leaderboardBannerImage,
  entryFee,
  discount,
  slots,
  discountSlots,
  seedPlayers,
  benefitingAncestorGenerations,
  degreeMultiplier,
  isReferral,
  lateRegistrationAllowed,
  lateRegistrationDurationHour,
  lateRegistrationDurationMin,
  hasNonCashPrizes,
  leaderboardBanners,
}) => {
  const [banners, setBanners] = useState([])
  useEffect(() => {
    setBanners(leaderboardBanners || [])
  })

  return (
    <Card>
      <Title level={3}>
        <Bold>Leaderboard</Bold> Details
      </Title>

      <div className={styles.checkboxes}>
        <Checkbox
          label={'Non Cash Prizes'}
          checked={hasNonCashPrizes}
          onChange={() => {
            updateSD('hasNonCashPrizes')(!hasNonCashPrizes)
            if (!hasNonCashPrizes) {
              updateSD('lbCategory')(lbCategoryOp[0])
              updateSD('lbType')(lbTypeOp[0])
            }
          }}
        />
        <Checkbox
          checked={isReferral}
          onChange={() => {
            if (isReferral) {
              updateSD('benefitingAncestorGenerations')(0)
              updateSD('degreeMultiplier')([])
            } else {
              updateSD('benefitingAncestorGenerations')(1)
              updateSD('degreeMultiplier')([
                { name: 'Degree 1', multiplier: 1 },
              ])
            }
            updateSD('isReferral')(!isReferral)

            // if its referral, set values
            if (!isReferral) {
              updateSD('formatType')(formatTypeOp[0])
              updateSD('lbCategory')(lbCategoryOp[0])
              updateSD('lbType')(lbTypeOp[0])
              updateSD('systemEvent')(systemEvents[0])
              updateSD('optIn')(false)
              updateSD('lateRegistrationAllowed')(false)
            }
          }}
          label={'Referral Leaderboard'}
        />
      </div>

      <div className={styles.leaderboardDetails}>
        <Select
          label="Category"
          options={lbCategoryOp}
          onChange={updateSD('lbCategory')}
          value={lbCategory}
          disabled={isReferral || hasNonCashPrizes}
        />
        <Select
          label="Leaderboard Type"
          options={lbTypeOp}
          onChange={updateSD('lbType')}
          value={lbType}
          disabled={isReferral || hasNonCashPrizes}
        />
        <Select
          label="Format Type"
          options={formatTypeOp}
          onChange={updateSD('formatType')}
          value={formatType}
          disabled={isReferral}
        />
        <Select
          label="Metric"
          options={systemEvents}
          onChange={(option) => {
            updateSD('systemEvent')(option)

            if (option.value === systemEvents[1].value) {
              // if it is a poker hand
              updateSD('gameCategory')(gameCategoryOp[0])
              updateSD('gameName')(gameNameOp[0])
            }
          }}
          value={systemEvent}
          disabled={isReferral}
        />
      </div>

      <div className={styles.checkboxes}>
        <Checkbox
          checked={optIn && !(isReferral || lbCategory.label === 'Paid')}
          onChange={() => {
            updateSD('optIn')(!optIn)
          }}
          label={'Opt-In Leaderboard'}
          disabled={isReferral || lbCategory.label === 'Paid'}
        />
        <Checkbox
          id="lateRegistrationAllowed"
          checked={lateRegistrationAllowed}
          onChange={() => {
            updateSD('lateRegistrationAllowed')(!lateRegistrationAllowed)
          }}
          label={'Late Registration Allowed'}
          disabled={isReferral || !(lbCategory.label === 'Paid' || optIn)}
        />
      </div>
      {hasNonCashPrizes && (
        <>
          <Divider placement={'left'}>
            For Leaderboards With Non Cash Prizes
          </Divider>
          <Title level={3}>
            <Bold>Leaderboard Details Banners</Bold> Upload
          </Title>
          <Text weight={'small'} customClasses={[styles.nonCashBanners]}>
            <Bold>Upload</Bold> multiple images and reorder them by dragging
          </Text>
          <Dropzone
            files={banners || []}
            allowMultiple={true}
            allowReorder={true}
            onUpdateFiles={(fileItems) => {
              if (leaderboardBanners.length === fileItems.length) return
              updateSD('leaderboardBanners')(fileItems.map((fi) => fi.file))
            }}
            onReorderFiles={(fileItems, origin, target) => {
              updateSD('leaderboardBanners')(fileItems.map((fi) => fi.file))
            }}
            label='Drop images or <span class="filepond--label-action">Browse</span>'
            acceptedFileTypes={[
              'image/jpeg',
              'image/png',
              'image/jpg',
              'image/webp',
            ]}
            args={{ styleItemPanelAspectRatio: 0.428 }}
            customClasses={[styles.bannerUpload]}
          />
        </>
      )}

      {lbCategory.label === 'Paid' && (
        <>
          <Divider placement={'left'}>For Paid Leaderboards</Divider>
          <Title level={3}>
            <Bold>Entry Fee</Bold> Details
          </Title>
          <div className={styles.entryFeeDetails}>
            <div className={styles.entryFeeInputs}>
              <Input
                label="Entry Fee"
                type="number"
                placeholder="eg: 500"
                onChange={updateSD('entryFee')}
                value={entryFee}
                min={0}
                containerCustomClasses={[styles.entryFee]}
              />
              <Input
                label="Early Bird Discount Amount"
                type="number"
                placeholder="eg: 100"
                onChange={updateSD('discount')}
                value={discount}
                max={entryFee || 0}
                min={0}
                containerCustomClasses={[styles.earlyBirdDiscount]}
              />
              <Input
                label="Slot Limit"
                type="number"
                placeholder="eg: 1000"
                onChange={updateSD('slots')}
                value={slots}
                min={0}
                containerCustomClasses={[styles.slotLimit]}
              />
              <Input
                label="Discount Available For Players"
                type="number"
                placeholder="eg: 20"
                onChange={updateSD('discountSlots')}
                value={discountSlots}
                max={slots || 1}
                min={0}
                containerCustomClasses={[styles.discount]}
              />
              <Input
                label="Seed Players"
                type="number"
                placeholder="eg: 10"
                onChange={updateSD('seedPlayers')}
                value={seedPlayers}
                min={0}
                containerCustomClasses={[styles.seedPlayers]}
              />
            </div>
            {entryFee && !!discount && slots && discountSlots ? (
              <Alert type={'info'}>
                Early bird entry is{' '}
                <Bold>
                  ₹ {entryFee - discount} for first{' '}
                  {discountSlots > 1 ? `${discountSlots} players` : 'player'}
                </Bold>{' '}
                in each slot.
              </Alert>
            ) : null}
          </div>

          <Title level={3}>
            <Bold>Leaderboard Card Banner</Bold> Upload
          </Title>
          <Dropzone
            files={leaderboardBannerImage ? [leaderboardBannerImage] : []}
            allowReorder={true}
            onUpdateFiles={(fileItems) => {
              const file = fileItems[0]?.file
              updateSD('leaderboardBannerImage')(file)
            }}
            label='Drop image or <span class="filepond--label-action">Browse</span>'
            acceptedFileTypes={[
              'image/jpeg',
              'image/png',
              'image/jpg',
              'image/webp',
            ]}
            customClasses={[styles.bannerUpload]}
          />
        </>
      )}

      {isReferral && (
        <>
          <Divider placement={'left'}>For Referral Leaderboards</Divider>
          <Title level={3}>
            <Bold>Referral</Bold> Details
          </Title>
          <div className={styles.referralDetails}>
            <div className={styles.degree}>
              <Input
                label="Referral Degree"
                type="number"
                placeholder="eg: 1"
                onChange={(value) => {
                  updateSD('benefitingAncestorGenerations')(value)
                  let newDMultiplier = []

                  for (let i = 0; i < value; i++) {
                    newDMultiplier.push({
                      name: `Degree ${i + 1}`,
                      multiplier: 1,
                    })
                  }
                  updateSD('degreeMultiplier')(newDMultiplier)
                }}
                value={benefitingAncestorGenerations}
                min={1}
                preventZeroValues={true}
              />
            </div>

            {degreeMultiplier.map((d, i) => (
              <div key={i} className={styles.degreeRow}>
                <Text weight={'paragraph'} customClasses={[styles.degreeName]}>
                  <Bold>{d.name}</Bold>
                </Text>
                <Input
                  value={d.multiplier}
                  onChange={(value) => {
                    const newDm = [
                      ...degreeMultiplier.slice(0, i),
                      { ...degreeMultiplier[i], multiplier: value },
                      ...degreeMultiplier.slice(i + 1),
                    ]
                    updateSD('degreeMultiplier')(newDm)
                  }}
                  type="number"
                  preventZeroValues={true}
                />
              </div>
            ))}
          </div>
        </>
      )}

      {(lbCategory.label === 'Paid' || optIn) && lateRegistrationAllowed && (
        <>
          <Divider placement={'left'}>For Late Registration Allowed</Divider>
          <Title level={3}>
            <Bold>Late Registration</Bold> Window
          </Title>
          <div className={styles.lateRegistrationDetails}>
            <Input
              label="Hours (After Start Time)"
              type="number"
              placeholder="eg: 1"
              onChange={updateSD('lateRegistrationDurationHour')}
              value={lateRegistrationDurationHour}
              min={0}
            />
            <Input
              label="Minutes (After Start Time)"
              type="number"
              placeholder="eg: 10"
              onChange={updateSD('lateRegistrationDurationMin')}
              value={lateRegistrationDurationMin}
              min={0}
              max={60}
            />
          </div>
        </>
      )}
    </Card>
  )
}

export default LeaderboardDetails
