import React from 'react'
import styles from './Deposit.module.scss'
import { Bold, Card, Title } from 'salt'
import { InputPreview } from '../../../../Components/InputPreview'

const DepositPreview = ({ depositorPercentage, isPartOfSummary }) => {
  return (
    <Card
      removeLeftMargin={isPartOfSummary}
      removeRightMargin={isPartOfSummary}
    >
      <Title level={3}>
        <Bold>Deposit</Bold> Details
      </Title>
      <div className={styles.depositDetailsPreview}>
        <InputPreview
          label={'Depositor Percentage'}
          value={depositorPercentage}
        />
      </div>
    </Card>
  )
}

export default DepositPreview
