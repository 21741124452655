import toRRule from 'Containers/CreateLeaderboard/Scheduler/rule'
import { formatSchedule } from 'Lib/Helpers'
import moment from "moment";

export const validateSchedule = (schedule, propertySchedule) => {
  let validationPassed = true
  let validationErr = ''

  if (!!schedule) {
    let rrule = toRRule(schedule)
    let dates = rrule.all()

    let formattedSchedule = formatSchedule(schedule)
    if (dates.length !== 0) {
      if (formattedSchedule.start_time > formattedSchedule.end_time) {
        validationPassed = false
        validationErr = 'Start time cannot be after end time'
      }
    } else {
      validationPassed = false
      validationErr = 'Select at least one recurrence rule for scheduling'
      returnPayload(true, '')
    }

    if (propertySchedule) {
      const pSt = moment(propertySchedule.start_time).format()
      const pEt = moment(propertySchedule.end_time).format()

      if (
        formattedSchedule.start_time < pSt ||
        formattedSchedule.start_time > pEt
      ) {
        validationPassed = false
        validationErr =
          'Leaderboard start time should be between property start time and end time'
      }
      if (
        formattedSchedule.end_time > pEt ||
        formattedSchedule.end_time < pSt
      ) {
        validationPassed = false
        validationErr =
          'Leaderboard end time should be between property start time and end time'
      }
    }
  }

  return returnPayload(validationPassed, validationErr)
}

const returnPayload = (validationPassed, validationErr) => {
  return {
    passed: validationPassed,
    error: validationErr,
  }
}
