import axios from 'axios'
import firebase from 'firebase/app'

const baseUrl = '/twirp/msadmin.v1.UserManagementService'

let numRetries = 0
const getAccessToken = async () => {
  let accessToken = firebase.auth().currentUser?.getIdToken()

  // promise chain will keep growing if we don't put max limit
  if (accessToken || numRetries >= 16) {
    numRetries = 0
    return accessToken
  }

  return new Promise((res) =>
    setTimeout(() => {
      numRetries += 1
      res(getAccessToken())
    }, 2000)
  )
}

export const addAdmin = async (email, note) => {
  const url = `${process.env.REACT_APP_FETCH_URL}${baseUrl}/AddAdmin`
  let err

  try {
    await axios({
      method: 'POST',
      headers: {
        Authorization: `Bearer ${await getAccessToken()}`,
      },
      url,
      data: { email, note },
    })
  } catch (e) {
    err = e?.response?.data
  }

  return err
}
