import React, { useState } from 'react'
import styles from './CSVUploader.module.scss'
import Papa from 'papaparse'
import { Alert, Dropzone, Text } from 'salt'

const CSVUploader = ({ setValues, setValidationResult }) => {
  const [files, setFiles] = useState([])
  const [result, setResult] = useState({
    exists: false,
    type: '',
    totalRows: '',
    errorReason: '',
  })

  const onFileParseComplete = (results, _) => {
    const values = results.data.flat().filter((element) => element.trim())
    setValues(values)
    setResult({
      exists: true,
      type: 'success',
      totalRows: values.length.toString(),
      errorReason: '',
    })
    setValidationResult &&
      setValidationResult({
        passed: true,
        error: '',
      })
  }

  const onFileParseError = (error, _) => {
    setResult({
      exists: true,
      type: 'error',
      totalRows: '',
      errorReason: error?.message,
    })
    setValidationResult &&
      setValidationResult({
        passed: false,
        error: 'Please enter valid CSV file',
      })
  }

  const onFileRemove = () => {
    setValues([])
    setResult({
      exists: false,
      type: '',
      totalRows: '',
      errorReason: '',
    })
    setValidationResult &&
      setValidationResult({
        passed: false,
        error: 'Please upload CSV file with player IDs',
      })
  }

  return (
    <>
      <Dropzone
        customClasses={[styles.csvReader]}
        files={files}
        label='Drop CSV file or <span class="filepond--label-action">Browse</span>'
        acceptedFileTypes={[
          'text/csv',
          'application/vnd.ms-excel',
          'application/csv',
        ]}
        onUpdateFiles={(files) => {
          setFiles(files ? files : [])

          if (files.length === 0) {
            onFileRemove()
            return
          }

          if (files[0].status === 8) {
            onFileParseError({ message: 'File is of invalid type' })
            return
          }

          Papa.parse(files[0]?.file, {
            complete: onFileParseComplete,
            error: onFileParseError,
          })
        }}
      />
      {result.exists && (
        <>
          {result.type === 'error' && (
            <Alert type={'danger'} customClasses={[styles.csvMessage]}>
              Error '{result.errorReason}' occurred while uploading file
            </Alert>
          )}
          {result.type === 'success' && (
            <>
              <Alert type={'success'} customClasses={[styles.csvMessage]}>
                Uploaded and scanned successfully without errors!
              </Alert>
              <div className={styles.csvStatus}>
                <div>
                  <Text weight={'small'}>Total Rows</Text>
                  <Text weight={'paragraph'}>{result.totalRows}</Text>
                </div>
                <div>
                  <Text weight={'small'}>Rows Read Successfully</Text>
                  <Text weight={'paragraph'}>{result.totalRows}</Text>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}

export default CSVUploader
