import styles from './BannerDetails.module.scss'
import React, { useState, useEffect } from 'react'
import { Bold, Card, Title, Text } from 'salt'
import ImageUploader from 'Components/ImageUploader'

const BannerDetails = ({ updateSD, sImage, lImage }) => {
  const [largeImage, setLargeImage] = useState(lImage || [])
  const [smallImage, setSmallImage] = useState(sImage || [])

  useEffect(() => {
    updateSD('largeImage')(largeImage)
  }, [largeImage])

  useEffect(() => {
    updateSD('smallImage')(smallImage)

  }, [smallImage])

  return (
    <Card>
      <Title level={3}>
        <Bold>Banner</Bold> Details
      </Title>
      <div className={styles.namingDetails}>
        <div className={styles.bannerUpload}>
          <>
            <Text weight="small" customClasses={[styles.label]}>
              Large Image
            </Text>
            <ImageUploader imageState={setLargeImage} />
            </>
        </div>
        <div className={styles.bannerUpload}>
          <>
            <Text weight="small" customClasses={[styles.label]}>
              Small Image
            </Text>
            <ImageUploader imageState={setSmallImage} />
            </>
        </div>
      </div>
    </Card>
  )
}

export default BannerDetails
