import React from 'react'
import NamingDetailsPreview from './NamingDetails/NamingDetailsPreview'
import { useSelector } from 'react-redux'
import LeaderboardDetailsPreview from './LeaderboardDetails/LeaderboardDetailsPreview'
import LeaderboardRulesPreview from './LeaderboardRules/LeaderboardRulesPreview'
import TermsAndConditionsPreview from './TermsAndConditions/TermsAndConditionsPreview'
import GameDetailsPreview from './GameDetails/GameDetailsPreview'
import TournamentDesignPreview from './TournamentDesign/TournamentDesignPreview'
import DepositPreview from './Deposit/DepositPreview'
import PrizeDetailsPreview from './PrizeDetails/PrizeDetailsPreview'

const SpawningStepPreview = () => {
  const {
    internalName,
    externalName,
    description,
    formatType,
    lbCategory,
    lbType,
    systemEvent,
    gameCategory,
    gameName,
    bbMinValue,
    bbMaxValue,
    totalPrize,
    prizes,
    goalBasedPrizes,
    rules,
    terms,
    depositorPercentage,
    optIn,
    lateRegistrationAllowed,
    lateRegistrationDurationHour,
    lateRegistrationDurationMin,
    isReferral,
    hasNonCashPrizes,
    benefitingAncestorGenerations,
    degreeMultiplier,
    leaderboardBanners
  } = useSelector((state) => state.leaderboard.spawningDetails)

  return (
    <>
      <NamingDetailsPreview
        internalName={internalName}
        externalName={externalName}
        description={description}
      />

      <LeaderboardDetailsPreview
        formatType={formatType.label}
        lbType={lbType.label}
        lbCategory={lbCategory.label}
        optIn={optIn}
        lateRegistrationAllowed={lateRegistrationAllowed}
        lateRegistrationEndTime={`After ${lateRegistrationDurationHour
          .toString()
          .padStart(
            2,
            '0'
          )} hrs ${lateRegistrationDurationMin
          .toString()
          .padStart(2, '0')} mins`}
        isReferral={isReferral}
        benefitingAncestorGenerations={benefitingAncestorGenerations}
        degreeMultiplier={degreeMultiplier}
        metric={systemEvent.label}
        hasNonCashPrizes={hasNonCashPrizes}
        leaderboardBanners={leaderboardBanners}
      />

      <LeaderboardRulesPreview rules={rules} />

      <TermsAndConditionsPreview terms={terms} />

      {systemEvent && (
        <GameDetailsPreview
          gameCategory={gameCategory.label}
          gameName={gameName.label}
        />
      )}

      {systemEvent?.value !== 'Deposit' && (
        <TournamentDesignPreview
          gameName={gameName.label}
          bbMinValue={bbMinValue}
          bbMaxValue={bbMaxValue}
        />
      )}

      {systemEvent.value === 'Deposit' && (
        <DepositPreview depositorPercentage={depositorPercentage} />
      )}

      {lbCategory && lbType && (
        <PrizeDetailsPreview
          lbType={lbType.label}
          totalPrize={totalPrize}
          prizes={lbType.label === 'Rank Based' ? prizes : goalBasedPrizes}
          hasNonCashPrizes={hasNonCashPrizes}
        />
      )}
    </>
  )
}

export default SpawningStepPreview