import React from 'react'
import { Alert, Bold, Card, Text, Title } from 'salt'
import styles from './LeaderboardRules.module.scss'

const LeaderboardRulesPreview = ({ rules, isPartOfSummary }) => {
  return (
    <Card
      removeLeftMargin={isPartOfSummary}
      removeRightMargin={isPartOfSummary}
    >
      <Title level={3}>
        <Bold>Leaderboard</Bold> Rules
      </Title>
      <div className={styles.rulesPreview}>
        {rules.length !== 0 && !!rules.join('') ? (
          <>
            {rules.map((rule, i) => {
              return (
                <Text weight={'paragraph'} key={i}>
                  <Bold type={'medium'}>
                    {i + 1}. {rule}
                  </Bold>
                </Text>
              )
            })}
          </>
        ) : (
          <Alert type={'info'}>No rules for leaderboard</Alert>
        )}
      </div>
    </Card>
  )
}

export default LeaderboardRulesPreview