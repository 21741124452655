import React, { useEffect, useState } from 'react'
import styles from './SelectAudience.module.scss'
import { Bold, Card, RadioGroup, Title } from 'salt'
import { CSVInput } from './CSVInput'
import { EntryConditions } from './EntryConditions'

const SelectAudience = ({
  updateAudience,
  updatePlayerIds,
  updateExcludedPlayerIds,
  setCSVValidation,
  audience,
}) => {
  const [inputType, setInputType] = useState('entry')

  const audienceTypeRadios = [
    {
      label: 'Entry Conditions',
      value: 'entry',
    },
    {
      label: 'Upload CSV',
      value: 'csv',
    },
  ]

  return (
    <>
      <Card>
        <Title level={3}>
          <Bold>Select</Bold> Type
        </Title>
        <div className={styles.selectType}>
          <RadioGroup
            radios={audienceTypeRadios}
            selectedValue={inputType}
            onChange={(e) => {
              if (setCSVValidation)
                setCSVValidation({ passed: false, error: '' })
              setInputType(e)
            }}
            rows={1}
            columns={2}
          />
        </div>
      </Card>

      {inputType === 'csv' && (
        <CSVInput
          updatePlayerIds={updatePlayerIds}
          setCSVValidation={setCSVValidation}
        />
      )}

      {inputType === 'entry' && (
        <EntryConditions
          audience={audience}
          updateAudience={updateAudience}
          updateExcludedPlayerIds={updateExcludedPlayerIds}
        />
      )}
    </>
  )
}

export default SelectAudience
