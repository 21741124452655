export const validateSpawningStep = (details) => {
  const {
    internalName,
    externalName,
    description,
    largeImage,
    smallImage,
  } = details

  let validationPassed = true
  let validationErr = ''

  if (!internalName) {
    validationPassed = false
    validationErr = 'Please fill Internal name'
    return returnPayload(validationPassed, validationErr)
  }

  if (!externalName) {
    validationPassed = false
    validationErr = 'Please fill External name'
    return returnPayload(validationPassed, validationErr)
  }

  if (!description) {
    validationPassed = false
    validationErr = 'Please fill Description'
    return returnPayload(validationPassed, validationErr)
  }

  if (!largeImage || largeImage.length === 0) {
    validationPassed = false
    validationErr = 'Please upload Banner Large Image'
    return returnPayload(validationPassed, validationErr)
  }

  if (!smallImage || smallImage.length === 0) {
    validationPassed = false
    validationErr = 'Please upload Banner Small Image'
    return returnPayload(validationPassed, validationErr)
  }

  return returnPayload(validationPassed, validationErr)
}

const returnPayload = (validationPassed, validationErr) => {
  return {
    passed: validationPassed,
    error: validationErr,
  }
}
